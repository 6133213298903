import React, { useState } from 'react';
import { Navbar } from "../Navbar";

import { Check } from "../../icons/Check";
import { postRequest } from '../NetWork/NetWork';
import { LoadingIndicator } from '../Loading/Loading';

const ChangeModelAlert = ({ isOpen, onClose, modelList ,curmodel, confirmSelect}) => {
const [selectedModel, setSelectedModel] = useState(curmodel)
const [showLoading, setShowLoading] = useState(false)
  if (!isOpen) return null;
  const height = "630px"
 
  const handleModelSelect = (model) => {
    setSelectedModel(model)
  }

  const handleConfirm =  async () => {
    setShowLoading(true)
    const data = await postRequest(`model/select/${selectedModel.id}`)
    if (data) {
      localStorage.setItem("current_model", JSON.stringify({
        "id": selectedModel.id,
        "name": selectedModel.name,
        "logo": selectedModel.logo,
        "current_level": selectedModel.current_level,
        "total_level": 10
      }))
      confirmSelect(selectedModel)
    }
    setShowLoading(false)
  };

  return (
    <div className="flex flex-col min-h-screen justify-center">
      <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 flex items-center justify-center">
        <div
          className="absolute inset-0 bg-black bg-opacity-50"
          onClick={()=>{
            onClose()
          }}
        ></div>
        
        {/* Modal */}
        <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-4 bg-white rounded-tl-[40px] rounded-tr-[40px] shadow-lg" style={{ width: '100vw', height: height }}>
         
          {/* Modal Body */}
          <div className="mt-[32px] flex  flex-col items-center justify-center gap-[25px]">
           
          
          <SelectModelView modelList={modelList} selectedModel={selectedModel} 
          handleModelSelect={handleModelSelect}
          />



{/* button */}
          <div className='flex flex-col items-center justify-center'>
          <div className="w-[300px] h-[50px]  py-3.5  bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex" onClick={()=>{
                    handleConfirm()
                }}>
                <div className="text-white text-base font-medium font-['SF Pro Display']">Confirm</div>
            </div>

          </div>
          </div>
        </div>
      </div>
        
      <LoadingIndicator isLoading={showLoading} />

      </div>

      <Navbar className="custom-navbar z-10" />

    </div>
  );
};

export default ChangeModelAlert;

const SelectModelView = ({modelList, handleModelSelect, selectedModel})=>{
    
    return   (
    <div className="mt-[20px] w-[375px] bg-white rounded-t-3xl flex flex-col justify-between">
        <div className="w-[376px] flex flex-col items-center pt-6">
        <div className="w-[223px] h-[25px] text-center text-[#1c1f25] text-2xl font-medium font-['SF Pro Display']">
            Choose Model</div>
         <div className="mt-[15px] w-[272px]  text-center text-[#676c77] text-base font-medium font-['SF Pro Display'] leading-[21px]">
         Choose an on-device local model, you can change it at any time.</div>

        <div className="mt-[15px] flex flex-col items-center gap-2">
      {modelList.map((model) => (
        <div
          key={model.name}
          className={`flex w-[335px] items-center gap-2.5 p-4 bg-element-lightwhite rounded-[18px] border border-solid border-element-lightform-line shadow-shadow cursor-pointer ${selectedModel.id === model.id ? "border-primarypin-ai-pink" : ""}`}
          onClick={() => handleModelSelect(model)}
        >
          <div className="relative w-[45px] h-[45px]">
            <div className="relative h-[45px]">
              <img
                className="absolute w-[47px] h-[47px] -top-px -left-px"
                alt={model.name}
                src={model.logo}
              />
            </div>
          </div>
          <div className="items-start gap-3 relative flex-1 grow flex flex-col">
            <div className="items-start gap-1.5 relative self-stretch w-full flex-[0_0_auto] flex flex-col">
              <div className="relative self-stretch mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-sm tracking-[0] leading-[normal]">
                {model.name}
              </div>
              <p className={`relative self-stretch [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-xs tracking-[0] leading-[normal] ${selectedModel === model.name ? "text-primarypin-ai-pink" : "text-element-light2nd-text"}`}>
              {renderText(`{Level ${model.current_level}}/10`)}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center w-[25px] h-[25px]">
            {selectedModel.id === model.id && <Check className="!relative !w-[25px] !h-[25px]" />}
          </div>
        </div>
      ))}
        </div>
    </div>
  </div>)
}


const renderText = (text) => {
    const parts = text.split(/(\{.*?\})/).filter(Boolean);
    return parts.map((part, index) =>
      part.startsWith('{') && part.endsWith('}') ? (
        <span key={index} className="text-[#e15ce6] text-xs font-medium font-['SF Pro Display']">{part.slice(1, -1)}</span>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };