/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import google_logo from "../../assets/data-google-g-logo-svg.png";

export const Google = ({ className }) => {
  return (
    <div
      className={`relative w-[60px] h-[60px] bg-cover bg-[50%_50%] ${className}`}
      style={{ backgroundImage: `url(${google_logo})` }}
    />
  );
};
