import React, { useEffect, useState } from "react";
import { GiftInvite } from "../../components/GiftInvite";
import { Wallet } from "../../components/Wallet";
import { ArrowNext1 } from "../../icons/ArrowNext1";
import { ColorfulBubble } from "../../icons/ColorfulBubble";
import img_rectangle from "../../assets/account-rectangle-27-1.svg"
import img_arrow_right from "../../assets/ic-arrow-right-mini.svg"
import img_help from "../../assets/account-ic-help.svg"
import img_frame from "../../assets/account-frame.svg"
import img_information from "../../assets/account-ic-information.svg"
import img_privacy from "../../assets/account-ic-privacy.svg"
import img_terms from "../../assets/account-ic-terms.svg"
import img_rectangle24 from "../../assets/account-rectangle-24.png"
import default_avatar from "../../assets/default_avatar.png"
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import AccountLogoutAlert from "./AccountLogoutAlert";
import { getRequest, postRequest } from "../NetWork/NetWork";
import { toast } from "react-toastify";
import { AuthProvider } from "../PIAuthCheckContext";
import ChangeModelAlert from "./ChangeModelAlert";
import { LoadingIndicator } from "../Loading/Loading";

export const Account = () => {
  const { user , logout} = useAuth0()
  const [showlogoutAlert, setShowlogoutAlert] = useState(false)
  const invitecode = localStorage.getItem('invite_code')
  const hasSelectModel = JSON.parse(localStorage. getItem("current_model"))
  const [showModelAlert, setShowModelAlert] = useState(false)
  const [selectModel, setSelectModel] = useState(hasSelectModel)

  const [showLoading, setShowLoading] = useState(true)
  const [modelList, setModelList] = useState([])
  

  const navigate = useNavigate()

 const deleteData = async ()=>{
  
  const data = await postRequest("connector/delete")
    if (data) {
      toast.success("Delete successfully")
    }
  }

  const featchData = async ()=>{
    const data = await getRequest("/model/list")
    setShowLoading(false)
    if (data) {
      const models = data.models
      setModelList(models)
      models.map((model)=>{
        if (model.select) {
          setSelectModel(model)
        }
      })
    }
  }

  useEffect(()=>{
    featchData()
  },[])

  const confirmLogout = ()=>{
    localStorage.setItem('is_login_pinai','0')
    logout({ returnTo: `${window.location.origin}/login` });
  }

  return (
    <AuthProvider children={
      <div className="w-screen h-screen bg-element-lightwhite overflow-y-auto">
      <div className="relative h-[980px] w-full bg-element-lightwhite flex flex-col">
        <ColorfulBubble className="!absolute w-full !h-full !top-[9px]" />
        <div className="absolute w-screen h-[650px] top-[260px] bg-[#F8FAFC] rounded-[30px] "></div>
        {/* <img
          className="absolute left-0 w-full h-[650px] top-[260px]"
          alt="Rectangle"
          src={img_rectangle}
        /> */}

         {/* head  */}
         <header className="absolute  w-full h-20  top-[64px]   bg-transparent flex items-center">
         <img
            className="w-[52px] h-[52px] ml-[20px] object-cover rounded-full "
            alt="Ellipse"
            src={user?.picture || default_avatar}
          />
          <div className="w-[267px]  flex ml-[16px] items-center gap-2">
            <div className="flex flex-col items-start gap-2 relative flex-1 grow">
              <div className="relative self-stretch mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-base tracking-[0] leading-[normal]">
                {user?.name || 'Hello'}
              </div>
              <div className="relative self-stretch [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-element-light2nd-text text-xs tracking-[0] leading-[15px]">
                Referral code:&nbsp;&nbsp;{invitecode}
              </div>
            </div>
          </div>
        </header>

 {/* invite */}
      <div className="flex  mx-[20px] items-center relative  gap-2.5 p-4  top-[153px] bg-secondarylight-purple rounded-[18px] shadow-shadow" onClick={()=>{
          navigate('/invite?type=1')
        }}>
          <div className="relative w-[45px] h-[45px]">
            <GiftInvite className="!h-[45px] !rounded-[10px] ![position:unset] !w-[unset]" />
          </div>
          <div className="flex flex-col items-start gap-3 grow">
            <div className="flex flex-col items-start gap-1.5">
              <div className="[font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-sm tracking-[0] leading-[normal]">
                Invite Friends
              </div>
              <p className="[font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-element-darkcard text-xs tracking-[0] leading-[normal]">
                Earn 500 points for each new logn friend
              </p>
            </div>
          </div>
          <ArrowNext1 className="!relative !w-[25px] !h-[25px]" />
        </div>


        {/* Aboult */}
        <div className="flex relative items-center  mx-[20px] h-72 top-[330px]">
        <div className="absolute top-0 left-0 [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-element-light2nd-text text-sm tracking-[0] leading-[normal]">
            About Hi PIN
          </div>

          <div className="absolute w-full h-[329px] top-[29px] bg-element-lightwhite rounded-[18px] shadow-shadow">
            <div className="flex flex-col w-full items-start gap-0.5 px-5 py-0 relative top-3.5">
            <div className="flex flex-col items-start gap-2.5 px-0 py-2.5 relative self-stretch w-full flex-[0_0_auto] border-b [border-bottom-style:solid] border-element-lightform-line"
                onClick={()=>{
                  deleteData()
                }}
              >
                <div className="flex items-center gap-2.5 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="relative w-[38px] h-[38px] bg-element-lightbackground rounded-[100px]">
                    <img className="absolute w-5 h-5 top-[9px] left-[9px]" alt="Ic help" src={img_help} />
                  </div>
                  <div className="flex flex-col items-start gap-4 relative flex-1 grow">
                    <div className="relative self-stretch w-full flex-[0_0_auto] flex items-center gap-2">
                      <div className="flex flex-col items-start gap-1 relative flex-1 grow">
                        <div className="relative self-stretch mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-base tracking-[0] leading-[normal]">
                          Delete data
                        </div>
                      </div>
                      <img className="relative w-[18px] h-[18px]" alt="Ic arrow next" src={img_arrow_right} />
                    </div>
                  </div>
                </div>
              </div>


              <div className="flex flex-col items-start gap-2.5 px-0 py-2.5 relative self-stretch w-full flex-[0_0_auto] border-b [border-bottom-style:solid] border-element-lightform-line"
                onClick={()=>{
                  navigate('/protocol?type=help')
                }}
              >
                <div className="flex items-center gap-2.5 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="relative w-[38px] h-[38px] bg-element-lightbackground rounded-[100px]">
                    <img className="absolute w-5 h-5 top-[9px] left-[9px]" alt="Ic help" src={img_help} />
                  </div>
                  <div className="flex flex-col items-start gap-4 relative flex-1 grow">
                    <div className="relative self-stretch w-full flex-[0_0_auto] flex items-center gap-2">
                      <div className="flex flex-col items-start gap-1 relative flex-1 grow">
                        <div className="relative self-stretch mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-base tracking-[0] leading-[normal]">
                          Help Center
                        </div>
                      </div>
                      <img className="relative w-[18px] h-[18px]" alt="Ic arrow next" src={img_arrow_right} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start gap-2.5 px-0 py-2.5 relative self-stretch w-full flex-[0_0_auto] border-b [border-bottom-style:solid] border-element-lightform-line"
                onClick={()=>{
                  navigate('/protocol?type=terms')
                }}
              >
                <div className="flex items-center gap-2.5 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="relative w-[38px] h-[38px] bg-element-lightbackground rounded-[100px]">
                    <img className="absolute w-5 h-5 top-[9px] left-[9px]" alt="Ic terms" src={img_terms} />
                  </div>
                  <div className="flex flex-col items-start gap-4 relative flex-1 grow">
                    <div className="relative self-stretch w-full flex-[0_0_auto] flex items-center gap-2">
                      <div className="flex flex-col items-start gap-1 relative flex-1 grow">
                        <div className="relative self-stretch mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-base tracking-[0] leading-[normal]">
                          Terms of Use
                        </div>
                      </div>
                      <img className="relative w-[18px] h-[18px]" alt="Ic arrow next" src={img_arrow_right} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start gap-2.5 px-0 py-2.5 relative self-stretch w-full flex-[0_0_auto] border-b [border-bottom-style:solid] border-element-lightform-line"
              onClick={()=>{
                navigate('/protocol?type=privacy')
              }}
              >
                <div className="flex items-center gap-2.5 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="relative w-[38px] h-[38px] bg-element-lightbackground rounded-[100px]">
                    <img className="absolute w-5 h-5 top-[9px] left-[9px]" alt="Ic privacy" src={img_privacy} />
                  </div>
                  <div className="flex flex-col items-start gap-4 relative flex-1 grow">
                    <div className="relative self-stretch w-full flex-[0_0_auto] flex items-center gap-2">
                      <div className="flex flex-col items-start gap-1 relative flex-1 grow">
                        <div className="relative self-stretch mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-base tracking-[0] leading-[normal]">
                          Privacy Policy
                        </div>
                      </div>
                      <img className="relative w-[18px] h-[18px]" alt="Ic arrow next" src={img_arrow_right} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start gap-2.5 px-0 py-2.5 relative self-stretch w-full flex-[0_0_auto]">
                <div className="flex items-center gap-2.5 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="relative w-[38px] h-[38px] bg-element-lightbackground rounded-[100px]">
                    <img
                      className="absolute w-5 h-5 top-[9px] left-[9px]"
                      alt="Ic information"
                      src={img_information}
                    />
                  </div>
                  <div className="flex flex-col items-start gap-4 relative flex-1 grow">
                    <div className="relative self-stretch w-full flex-[0_0_auto] flex items-center gap-2">
                      <div className="flex flex-col items-start gap-1 relative flex-1 grow">
                        <div className="relative self-stretch mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-base tracking-[0] leading-[normal]">
                          Version
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inline-flex items-center gap-2.5 relative flex-[0_0_auto]">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-element-light2nd-text text-sm text-right tracking-[0] leading-[normal]">
                      v.1.0
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>

        {/* logout */}
        <div className="absolute w-[calc(100vw-40px)] left-[20px] h-[45px] top-[770px] flex justify-center ">
          <div className="relative w-full h-[45px] bg-element-lightwhite rounded-[18px] shadow-shadow  flex justify-center"
            onClick={()=>{
              setShowlogoutAlert(true)
            }}
          >
            <div className="absolute top-[13px]  [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-[#e15ce6] text-sm text-center  ">
              Log Out
            </div>
          </div>
        </div>

         {/* model */}
         {selectModel &&
         <div className="absolute top-[276px] w-[calc(100vw-40px)] left-[20px]">
         <div className="  text-[#676c77] text-sm font-medium font-['SF Pro Display']">On-device Model</div>
         <div className="flex w-full items-center gap-2.5 p-4  h-[77px] mt-[10px] bg-white rounded-[18px] shadow-shadow" onClick={()=>{
         // navigate('/invite?type=1')
         setShowModelAlert(true)
       }}>
         <div className="relative w-[45px] h-[45px]">
           <img src={selectModel.logo} className="!h-[45px] !rounded-[10px] ![position:unset] !w-[unset]" />
         </div>
         <div className="flex flex-col items-start relative flex-1 grow">
         <div className="text-[#1c1f25] text-sm font-medium font-['SF Pro Display']">
           {selectModel.name}</div>
         </div>
         <ArrowNext1 className="!relative !w-[25px] !h-[25px]" />
         </div>
       </div>
       }
       
      
      </div>
      <ChangeModelAlert curmodel={selectModel} 
      confirmSelect={(model)=>{
        setShowModelAlert(false)
        setSelectModel(model)
      }}
      modelList={modelList} isOpen={showModelAlert} 
      onClose={()=>{
        setShowModelAlert(false)
      }}/>

      <AccountLogoutAlert  isOpen={showlogoutAlert} 
      confirm={()=>{
        setShowlogoutAlert(false)
        confirmLogout()
      }}
      onClose={()=>{
        setShowlogoutAlert(false)
      }}/>

    <LoadingIndicator isLoading={showLoading} />
    </div>
    } />
  );
};
