/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FingerTouchsMoney = ({ className, strokeColor = 'white' }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="25"
      viewBox="0 0 25 25"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1187_10668)">
        <path
          d="M11.5081 6.94169C11.4328 6.72876 11.3167 6.53515 11.1691 6.37027C10.8552 6.01954 10.399 5.79883 9.89135 5.79883H8.56442C7.71929 5.79883 7.03418 6.48394 7.03418 7.32907C7.03418 8.04818 7.53491 8.67027 8.2374 8.82396L10.2577 9.26589C11.0447 9.43805 11.6056 10.1355 11.6056 10.9411C11.6056 11.8879 10.8381 12.656 9.89135 12.656H8.74848C8.00207 12.656 7.36706 12.179 7.13172 11.5131"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M9.32031 5.79878V4.08447"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M9.32031 14.3701V12.6558"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M12.5898 23.741V14.741C12.5898 14.1727 12.8156 13.6276 13.2175 13.2258C13.6193 12.8239 14.1644 12.5981 14.7327 12.5981C15.301 12.5981 15.8461 12.8239 16.2479 13.2258C16.6498 13.6276 16.8756 14.1727 16.8756 14.741V19.4553H20.3042C21.2135 19.4553 22.0855 19.8165 22.7286 20.4596C23.3714 21.1024 23.7328 21.9745 23.7328 22.8839V23.741"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M9.32157 16.9952C5.0315 16.9952 1.55371 13.5174 1.55371 9.22733C1.55371 4.93726 5.0315 1.45947 9.32157 1.45947C13.6116 1.45947 17.0894 4.93726 17.0894 9.22733C17.0894 9.58798 17.0648 9.94287 17.0173 10.2905"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1187_10668">
          <rect fill="white" height="24" transform="translate(0.642578 0.600098)" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
