import React from "react";
import logo_blk_name from "../../assets/logo_with_name.png"
import splash_rectangle from "../../assets/splash-rectangle.svg"
import { SplashColorfulBubble } from "../../components/ColorfulBubble";
import Logo from "../../assets/logo.png"

export const Splash = () => {
  return (
    <div className="w-screen h-screen bg-white">
      <div className="relative h-full w-full flex flex-col items-center">
        <div className=" inline-flex items-center gap-2.5 px-3 py-1.5 absolute top-[725px]  bg-element-lightwhite rounded-lg">
          <div className="w-2.5 h-2.5 bg-primarypin-ai-pink rounded-[5px]" />
          <div className="w-fit mt-[-1.00px] [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-element-light2nd-text text-sm tracking-[0] leading-[normal]">
            Getting Ready...
          </div>
        </div>
        <img
          className="w-[120px] h-[120px] mt-[160px]"
          alt="Logo blk name no bg"
          src={Logo}
        />

        <div className="mt-[20px] h-9 text-center text-[#1c1f25] text-3xl font-bold font-['SF Pro Display']">
          Hi PIN</div>    

           <div className= "h-[100px] mt-[20px] text-center text-[#4f5865] text-xl font-medium font-['SF Pro Display']">
            Your Personal AI<br/>
            Assistant On-device
          </div>  

      </div>
    </div>

  );
};
