/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Coins = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="25"
      viewBox="0 0 25 25"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1213_7281)">
        <path
          d="M16.0709 13.3929C20.5089 13.3929 24.1066 11.7939 24.1066 9.82143C24.1066 7.84898 20.5089 6.25 16.0709 6.25C11.6329 6.25 8.03516 7.84898 8.03516 9.82143C8.03516 11.7939 11.6329 13.3929 16.0709 13.3929Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.03516 9.82178V20.5361C8.03516 22.5003 11.6066 24.1075 16.0709 24.1075C20.5352 24.1075 24.1066 22.5003 24.1066 20.5361V9.82178"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.1066 15.1787C24.1066 17.143 20.5352 18.7501 16.0709 18.7501C11.6066 18.7501 8.03516 17.143 8.03516 15.1787"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8926 2.69446C13.806 1.40673 11.3771 0.783946 8.92829 0.908738C4.48186 0.908738 0.892578 2.51589 0.892578 4.48017C0.892578 5.53375 1.92829 6.48017 3.57115 7.15875"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.57115 17.8729C1.92829 17.1943 0.892578 16.2478 0.892578 15.1943V4.47998"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.57115 12.516C1.92829 11.8374 0.892578 10.891 0.892578 9.8374"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1213_7281">
          <rect fill="white" height="25" width="25" />
        </clipPath>
      </defs>
    </svg>
  );
};
