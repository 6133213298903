import React from 'react';
import { Navbar } from "../Navbar";

const AccountLogoutAlert = ({ isOpen, onClose, confirm}) => {
  if (!isOpen) return null;
  const height = "362px"

  return (
    <div className="flex flex-col min-h-screen justify-center">
      <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 flex items-center justify-center">
        <div
          className="absolute inset-0 bg-black bg-opacity-50"
          onClick={()=>{
            onClose()
          }}
        ></div>
        
        {/* Modal */}
        <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-4 bg-white rounded-tl-[40px] rounded-tr-[40px] shadow-lg" style={{ width: '100vw', height: height }}>
         
          {/* Modal Body */}
          <div className="mt-[32px] flex  flex-col items-center justify-center gap-[25px]">
           
            <div className="h-[31px] text-center text-black text-2xl font-medium font-['SF Pro Display']">Disconnect?</div>
           
            <div className="flex items-center justify-center w-[301px] h-[100px] text-center text-black text-base font-normal font-['SF Pro Display'] leading-[21px]">Do you want to disconnect your wallet? <br/>You will not receive any airdrop to this wallet, but you can connect a new wallet.</div>
         

{/* button */}
          <div className='flex flex-col items-center justify-center'>
          <div className="w-[300px] h-[50px]  py-3.5  bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex" onClick={()=>{
                    confirm()
                }}>
                <div className="text-white text-base font-medium font-['SF Pro Display']">Disconnect</div>
            </div>

            <div className="mt-[10px] w-[300px] h-[50px]  py-3.5  bg-white border border-solid border-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex" onClick={()=>{
                    onClose()
                }}>
                <div className="text-[#e15ce6] text-base font-medium font-['SF Pro Display']">Cancel</div>
            </div>

          </div>
            

          </div>

          
        </div>
      </div>
      </div>

      <Navbar className="custom-navbar z-10" />

    </div>
  );
};

export default AccountLogoutAlert;
