// src/minikit-provider.tsx
"use client";  // Required for Next.js

import { MiniKit } from "@worldcoin/minikit-js";
import React from "react";
import { ReactNode, useEffect } from "react";

export default function MiniKitProvider({ children }: { children: ReactNode }) {
  useEffect(() => {
    MiniKit.install();
  }, []);

  return <>{children}</>;
}
