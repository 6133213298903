/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Check = ({ className }) => {
  return (
    <svg
      className={`check ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_941_6947)">
        <path
          className="path"
          d="M8.99965 16.1698L5.52965 12.6998C5.13965 12.3098 4.50965 12.3098 4.11965 12.6998C3.72965 13.0898 3.72965 13.7198 4.11965 14.1098L8.29965 18.2898C8.68965 18.6798 9.31965 18.6798 9.70965 18.2898L20.2896 7.70982C20.6796 7.31982 20.6796 6.68982 20.2896 6.29982C19.8997 5.90982 19.2696 5.90982 18.8796 6.29982L8.99965 16.1698Z"
          fill="#43C6A7"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_941_6947">
          <rect className="rect" fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
