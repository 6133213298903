/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Coins19 = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="12"
      viewBox="0 0 11 12"
      width="11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1273_1224)">
        <path
          d="M7.07087 6.39286C9.02361 6.39286 10.6066 5.6893 10.6066 4.82143C10.6066 3.95355 9.02361 3.25 7.07087 3.25C5.11815 3.25 3.53516 3.95355 3.53516 4.82143C3.53516 5.6893 5.11815 6.39286 7.07087 6.39286Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.53516 4.82129V9.53558C3.53516 10.3999 5.10658 11.107 7.07087 11.107C9.03516 11.107 10.6066 10.3999 10.6066 9.53558V4.82129"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6066 7.17871C10.6066 8.043 9.03516 8.75014 7.07087 8.75014C5.10658 8.75014 3.53516 8.043 3.53516 7.17871"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.99258 1.68519C6.07446 1.11859 5.00577 0.844565 3.92829 0.899474C1.97186 0.899474 0.392578 1.60662 0.392578 2.47091C0.392578 2.93448 0.848292 3.35091 1.57115 3.64948"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.57115 8.36357C0.848292 8.06499 0.392578 7.64856 0.392578 7.18499V2.4707"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.57115 6.0067C0.848292 5.70812 0.392578 5.2917 0.392578 4.82812"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1273_1224">
          <rect fill="white" height="11" transform="translate(0 0.5)" width="11" />
        </clipPath>
      </defs>
    </svg>
  );
};
