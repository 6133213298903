/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const SplashColorfulBubble = ({ className }) => {
  return (
    <div className={`w-[375px] h-[812px] ${className}`}>
      <div className="relative w-[631px] h-[524px] top-36 left-[-123px]">
        <div className="absolute w-[167px] h-[425px] top-[58px] left-[151px] rounded-[83.33px/212.74px] rotate-[-62.23deg] blur-[90px] [background:linear-gradient(180deg,rgb(255,57.27,235.23)_1.91%,rgb(182.02,147.68,255)_27%,rgb(98.4,226.81,255)_47.5%,rgb(126.45,255,147.02)_69%,rgb(222.37,244.61,133.4)_88.5%,rgb(255,255,255)_100%)]" />
        <div className="absolute w-[351px] h-[540px] -top-2 left-[140px] rounded-[175.57px/269.95px] rotate-[-68.60deg] [background:linear-gradient(180deg,rgba(255,255,255,0)_0%,rgba(103.37,145.83,255,0.34)_100%)]" />
      </div>
    </div>
  );
};
