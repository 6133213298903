/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useState } from "react";
import img_mask_group from "../../assets/home-mask-twitter.png"

export const Coin = ({
  className,
  maskGroup = {img_mask_group},
  overlapGroupClassName,
  edgeClassName,
  dashClassName,
  highlightClassName,
  maskGroupClassName,
  callback
}) => {

  let x1 = 0
  let x2 = 0
  const [isTouching, setIsTouching] = useState(false);
  function handleTouchStart(e) {
    setIsTouching(true);
    const touch = e.touches[0];
    const rect = e.target.getBoundingClientRect();
    x1 = touch.clientX - rect.left;
  }

  function handleTouchEnd() {
    setIsTouching(false);
    const re = Math.abs(x2 -x1)
    // if (re > 1) {
    //   callback()
    // }
    callback()
  }

  function handleTouchMove(e) {
    if (isTouching) {
      // handleTouch(e);
      const touch = e.touches[0];
      const rect = e.target.getBoundingClientRect();
      x2 = touch.clientX - rect.left;
    }
  }


  return (
    <div className={`w-[45px] h-[45px] ${className}`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className={`relative w-[59px] h-[59px] top-[-7px] left-[-7px] rounded-[29.5px] ${overlapGroupClassName}`}>
        <div
          className={`absolute w-[57px] h-[57px] top-px left-px bg-[#fbdd45] rounded-[28.5px] border-[6px] border-solid border-[#ffe76d] shadow-[0px_0px_4px_#00000033] ${edgeClassName}`}
        />
        <div
          className={`absolute w-[59px] h-[59px] top-0 left-0 bg-[#ffef9e] rounded-[29.5px] border-[7px] border-dashed border-[#fdc351] shadow-dark-shadow ${dashClassName}`}
        />
        <div
          className={`absolute w-[47px] h-[47px] top-1.5 left-1.5 bg-[#ffef9e] rounded-[23.5px] border border-solid border-[#fff9ed] ${highlightClassName}`}
        />
        <img
          className={`absolute w-[45px] h-[45px] top-[7px] left-[7px] ${maskGroupClassName}`}
          alt="Mask group"
          src={maskGroup}
        />
      </div>
    </div>
  );
};

Coin.propTypes = {
  maskGroup: PropTypes.string,
};
