import { useLocation, useNavigate } from "react-router-dom";
import ic_arrow_left_mini from "../../assets/ic-arrow-left-mini.svg";
import { useEffect, useState } from "react";

export const Protocol = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  let content = "";

  if (type === "terms") {
    content = `
        <h2 style="font-size: 18px; font-weight: bold; margin-bottom: 10px;">Terms of Use for Hi PIN</h2>

        <h3 style="font-size: 16px; margin-bottom: 8px;">1. Introduction</h3>
        <p style="margin-bottom: 10px;">Welcome to Hi PIN, an AI assistant designed to integrate data from your internet services to provide personalized tasks and enhance your digital experience. By using Hi PIN, you agree to these Terms of Use. If you do not agree with these terms, please do not use Hi PIN.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">2. User Accounts</h3>
        <p style="margin-bottom: 10px;">To use Hi PIN, you must be at least 18 years old and capable of forming a binding contract. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your device. You agree to accept responsibility for all activities that occur under your account.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">3. Services Provided</h3>
        <ul style="margin-bottom: 10px; padding-left: 20px;">
          <li style="margin-bottom: 5px;">Checking social media updates.</li>
          <li style="margin-bottom: 5px;">Getting a summary of emails you have received and updates about your upcoming work schedule.</li>
          <li style="margin-bottom: 5px;">Ordering food based on your preferences.</li>
          <li style="margin-bottom: 5px;">Booking travel based on your preferences.</li>
        </ul>
        <p style="margin-bottom: 10px;">You agree to use Hi PIN only for lawful purposes and in accordance with these terms.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">4. Privacy and Data Usage</h3>
        <p style="margin-bottom: 10px;">Hi PIN takes privacy seriously. Our practices regarding the collection, use, and sharing of data are detailed in our Privacy Policy, which is incorporated by reference into these Terms of Use.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">5. Third-Party Services</h3>
        <p style="margin-bottom: 10px;">Hi PIN integrates with third-party services such as Google, Facebook, Uber, etc. You are responsible for complying with the terms of use and privacy policies of these third-party services. Hi PIN is not responsible for any issues that arise from your use of these third-party services.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">6. Intellectual Property</h3>
        <p style="margin-bottom: 10px;">All content, features, and functionality (including but not limited to text, graphics, logos, and software) are the exclusive property of Hi PIN and its licensors. You are granted a limited, non-exclusive, non-transferable license to use Hi PIN for personal, non-commercial use.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">7. Limitation of Liability</h3>
        <p style="margin-bottom: 10px;">Hi PIN is provided "as is" and "as available" without any warranties, express or implied. Hi PIN shall not be liable for any damages arising out of your use of the service.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">8. Termination</h3>
        <p style="margin-bottom: 10px;">Hi PIN reserves the right to terminate your access to the service at any time, without notice, for conduct that violates these terms or is harmful to other users or the business interests of Hi PIN.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">9. Changes to Terms</h3>
        <p style="margin-bottom: 10px;">Hi PIN reserves the right to modify these Terms of Use at any time. We will notify you of any changes by posting the new terms on our website and updating the "Last Updated" date at the top of these terms.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">10. Governing Law</h3>
        <p style="margin-bottom: 10px;">These terms shall be governed and construed in accordance with the laws of California, without regard to its conflict of law provisions.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">11. Contact Information</h3>
        <p style="margin-bottom: 10px;">If you have any questions about these Terms of Use, please contact us at support@pinai.io.</p>
        `;
  } else if (type === "privacy") {
    content = `
        <h2 style="font-size: 18px; font-weight: bold; margin-bottom: 10px;">Privacy Policy for Hi PIN</h2>

        <h3 style="font-size: 16px; margin-bottom: 8px;">1. Introduction</h3>
        <p style="margin-bottom: 10px;">At Hi PIN, your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our AI assistant. By using Hi PIN, you agree to the practices described in this policy.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">2. Data Collection</h3>
        <ul style="margin-bottom: 10px; padding-left: 20px;">
          <li style="margin-bottom: 5px;"><strong>Personal Information</strong>: Your name, email address, and account details when you create an account with Hi PIN.</li>
          <li style="margin-bottom: 5px;"><strong>Usage Data</strong>: Information on how you interact with Hi PIN, including the services you use and your preferences.</li>
          <li style="margin-bottom: 5px;"><strong>Third-Party Data</strong>: With your permission, we collect data from third-party services like Google, Facebook, and Uber to enhance our services.</li>
        </ul>

        <h3 style="font-size: 16px; margin-bottom: 8px;">3. Use of Data</h3>
        <ul style="margin-bottom: 10px; padding-left: 20px;">
          <li style="margin-bottom: 5px;">Personalize your experience by understanding your preferences and habits.</li>
          <li style="margin-bottom: 5px;">Provide services like social media updates, food ordering, and travel bookings.</li>
          <li style="margin-bottom: 5px;">Improve our AI algorithms to enhance the overall performance of Hi PIN.</li>
        </ul>
        <p style="margin-bottom: 10px;"><strong>Important Notice:</strong> Hi PIN does not use data obtained through Google Workspace APIs to develop, improve, or train generalized AI or machine learning models.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">4. Data Sharing</h3>
        <p style="margin-bottom: 10px;">Hi PIN integrates with third-party services such as Google, Facebook, and Uber. When you connect your accounts, we access and use your data in accordance with their privacy practices and terms of use. We follow the highest standards of privacy and data protection in these interactions.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">5. Data Security</h3>
        <ul style="margin-bottom: 10px; padding-left: 20px;">
          <li style="margin-bottom: 5px;"><strong>On-Device AI</strong>: Your data is processed on your device to minimize exposure to external servers.</li>
          <li style="margin-bottom: 5px;"><strong>Cryptography and Blockchain</strong>: We use cutting-edge cryptography and blockchain technologies to secure your data.</li>
          <li style="margin-bottom: 5px;"><strong>Data Ownership</strong>: You retain ownership of your data, and Hi PIN acts as a custodian, safeguarding it.</li>
        </ul>

        <h3 style="font-size: 16px; margin-bottom: 8px;">6. User Rights</h3>
        <ul style="margin-bottom: 10px; padding-left: 20px;">
          <li style="margin-bottom: 5px;"><strong>Access</strong>: Request access to your personal information.</li>
          <li style="margin-bottom: 5px;"><strong>Correction</strong>: Request corrections to inaccurate or incomplete data.</li>
          <li style="margin-bottom: 5px;"><strong>Deletion</strong>: Request deletion of your personal information, subject to legal obligations.</li>
          <li style="margin-bottom: 5px;"><strong>Portability</strong>: Request a copy of your data in a structured, commonly used format.</li>
        </ul>

        <p style="margin-bottom: 10px;">To exercise any of these rights, please contact us at support@pinai.io.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">7. Cookies and Tracking</h3>
        <p style="margin-bottom: 10px;">Hi PIN may use cookies and similar tracking technologies to enhance your experience. You can control the use of cookies through your browser settings.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">8. Children's Privacy</h3>
        <p style="margin-bottom: 10px;">Hi PIN is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If we discover that we have inadvertently collected such data, we will take steps to delete it.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">9. Changes to Privacy Policy</h3>
        <p style="margin-bottom: 10px;">We may update this Privacy Policy periodically. Any updates will be posted on our website, and the "Last Updated" date at the top of the policy will be revised.</p>

        <h3 style="font-size: 16px; margin-bottom: 8px;">10. Contact Information</h3>
        <p style="margin-bottom: 10px;">If you have any questions or concerns regarding this Privacy Policy, please contact us at support@pinai.io.</p>
        `;
  } else if (type === "help") {
    content = `<p>Help section content here...</p>`;
  }

  return (
    <div className="w-screen h-screen bg-[#F8FAFC] flex flex-col">
      <nav className="relative">
        <button className="w-[20px] h-[20px] m-4" onClick={() => navigate(-1)}>
          <img src={ic_arrow_left_mini} alt="ic_arrow_left_mini" className="w-full h-full" />
        </button>
      </nav>
      <div className="flex-grow mt-[10px] mx-[20px] mb-[32px] bg-white rounded-lg shadow-lg flex flex-col items-center  overflow-y-auto p-4">
        <div className="mt-2 text-left" style={{ fontSize: '14px', lineHeight: '1.6' }} dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};
