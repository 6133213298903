// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// 初始化 i18next
i18n
  .use(Backend)
  .use(LanguageDetector) // 使用语言检测器插件
  .use(initReactI18next)
  .init({
    fallbackLng: 'en', // 默认语言
    interpolation: {
      escapeValue: false, // React 已经处理了 XSS
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    detection: {
      // 配置语言检测选项
      order: ['navigator', 'localStorage', 'cookie', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;
