
import axios from "axios";
import { useState } from "react"; 
import { toast } from 'react-toastify';

const apiClient = axios.create({
    baseURL: 'https://api.pinai.tech/', 
    // baseURL: 'http://47.97.183.217:8088/', 
    timeout: 30000,
  });

apiClient.interceptors.request.use(
(config) => { 
     
    const token = localStorage.getItem('authToken');
    if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    }
    const language = localStorage.getItem('language')
    config.headers['Accept'] = 'application/json';
    if (language) {
      config.headers['lang'] = language;
    }
    const lat = localStorage.getItem('lat')
    const lng = localStorage.getItem('lng')
    if (lat && lng) {
      config.headers['lat'] = lat;
      config.headers['lng'] = lng;
    }

    config.headers['Content-Type'] = 'application/json';

    return config;
},
(error) => {
    return Promise.reject(error);
}
);
  
let toastId = null;
apiClient.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
    if (error.response && error.response.status === 401) {
        console.error('Unauthorized, please log in again.');
        // localStorage.removeItem("authToken")
        // localStorage.setItem('is_login_pinai','0')
        // window.location.href = "/main"
    } else {
      if (!toast.isActive(toastId)) {
        // toastId = toast.error('Network error, please try again later.');
        if (error.response) {
          toastId = toast.error(error.response.data.detail);
        }
      }
    } 
    
    return Promise.reject(error);
    }
);
  

export const getRequest = async (url, params = {}) => {
  try {
    const response = await apiClient.get(url, { params });
    return response;
  } catch (error) {
    console.error('Get Request Error:', error);
    // throw error; 
  }
}

export const postRequest = async (url, data = {}) => {
    try {
      const response = await apiClient.post(url, data);
      return response;
    } catch (error) {
      console.error('Post Request Error:', error);
    //   throw error; 
    }
}