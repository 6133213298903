import PropTypes from "prop-types";
import React from "react";
import { DataConnector } from "../../icons/DataConnector";
import { FingerTouchsMoney } from "../../icons/FingerTouchsMoney";
import { MoneyBag } from "../../icons/MoneyBag";
import { Voice2 } from "../../icons/Voice2";
import navbar_logo from "../../assets/navbar_logo.png"
import default_avatar from "../../assets/default_avatar.png";
import tab_friend from "../../assets/tab-friend.png";

import { NavLink, useNavigate } from 'react-router-dom';

export const Navbar = ({ className }) => {

  return (
    <div className={`fixed bottom-5 left-1/2 transform -translate-x-1/2 h-[60px] w-[350px] ${className}`}>
      <div className="relative w-[360px] left-[-5px] -top-0.5 h-[67px]">
        <div className="left-0 top-[7px] rounded-[100px] absolute w-[360px] shadow-shadow h-[60px] bg-primarydark-blue">
          <NavLink
            to="/main/profile"
            className={({ isActive }) =>
              `flex flex-col w-[31px] items-center gap-[3px] absolute top-2.5 left-[301px] ${isActive ? "text-white" : "text-element-lightinactive-text"
              }`
            }
          >
            <img className="relative w-6 h-6 object-cover" alt="Ellipse" src={default_avatar} />
            <div className="relative w-fit ml-[-2.00px] mr-[-2.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-[11px] text-center tracking-[0] leading-[normal] whitespace-nowrap">
              Airdrop
            </div>
          </NavLink>
          <NavLink
            to="/main/invite"
            className={({ isActive }) =>
              `flex flex-col w-[33px] h-[42px] items-center gap-[3px] absolute top-2.5 left-60 rounded-[100px_100px_0px_0px] ${isActive ? "text-white" : "text-element-lightinactive-text"
              }`
            }
          >
            {({ isActive }) => (
              <>
                {/* <MoneyBag
                  className="!relative !w-6 !h-6"
                  strokeColor={isActive ? "white" : "#ABAFB7"}
                /> */}
                <img src={tab_friend} className="w-[24px] h-[24px]"></img>
                <div className="relative w-fit top-[0.5px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-[11px] text-center tracking-[0] leading-[normal] whitespace-nowrap">
                  Friends
                </div>
              </>
            )}
          </NavLink>
          <NavLink
            to="/main/data"
            className={({ isActive }) =>
              `flex flex-col w-[33px] h-[42px] items-center gap-[3px] absolute top-2.5 left-[86px] rounded-[100px_100px_0px_0px] ${isActive ? "text-white" : "text-element-lightinactive-text"
              }`
            }
          >
            {({ isActive }) => (
              <>
                <DataConnector
                  className="!relative !w-6 !h-6"
                  strokeColor={isActive ? "white" : "#ABAFB7"}
                />
                <div className="relative w-fit [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-[11px] text-center tracking-[0] leading-[normal] whitespace-nowrap">
                  Data
                </div>
              </>
            )}

          </NavLink>
          <NavLink
            to="/main/home"
            end
            className={({ isActive }) =>
              `flex flex-col w-[35px] h-[42px] items-center gap-[3px] absolute top-2.5 left-[26px] rounded-[100px_100px_0px_0px] ${isActive ? "text-white" : "text-element-lightinactive-text"
              }`
            }
          >
            {({ isActive }) => (
              <>
                <FingerTouchsMoney
                  className="!relative !w-6 !h-6"
                  strokeColor={isActive ? "white" : "#ABAFB7"}
                />
                <div className="relative w-fit [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-[11px] text-center tracking-[0] leading-[normal] whitespace-nowrap">
                  Play
                </div>
              </>
            )}
          </NavLink>
        </div>
        <NavLink to="/main/action" className="top-0 shadow-[0px_2px_3px_#cbcccf26] rounded-[100px] absolute w-[65px] left-[148px] h-[65px] bg-primarypin-ai-pink">
          <div className="w-[65px] h-[65px] top-0 left-0 bg-element-lightwhite rounded-[100px] shadow-[0px_-2px_16px_#9f9f9f40]">
            <img className="absolute w-[47px] h-[47px] top-[9px] left-[9px]" alt="Logo hi PIN" src={navbar_logo} />
          </div>
        </NavLink>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  className: PropTypes.string,
};

export default Navbar;
