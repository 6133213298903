import React, { useState, useEffect, useRef } from "react";
import Lottie from "react-lottie";
import { Coin } from "../../components/Coin";
import { GiftInvite } from "../../components/GiftInvite";
import { Coins } from "../../icons/Coins";
import { ColorfulBubble } from "../../icons/ColorfulBubble";
import { InfoOutline1 } from "../../icons/InfoOutline1";
import { Power3 } from "../../icons/Power3";

import HomeEarn from "../../assets/home-earn.png";
import homeAvatar from "../../assets/home-avatar.png";
import homeCircle from "../../assets/home-circle.gif";


// 引入撒花动画的 JSON 文件
import confettiAnimationData from "../../assets/disappearing_bubble.json";
import axios from "axios";
import { getRequest, postRequest } from "../NetWork/NetWork";
import HomeTipsAlert from "./HomeTipsAelrt";
import { NavLink, useNavigate } from "react-router-dom";
import HomeGuideAlert from "./HomeGuideAlert";
import HomePointAlert from "./HomePointAlert";
import img_arrow_right from "../../assets/home-arrow-right.png"
import { AuthProvider } from "../PIAuthCheckContext";
import { DataConnector } from "../../icons/DataConnector";


const generateRandomPosition = () => {
  const x = Math.random() * (window.innerWidth - 50); // 随机x坐标
  // const minY = 80;
  const minY = 0;
  const maxY = window.innerHeight - 100 - 200 - 50;
  const y = Math.random() * (maxY - minY) + minY; // 随机y坐标
  return { x, y };
};

const CoinCard = ({ id, x, y, isMovingToCenter, onAnimationEnd, coin }) => {
  const cardStyle = {
    position: 'absolute',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    top: isMovingToCenter ? '50%' : `${y}px`,
    transition: isMovingToCenter ? 'top 0.2s ease-out, left 0.2s ease-out, transform 0.2s ease-out' : 'none',
    left: isMovingToCenter ? '50%' : `${x}px`,
    transform: isMovingToCenter ? 'translate(-50%, calc(-50% - 60px))' : 'none',
    zIndex: isMovingToCenter ? 1000 : 20,
  };

  return (
    <div
      style={cardStyle}
      onTransitionEnd={onAnimationEnd} // 监听动画结束
    >
      {/* 其他内容，例如 Coin 组件 */}
      <Coin maskGroup={coin.icon} callback={() => { /* handleMouseEnter() */ }} />
    </div>
  );
};



//https://api.pinai.tech/home
// https://apifoxmock.com/m1/3891642-3525916-default/home"
const FetchData = async () => {
  try {
    const response = await getRequest("/home")
    return response;
  } catch (err) {
    console.error(`error: ${err}`)
}
};

export const Home = () => {
  const [coins, setCoins] = useState([]);
  const [points, setPoints] = useState({
    pinPoints: 0,
    dataScore: 0,
    awardPoint: 0
  });
 
  const [curmodel, setCurmodel] = useState(null)
  const animationQueueRef = useRef([]);
  const isAnimatingRef = useRef(false);


  const navigate = useNavigate()
  const [lottieStart, setLottieStart] = useState(false);

  //tips Alert
  const [isOpenModal, setIsModalOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const openModal = (item) => {
    setSelectedItem(item)
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }

   //guide Alert
   //point Alert
   const [isGuideOpenModal, setIsGuideOpenModal] = useState(false)
   const [isPointOpenModal, setIsPointOpenModal] = useState(false)
   const openGuideAlert = (type) => {
    if (type == "point") {
      setIsPointOpenModal(true)
    } else {
      setIsGuideOpenModal(true)
    }
   }
   const closeGuideAlert = (type) => {
    if (type == "point") {
      setIsPointOpenModal(false)
    } else {
      setIsGuideOpenModal(false)
      openAwardGuide("point")
    }
   }

   const openAwardGuide = ()=> {
    const hashowGuide = localStorage.getItem("hasShowGuidePoint")
    if (hashowGuide != '1') {
      localStorage.setItem("hasShowGuidePoint", "1")
      openGuideAlert("point")
    }
   }


  const UploadCollect = async (removeCoins) => {
    const response = await postRequest("/home/collect", removeCoins);
    if (response) {
      setPoints(prevPoints => ({
        ...prevPoints,
        pinPoints: response.pin_points,
        dataScore: response.data_power,
        awardPoint: response.new_points_add
      }));
      if (coinCount == coins.length) {
        refreData()
      }
    }
  };

  const refreData = async () => {
    const re = await FetchData();
    if (!re) {
      return;
    }
    setCoinCount(0)
    setRemovedCoins([])
    removedCardsRef.current.clear();

    if (re.current_model) {
      setCurmodel(re.current_model)
      localStorage.setItem("current_model", JSON.stringify(re.current_model))
    }

    const points = {
        pinPoints: re.pin_points,
        dataScore: re.data_power,
    }
    const coinsdata = re.coins;
    const coinList = [];
    for(const coinTypeItem of coinsdata) {
        for (let i = 0; i < coinTypeItem.count; i++) {
          coinList.push({
              type: coinTypeItem.type,
              icon: coinTypeItem.icon
          });
      }
    }
    const initializedCoins = coinList.map((coin, index) => ({
      ...coin,
      ...generateRandomPosition(),
      visible: true,
      id: index,
      isMovingToCenter: false, // 新增状态
    }));
   
      setCoins(initializedCoins);
    setPoints(points);
    localStorage.setItem("homePoints", JSON.stringify(points));
  };
  const [coinCount, setCoinCount] = useState(0)
  useEffect(() => {
    const hashowGuide = localStorage.getItem("hasShowGuide")
    if (hashowGuide != '1') {
      localStorage.setItem("hasShowGuide", "1")
      setIsGuideOpenModal(true)
    }
    // console.log("coiinCount coins", coinCount, coins.length)
    refreData();
  }, []);

  //remove coin
  const [removedCoins, setRemovedCoins] = useState([]);

  const timeoutRef = useRef(null);
  // 清除定时器 (在组件卸载时)
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
}, []);


  const uploadIconData = ()=>{
    if (removedCoins.length >0) {
      setLottieStart(true)
      // 清除之前的定时器，防止多次执行
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
  
      // 设置一个新的定时器，在1秒后执行
      timeoutRef.current = setTimeout(() => {
        setLottieStart(false);
      }, 1000); // 1秒后执行
    }
    
    const typeCountMap = new Map();
    removedCoins.forEach(item => {
      const type = item.type;
      if (typeCountMap.has(type)) {
        typeCountMap.set(type, typeCountMap.get(type) + 1);
      } else {
        typeCountMap.set(type, 1);
      }
    });
    const resultArray = Array.from(typeCountMap, ([type, count]) => ({ type, count }));
    UploadCollect(resultArray)
  }

  const removedCardsRef = useRef(new Set()); // 用于存储已移除的卡片ID

  const handleTouchEnd = () => {
    uploadIconData()
    setRemovedCoins([])
  };

  const handleMouseUp = () => {
    uploadIconData()
    setRemovedCoins([]) 
};

  const handleMouseMove = (e) => {
    const touchX = e.clientX;
    const touchY = e.clientY;
    checkMove(touchX, touchY)
  };

   // 手指滑动时处理
   const handleTouchMove = (e) => {
      const touch = e.touches[0];
      const touchX = touch.clientX;
      const touchY = touch.clientY;
      checkMove(touchX, touchY)
    }
  
  const checkMove = (touchX, touchY) => {
      setCoins((prevCards) => {
          const updatedCards = prevCards.map((card) => {
              if (card.visible && isColliding(touchX, touchY, card)) {
                  if (!removedCardsRef.current.has(card.id)) {
                    setCoinCount(pre => pre+1)
                      removedCardsRef.current.add(card.id); // 记录已移除的卡片ID
                      setRemovedCoins((prevRemovedCards) => [
                          ...prevRemovedCards,
                          {type: card.type, id: card.id}
                      ]);
                  }
                  return { ...card, isMovingToCenter: true }; // 碰撞时开始移动
              }
              return card;
          });
          return updatedCards;
      });
  };

  const handleAnimationEnd = (id) => {
      setCoins((prevCards) =>
        prevCards.map((card) => {
          if (card.id === id) {
            return { ...card, visible: false };
          }
          return card;
        })
      );
    };
     // 圆形碰撞检测
  const isColliding = (touchX, touchY, card) => {
      // const cardRadius = 25; // 圆的半径，50px大小
      // const cardX = card.x + cardRadius; // 圆心的 x 坐标
      // const cardY = card.y + cardRadius + 200; // 圆心的 y 坐标，需要加上顶部偏移量

      // // 计算触摸点与圆心的距离
      // const distance = Math.sqrt(
      //     Math.pow(touchX - cardX, 2) + Math.pow(touchY - cardY, 2)
      // );
      // // 判断距离是否小于半径，表示碰撞发生
      // return distance <= cardRadius;
    
      const cardSize = 50;
      const cardX = card.x;
      const cardY = card.y + 200;
      return (
          touchX >= cardX &&
          touchX <= cardX + cardSize &&
          touchY >= cardY &&
          touchY <= cardY + cardSize
        );

  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   setTimeout(() => {
  //     document.documentElement.style.overflow = 'hidden';
  //   }, 0);
   
  //   return () => {
  //     document.documentElement.style.overflow = 'auto';
  //   };
  // }, []);

  return (
    <AuthProvider children={
      <div className="w-screen h-screen bg-element-lightwhite">
      <div className="absolute top-0  h-screen w-full flex flex-col items-center">
        <ColorfulBubble className="absolute w-full !h-full !top-0 !left-0 pointer-events-none" />
       
   <div className="w-full flex items-center justify-center mt-[66px] gap-[40px]">

    {/* PIN */}
    <div className="ml-[21px] flex flex-col items-start gap-1"
        onClick={()=>{
          openModal(
            {
              name:"PIN",
              core: points.pinPoints
            }
          )
        }}
        >
          <div className="relative w-fit mt-[-1.00px] text-[14px] [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-element-light2nd-text text-sm tracking-[0] leading-[normal]">
            PIN Points
          </div>
          <div className="inline-flex items-center gap-1 relative flex-[0_0_auto]">
            <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-[28px] tracking-[0] leading-[normal] whitespace-nowrap">
              {points.pinPoints}
            </div>
            <Coins className="!relative !w-[19px] !h-[19px]" color="#676C77" />
          </div>
        </div>

  
      {/* Data */}
        <div className="flex flex-col items-start gap-1"
        onClick={()=>{
          openModal(
            {
              name:"Data",
              core: points.dataScore
            }
          )
        }}
        >
          <div className="inline-flex items-center justify-center gap-0.5 relative flex-[0_0_auto]">
            <div className="mt-[-1.00px] [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-element-light2nd-text text-sm relative w-fit tracking-[0] leading-[normal]">
              Data Power
            </div>
            <InfoOutline1 className="!relative !w-[15px] !h-[15px]" color="#626262" />
          </div>
          <div className="inline-flex items-center gap-1 relative flex-[0_0_auto] mt-[4px]">
            <div className="relative w-fit [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-xl tracking-[0] leading-[normal] whitespace-nowrap">
              {points.dataScore}
            </div>
            <Power3 className="!relative !w-[25px] !h-[25px]" color="#676C77" />
          </div>
        </div>

      {/* Earn */}
        <div className=" flex flex-col w-[52px] items-center p-[5px]  bg-element-lightwhite rounded-[5px] shadow-shadow" onClick={()=>{
            navigate('/task')}}>
          {/* <GiftInvite className="!self-stretch !h-[35px] !w-full" /> */}
          <img src={HomeEarn} alt="HomeEarn" className="w-[30px] h-[30px]"></img>
          <div className="relative self-stretch h-[9px] [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-black text-sm text-center tracking-[0] leading-[normal] whitespace-nowrap">
            Earn
          </div>
        </div>
      
  
   </div>

          {curmodel && < ModelSelect model={curmodel}/>}
       
        
          <div className="absolute inset-0 top-[30px] flex items-center justify-center pointer-events-none">

          <img  className="w-[60px] h-[60px] absolute " src={homeAvatar} alt="homeAvatar pointer-events-none"></img>
          {lottieStart && <Lottie
           className="absolute  z-10 pointer-events-none"
            options={{
              loop: true,
              autoplay: true,
              animationData: confettiAnimationData,
            }}
            height={200}
            width={200}
          />
          }
            <div className="absolute w-[330px] h-[330px]  bg-cover" style={{backgroundImage:`url(${homeCircle})`}}/>
            {coins.length==0 && 
            
                <div className="absolute top-[550px] w-[308px]  text-center text-black text-base font-medium font-['SF Pro Display']">
              There's no more coins to collect right now.  </div>
             
            }
             {coins.length==0 && 
                <div className="absolute top-[580px] w-[298.41px] h-[50px] px-6 py-3.5 bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex pointer-events-auto"
                onClick={()=>{
                  navigate('/main/action')
                }}
              >
                <div className="text-white text-base font-medium font-['SF Pro Display']">Connect more data</div>
              </div>
              }


             
        </div>

       {coins.length > 0 &&  <div
          className='absolute top-[200px] bottom-[100px] w-full'
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseUp}
        >
        {coins.map((card) =>
           card.visible && (
            <CoinCard
              key={card.id}
              id={card.id}
              x={card.x}
              y={card.y}
              coin={card}
              isMovingToCenter={card.isMovingToCenter}
              onAnimationEnd={() => handleAnimationEnd(card.id)}
            />
          )
        )}
        </div>}
        
        

      </div>
      

      <HomeTipsAlert isOpen={isOpenModal} onClose={closeModal} item={selectedItem} />

      <HomeGuideAlert isOpen={isGuideOpenModal} onClose={closeGuideAlert} />

      <HomePointAlert isOpen={isPointOpenModal} onClose={closeGuideAlert} point={points.awardPoint} />

    </div>
    } />
  );
};

const renderText = (text) => {
  const parts = text.split(/(\{.*?\})/).filter(Boolean);
  return parts.map((part, index) =>
    part.startsWith('{') && part.endsWith('}') ? (
      <span key={index} className="text-[#e15ce6] text-xs font-medium font-['SF Pro Display']">{part.slice(1, -1)}</span>
    ) : (
      <span key={index}>{part}</span>
    )
  );
};

const ModelSelect = ({model})=> {
  const navigate = useNavigate()
  return (
    <div className="mx-[20px] mt-[10px] w-[335px] h-[56.47px] p-[5px] bg-white/50 rounded-[18px] shadow justify-start items-center gap-2.5 inline-flex"
    onClick={()=>{
      navigate('/model')
    }}
    >
    <img className="w-[45px] h-[45px]  rounded-xl border border-[#e5e7ec] object-contain" src={model.logo} alt="homeAvatar" />

  <div className="grow shrink basis-0 flex-col justify-start items-start gap-3 inline-flex">
    <div className="self-stretch h-[37px] flex-col justify-start items-start gap-1.5 flex">
      <div className="self-stretch text-[#1c1f25] text-sm font-medium font-['SF Pro Display']">{model.name}</div>
     
      <div className="text-[#676c77] text-xs font-medium font-['SF Pro Display']">
      {renderText(`{level ${model.current_level}}/${model.total_level}`)}
      </div>
    </div>
  </div>
  <img src={img_arrow_right} className="w-[25px] h-[25px] relative" />
</div>
  )
}
