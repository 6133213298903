import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import img_rectangle from "../../assets/talk-rectangle.svg";
import img_logo from "../../assets/navbar_logo.png";
import img_listening from "../../assets/talk-listening.gif";
import ic_arrow_left_mini from "../../assets/ic-arrow-left-mini.svg";

export const Talk = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { type } = location.state || {};
    const [chatHistory, setChatHistory] = useState([]);
    const chatContainerRef = useRef(null);
    const recognitionInstance = useRef(null);

    useEffect(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            alert('Your browser does not support Speech Recognition API. Please use a supported browser such as Google Chrome.');
            return;
        }

        recognitionInstance.current = new SpeechRecognition();
        recognitionInstance.current.continuous = true;
        recognitionInstance.current.interimResults = false;
        recognitionInstance.current.lang = navigator.language || 'en-US';

        recognitionInstance.current.onstart = () => {
            console.log('Voice recognition started.');
        };

        recognitionInstance.current.onend = () => {
            console.log('Voice recognition ended.');
            recognitionInstance.current.start(); // 自动重新启动识别
        };

        recognitionInstance.current.onerror = (event) => {
            console.error('Speech recognition error:', event);
            recognitionInstance.current.start(); // 处理错误并重新启动识别
        };

        recognitionInstance.current.onresult = (event) => {
            const transcript = event.results[event.results.length - 1][0].transcript.trim();
            if (transcript) {
                updateChatHistory('User', transcript);
            }
        };

        recognitionInstance.current.start();

        return () => {
            if (recognitionInstance.current) {
                recognitionInstance.current.onend = null;
                recognitionInstance.current.onresult = null;
                recognitionInstance.current.onerror = null;
                recognitionInstance.current.stop();
            }
        };
    }, []);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatHistory]);

    useEffect(() => {
        const getAIResponse = async () => {
            try {
                if (chatHistory.length === 0) return;

                const lastUserMessage = chatHistory[chatHistory.length - 1];
                if (lastUserMessage.sender !== 'User') return;

                const systemMessage = getSystemMessage(type);

                const messages = [
                    {
                        role: 'system',
                        content: systemMessage,
                    },
                    ...chatHistory.map((chat) => ({
                        role: chat.sender === 'User' ? 'user' : 'assistant',
                        content: chat.message,
                    })),
                ];

                const result = await axios.post(
                    'https://api.openai.com/v1/chat/completions',
                    {
                        model: 'gpt-4o',
                        messages: messages,
                        functions: functions,
                        function_call: 'auto'
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer sk-proj-tEK0BXGxntlXBmuiCTNjT3BlbkFJiVBaJ3my0TPrGCIyM4eZ`,
                        },
                    }
                );

                if (result.data.choices && result.data.choices.length > 0) {
                    const message = result.data.choices[0].message;

                    // Check if the response includes a function call
                    if (message.function_call) {
                        const { name, arguments: args } = message.function_call;
                        if (name === 'openApp') {
                            const { deeplink } = JSON.parse(args);
                            openApp(deeplink);
                        }
                    } else if (message.content) {
                        updateChatHistory('AI', message.content.trim());
                    }
                }
            } catch (error) {
                console.error('Error getting AI response:', error);
            }
        };

        getAIResponse();
    }, [chatHistory, type]);

    const getSystemMessage = (type) => {
        switch (type) {
            case 'ask_for_rides':
                return 'You are a helpful assistant. Your purpose is to assist the user with getting a ride with Uber. Ask the user for their starting location and destination.';
            case 'order_food':
                return 'You are a helpful assistant. Your purpose is to assist the user with ordering food with Uber Eats. Ask the user about their cravings or preferred cuisine.';
            case 'shopping':
                return 'You are a helpful assistant. Your purpose is to assist the user with shopping with Amazon. Ask the user what items they are looking to purchase.';
            default:
                return 'You are a helpful assistant. Your purpose is to assist the user with their inquiries.';
        }
    };

    const updateChatHistory = (sender, message) => {
        setChatHistory((prevHistory) => [...prevHistory, { sender, message }]);
    };

    const functions = [
        {
            name: 'openApp',
            description: 'Open a specific app with given parameters',
            parameters: {
                type: 'object',
                properties: {
                    deeplink: {
                        type: 'string',
                        description: 'The full deeplink of the app to open (uber, doordash, amazon)',
                    }
                },
                required: ['deeplink'],
            },
        },
    ];

    const openApp = (deeplink) => {
        window.location.href = deeplink;
    };

    const goBack = () => {
        navigate('/main/action');
    };

    const getHeaderMessage = () => {
        switch (type) {
            case 'ask_for_rides':
                return 'What is your destination?';
            case 'order_food':
                return 'What are you craving to eat right now?';
            case 'shopping':
                return 'What is on your mind?';
            default:
                return '';
        }
    };

    return (
        <div className="w-screen h-screen bg-[#F8FAFC] flex flex-col item-center">
            <nav className="relative">
            <button className="w-[20px] h-[20px] m-4" onClick={() => navigate(-1)}>
                <img src={ic_arrow_left_mini} alt="ic_arrow_left_mini" className="w-full h-full" />
            </button>
            </nav>
    

            <div className="flex w-screen gap-5 absolute top-[98px] flex-col items-center">
                    <div className="relative w-[335px] h-44">
                        <div className="flex flex-col w-[335px] items-center gap-3 p-5 relative rounded-[18px] border border-solid border-[#cacfdb]">
                            <img className="relative w-[100px] h-[100px]" alt="Logo" src={img_logo} />
                            <div className="relative w-[295px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-xl text-center tracking-[0] leading-[normal]">
                                {getHeaderMessage()}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="absolute w-screen  h-[474px] top-[563px]">
                    <div className="relative h-[474px] rounded-[237.25px/237.24px]">
                        <div className="absolute w-[475px] h-[474px] top-0 left-[-40px] rounded-[237.25px/237.24px] [background:linear-gradient(180deg,rgb(255,77.56,144.1)_0%,rgba(255,78,144,0)_100%)] blur-16">
                            <div className="relative w-[365px] h-[365px] top-[55px] left-[55px] rounded-[182.34px/182.36px] [background:linear-gradient(180deg,rgb(85,132.6,255)_0%,rgba(85,244.8,255,0)_100%)]">
                                <div className="relative w-[253px] h-[253px] top-14 left-14 rounded-[126.72px/126.64px] [background:linear-gradient(180deg,rgb(208.22,87.12,255)_0%,rgba(208,87,255,0)_100%)]" />
                            </div>
                        </div>
                        <img className="absolute w-[302px] h-24 top-16 left-1/2 transform -translate-x-1/2 object-cover" alt="Image" src={img_listening} />
                    </div>
                </div>
                
                <div className="absolute top-[310px] left-[20px] right-[20px] bottom-[280px] overflow-y-auto" ref={chatContainerRef} style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                    <div className="relative w-full h-full flex flex-col gap-3">
                        {chatHistory.map((chat, index) => (
                            <div key={index} className={`flex ${chat.sender === 'User' ? 'justify-end' : 'justify-start'}`}>
                                <div className={`p-3 rounded-lg max-w-[80%] ${chat.sender === 'User' ? 'bg-blue-100' : 'bg-gray-100'}`}>
                                    {chat.message}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>


            </div>
    );
};
