import { useLocation, useNavigate } from "react-router-dom";
import ic_arrow_left_mini from "../../assets/ic-arrow-left-mini.svg";
import { useEffect, useState } from "react";
import img_logo from "../../assets/navbar_logo.png";
import { getRequest } from "../NetWork/NetWork";
import { LoadingIndicator } from "../Loading/Loading";
import Voice from "../../assets/agent-voice.png";

export const ShoppingAgentPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const actionid = queryParams.get('action_id');

    const [loading, setLoading] = useState(false)
    const [placeList , setPlaceList] = useState([])
     const getList = async ()=>{
        setLoading(true)
        const params = {
            action_id: actionid
        }
        const list = await  getRequest("/action/categories", params)
        if (list) {
            setPlaceList(list)
        }
        setLoading(false)
     }

     const gotoChat = async (item)=> {
        const params = {
            action_id: actionid,
            lat: item.latitude,
            lng: item.longitude,
            name: item.name,
            address: item.address,
            category: item.name
            // category: 
        }
        setLoading(true)
         const data = await getRequest("/action/steps", params)
         setLoading(false)
         if (data) {
            navigate('/riderAgentChat', {
                state: { data, actionid: actionid }
            })
         }
     }

    useEffect(()=>{
        getList()
    }, [])

  
    return (
        <div className="w-[375px] h-screen bottom-[30px] bg-[#F8FAFC] flex flex-col items-center rounded-[10px]">
          <nav className="relative w-full">
            <button className="absolute left-[20px] top-[20px] w-[20px] h-[20px]" onClick={() => navigate(-1)}>
            <img src={ic_arrow_left_mini} alt="ic_arrow_left_mini" className="w-full h-full" />
            </button>
          </nav>
     
     <div className="flex flex-col w-[375px] gap-5 absolute bottom-[30px] top-[98px] flex-col items-center overflow-y-auto">
        <div className="flex w-[335px] gap-5  flex-col">
            <div className="relative w-[335px] h-44">
                <div className="flex flex-col w-[335px] items-center gap-3 p-5 relative rounded-[18px] border border-solid border-[#cacfdb]">
                    <img className="relative w-[100px] h-[100px]" alt="Logo" src={img_logo} />
                    <div className="w-[295px] text-center text-[#1c1f25] text-xl font-medium font-['SF Pro Display']">
                    What is on your mind?</div>
                </div>
            </div>
        </div>

        {/* List */}
        <div className="grid grid-cols-2 gap-x-5 gap-y-7 p-4">
            {placeList.map((item, index) => (
            <div className="w-[161px] h-[50px] p-2.5 bg-white rounded-xl shadow justify-start items-center gap-[5px] inline-flex"
            onClick={()=>{
                gotoChat(item)
              }}
            >
                <div className="w-[30px] h-[30px] relative rounded-[10px]">
                  <img className="w-[30px] h-[30px] left-0 top-0 absolute" src={item.icon} />
                </div>
                <div className="w-28 text-[#1c1f25] text-[11px] font-medium font-['SF Pro Display']">
                    {item.name}</div>
            </div>

            ))}
          
         </div>
         <img src={Voice} alt="Voice" className="w-[45px] h-[45px] mt-[10px]" 
                onClick={()=>{
                    navigate(`/talk`);
                }}
            />
       </div>
       <LoadingIndicator isLoading={loading} />
      </div>
      
    );
  };