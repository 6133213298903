
import React from "react";
import { Navbar } from "../Navbar";
import TimesIcon from "../../assets/action-times.png"
import NOTimesIcon from "../../assets/action-notime.png"

const ActionUpdateAlert = ({ isOpen, onClose, times}) => {
    if (!isOpen) return null;
    const notime = times==0?true:false

    const height = notime? '450px':'405px'

    const renderText = (text) => {
        const parts = text.split(/(\{.*?\})/).filter(Boolean);
        return parts.map((part, index) =>
          part.startsWith('{') && part.endsWith('}') ? (
            <span key={index} className="text-[#e15ce6] text-lg font-normal font-['SF Pro Display']">{part.slice(1, -1)}</span>
          ) : (
            <span key={index}>{part}</span>
          )
        );
      };

    return (
        <div className="flex flex-col min-h-screen justify-center">
            {/* Background overlay */}
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div
                     className="flex items-center justify-center w-screen h-screen inset-0 bg-black bg-opacity-50"
                    onClick={onClose}
                ></div>

                {/* Modal */}
                <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 bg-white rounded-tl-[40px] rounded-tr-[40px] shadow-lg" style={{ width: '100vw', height: height }}>

                <div className="mt-[56px] flex flex-col items-center justify-center gap-[25px]">
                        <img src={notime? NOTimesIcon : TimesIcon} alt="TimesIcon" className="w-[80px] h-[80px] relative" />
                        
                        <div className="w-[260px] text-black text-center text-[25px] font-bold font-['SF Pro Display']">
                            {notime?"Hi PIN used up": "Hi PIN usage"}</div>

                        <div className="w-[260px] text-[#676c77] text-center text-lg font-normal font-['SF Pro Display']">
                            {notime?renderText(`You've used all your Hi PIN allowances for today. Please come back`):
                             renderText(`{${times} uses remaining} for today`)}
                        </div>

                        <div className="mt-[25px] w-[300px] h-[50px] px-6 py-3.5 bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex" onClick={onClose}>
                            <div className="text-white text-base font-medium font-['SF Pro Display']">OK</div>
                        </div>
                       
                 </div>
                </div>
            </div>


            <Navbar className="custom-navbar z-10" />
        </div>
    );
  };
  
  export default ActionUpdateAlert;