import React, { createContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { use } from 'i18next';
import { Login } from './Login/Login';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const { isAuthenticated, getIdTokenClaims, loginWithRedirect } = useAuth0();
    const login = localStorage.getItem('is_login_pinai')==='1'?true: false
    const [isLogin, setIsLogin] = useState(login)
    
    // const location = useLocation(); // 获取当前路由信息
    useEffect(() => {
    //   console.log("open page:", window.location.href)
      // 每次路由变化时触发
      const login = localStorage.getItem('is_login_pinai')==='1'?true: false
      setIsLogin(login)
      const checkAuthentication = async () => {
        if (isAuthenticated) {
          try {
            const authToken = await getIdTokenClaims();
            if (authToken) {
                //刷新token
                localStorage.setItem('authToken', authToken.__raw)
                console.log("token", authToken.__raw)
            }
          } catch (error) {
            
          }
        } else {
          //没有授权
          console.log("not isAuthenticated")
        }
      };
  
      checkAuthentication();
    }, []); 

    useEffect(() => {
        window.scrollTo(0, 0);
        // setTimeout(() => {
        //   document.documentElement.style.overflow = 'hidden';
        // }, 0);
       
        // return () => {
        //   document.documentElement.style.overflow = 'auto';
        // };
      }, []);



    // location 是依赖项，每次路由变化都会触发 useEffect
    return (
        <AuthContext.Provider value={{ isAuthenticated }}>
          {children}
        </AuthContext.Provider>
      );
  };
