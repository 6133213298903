import React, { useEffect, useState } from "react";
import { useNavigate, useNavigationType } from "react-router-dom";
import img_fastfood from "../../assets/action-fast-food-1.gif";
import { Coins4 } from "../../icons/Coins4";
import { ColorfulBubble } from "../../icons/ColorfulBubble";
import { Power2 } from "../../icons/Power2";
import { Power5 } from "../../icons/Power5";
import ActionFaceAlert from "./ActionFaceAlert";
import { getRequest, postRequest } from "../NetWork/NetWork";
import { LoadingIndicator } from "../Loading/Loading";
import ActionXlert from "./ActionXlert";
import ActionLock from "../../assets/action-lock.png";
import AuthConnetctAlert from "../AuthoAlert/AuthConnetctAlert";
import { useTranslation } from 'react-i18next';
import { AuthProvider } from "../PIAuthCheckContext";
import ActionUpdateAlert from "./ActionUpdateAlert";
import DataConnectSuccessAlert from "../Data/DataConnectSuccessAlert";


const handleAuthorization = (item, setAuthoParams) => {
  localStorage.setItem("globalAuthType", item.depend_con_type)
  
  if (item.action_id === 2004 || item.action_id == 2005 || item.action_id == 2007) {
    var scopeStr = "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.birthday.read"

    if (item.action_id == 2005) {
      //Gmail
      scopeStr = "https://www.googleapis.com/auth/gmail.readonly"
    } else if (item.action_id === 2004){
    //  "GoogleCalendar" 
      scopeStr = "https://www.googleapis.com/auth/calendar.readonly"
    } else if (item.action_id === 2007) {
      scopeStr = "https://www.googleapis.com/auth/user.birthday.read"
    }
    googleAouth(scopeStr)
  } else if (item.action_id == 2006) {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          const token = response.authResponse.accessToken;  
          const params = {
            "connector_type": "Facebook",
            "is_connect": true,
            "access_token": token
          }
          setAuthoParams(params)
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        scope:
          'user_posts, user_friends',
      }
    );
  }
};

function googleAouth(scopeStr) {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const redirectUri = window.location.origin + "/main/action";
  const scope = encodeURIComponent(
    scopeStr
  );
  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=${scope}&include_granted_scopes=true&response_type=code&access_type=offline&redirect_uri=${redirectUri}&client_id=${clientId}&prompt=consent`;
  window.location.href = authUrl; 
}


export const Action = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [showXAlert, setShowXAlert] = useState(false)
  const [dataList, setDataList] = useState([]) 

  const { t , i18n} = useTranslation();

  const switchConnector = async (params) => {
    try {
      const result = await postRequest("/connector/switch", params)
      if (result) {
        return result;
      }
    } catch(error) {
      console.error('connector/switch Request failed:', error);
    }
  };

  const authoSuccessAction =  "authoSuccessItem"
  // connect data alert
  const [showConnectAlert, setShowConnectAlert] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [authoParams, setAuthoParams] = useState(null)
  const connectChannel = (item) => {
    localStorage.setItem(authoSuccessAction, JSON.stringify(item))
    handleAuthorization(item, setAuthoParams)
  }
  
  const showConnectSucc = ()=> {
     console.log("showConnectSucc")
     localStorage.removeItem(needShowConnect)
    const item = JSON.parse(localStorage.getItem(authoSuccessAction))
      if (item) {
        setSelectedItem(item)
        setIsConnectSuc(true)
        localStorage.removeItem(authoSuccessAction)
      }
  }
 const closeConnectSucc = (item) => {
  setIsConnectSuc(false)
  showUpTime()
 }

  //connect auth success
  const [isConnectSuc, setIsConnectSuc] = useState(false);
  useEffect(() => {
    // if(loading)
    //   return;
    
    const queryParams = new URLSearchParams(window.location.search);
    // 提取出 `code` 参数
    const code = queryParams.get('code');
    var needfresh = true
    if (code) {
      //google auth成功
      const authType = localStorage.getItem("globalAuthType")
      const redirectUri = window.location.origin + "/main/action";
      const params = {
        "connector_type": authType,
        "is_connect": true,
        "auth_code": code,
        "redirect_uri": redirectUri
      }
      //调用swicth接口
      setAuthoParams(params)

      // 移除 URL 中的 access_token
      const newUrl = window.location.origin + window.location.pathname;
      window.history.replaceState(null, '', newUrl);
      needfresh = false
    }
    if (needfresh) {
      featchListData()
    }
  }, []);


 //swtich api
 useEffect(()=>{
  if (authoParams == null) return;
  const type = authoParams.connector_type
  switchConnector(authoParams).then(result => {
    if (result) {
      const item = JSON.parse(localStorage.getItem(authoSuccessAction))
      if (item) {
        item.reward_points = result.reward_points
        item.power_growth = result.data_power
        localStorage.setItem(authoSuccessAction, JSON.stringify(item))
        localStorage.setItem(needShowConnect, "1")
        gotoAction(item)
      }
    } else {
      localStorage.removeItem(authoSuccessAction)
    }
  })
}, [authoParams])


  
  useEffect(() => {
    // Load the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v10.0',
      });
    };
  
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);



  const [level, setLevel] = useState(0)
  const [dailyTimes, setDailyTimes] = useState(0)
  const [usageTimes, setUsageTimes] = useState(0)
  const [showUpAlert, setShowUpAlert] = useState(false)
  const needShowConnect = "needShowConnect"
  const needTimeTips = "needTimeTips"
  const navigationType = useNavigationType()
  if (navigationType ==='POP') {
  }

  const featchListData = async()=>{
    setLoading(true)
    const data = await getRequest("/action/list")
    if (data) {
      setDataList(data.actions)
      setLevel(data.level)
      setDailyTimes(data.daily_times)
      setUsageTimes(data.usage_times)
      if (localStorage.getItem(needShowConnect) === '1') {
        showConnectSucc()
      } else if (localStorage.getItem(needTimeTips)=== '1') {
        showUpTime()
      }
      
    }
    setLoading(false)
  }


  const showUpTime = () => {
    setShowUpAlert(true)
    localStorage.removeItem(needTimeTips)
  }

  const closeConnectAlert = ()=>{
    setShowConnectAlert(false)
  }


  const handleCardClick = (card) => {
    if (card.depend_level > level) {
      const title = card.title
      // navigate(`/talk`, { state: { title } });
      // navigate(`/riderAgentPage?action_id=${card.action_id}`);
      // navigate(`/foodAgentPage?action_id=${card.action_id}`);
      // navigate(`/shoppingAgentPage?action_id=${card.action_id}`);
      return;
    }

    if (card.available == 0) {
        setSelectedItem(card)
        setShowConnectAlert(true)
        return;
    }
    if (dailyTimes == usageTimes) {
      setShowUpAlert(true)
      return
    }
    localStorage.setItem(needTimeTips, "1")
    gotoAction(card)
  };

  const gotoAction = (card)=> {
    if (card.action_id == 2005 || card.action_id == 2007 || card.action_id == 2004) {
      navigate(`/friendSummary?type=${card.action_id}`)
    } else if (card.action_id == 2006) {
        // setShowFaceAlert(true)
        navigate("/faceselect")

    } else if (card.action_id == 2008) {
        setShowXAlert(true)
    } else if (card.action_id == 2002) {
      navigate(`/riderAgentPage?action_id=${card.action_id}`);
    } else if (card.action_id == 2003) {
      navigate(`/shoppingAgentPage?action_id=${card.action_id}`);
    } else if (card.action_id == 2001) {
      navigate(`/foodAgentPage?action_id=${card.action_id}`);
    } else  {
      const title = card.title
      navigate(`/talk`, { state: { title } });
    }
  }

  return (
   <AuthProvider children={
    <div className="w-[375px] h-screen bg- overflow-hidden !important">
    <div className="relative h-full">
      <div
        className="absolute bg-[#F8FAFC] w-[375px] h-full"
      />
      {/* <img className="absolute w-[375px] h-[1040px] top-0 left-0" alt="Rectangle" src={img_rectangle} /> */}
      <div className="flex flex-col w-[307px] items-center absolute top-[44px] left-[34px]">
        <div className="relative self-stretch mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-xl text-center tracking-[0] leading-[normal]">
          Hi PIN
        </div>
        <p className="relative self-stretch h-[71px] [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-element-light2nd-text text-sm text-center tracking-[0] leading-5">
          Use Hi PIN to complete the following tasks, boost your action score, and earn more PIN Points.
        </p>
      </div>
      <div className="flex flex-wrap w-[335px] items-center gap-[13px_13px] absolute top-[150px] left-[22px] bottom-[90px] overflow-y-auto">
        {dataList.map((card, index) => (
          <div
            key={index}
            className={`flex w-[161px] items-start gap-2.5 p-4 relative bg-element-lightwhite rounded-[18px] shadow-shadow`}
            onClick={() => handleCardClick(card)}
          >
            <div className="inline-flex flex-col items-center gap-2 relative flex-[0_0_auto] mr-[-1.00px]">
              <div className="flex w-[45px] h-[45px] items-center justify-center relative bg-secondarylight-grass-green rounded-[100px]">
                <img className="!rounded-[10px] !h-[45px] !w-[45px] !left-[-5px] !top-[-5px]" src={card.url} />
              </div>
              <div className="relative w-fit [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-base tracking-[0] leading-[normal] whitespace-nowrap">
                {card.title}
              </div>
              <p className="relative w-[130px] [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-element-light2nd-text text-xs tracking-[0] leading-[15px]">
                {card.description}
              </p>
           
              <div className="flex w-[130px] items-center justify-between relative flex-[0_0_auto]">
                <div className="inline-flex items-center gap-[3px] relative flex-[0_0_auto]">
                  <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarypin-ai-pink text-xs tracking-[0] leading-[normal] whitespace-nowrap">
                    PIN Points:
                  </div>
                </div>
                <div className="px-[5px] py-0 [background:linear-gradient(180deg,rgb(112,168,235)_0%,rgb(180,149,245)_33.33%,rgb(227,86,197)_66.67%,rgb(249,173,88)_100%)] inline-flex h-[18px] items-center justify-end gap-[5px] relative flex-[0_0_auto] rounded-lg">
                  <div className="relative w-fit [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-xs text-right tracking-[0] leading-[15px] whitespace-nowrap">
                    &nbsp;&nbsp;{card.points}
                  </div>
                  <Coins4 className="!relative !w-[11px] !h-[11px]" />
                </div>
              </div>
            </div>
            {card.depend_level > level && (//
              <div className="absolute top-0 left-0 w-full h-full bg-[#1C1F25] bg-opacity-50 rounded-[18px] flex flex-col items-center" 
              >
                 <img  src={ActionLock} alt="ActionLock"  className="mt-[96px] w-[40px] h-[40px]" />
                 <div className="h-[19px] text-center text-white text-base font-bold font-['SF Pro Display'] leading-[15px]">Level {card.depend_level}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>

   {/* <ActionFaceAlert isOpen={showFaceAlert} discover={(name)=>{
     navigate(`/friendSummary?type=2006&name=${name }`)
   }} onClose={()=>{
      setShowFaceAlert(false)
   }}/> */}
{/* isOpen, onClose, item, connect */}
 <AuthConnetctAlert isOpen={showConnectAlert} onClose={closeConnectAlert} connect={(item)=>{connectChannel(item)}} item={selectedItem}/>
<ActionXlert isOpen={showXAlert} discover={(name)=>{
      navigate(`/friendSummary?type=2008&name=${name}`)
    }} onClose={()=>{
      setShowXAlert(false)
    }}/>
<ActionUpdateAlert times={dailyTimes-usageTimes} isOpen={showUpAlert} onClose={()=>{
  setShowUpAlert(false)
 }} />

<DataConnectSuccessAlert point={selectedItem?.reward_points} isOpen={isConnectSuc} onClose={closeConnectSucc}
 item={selectedItem} />

  <LoadingIndicator isLoading={loading} />

  </div>
   }/>
  );
};
