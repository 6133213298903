/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Power2 = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="19"
      viewBox="0 0 18 19"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1213_7267)">
        <path
          d="M10.2853 1.14258V7.57115H14.7853L7.71387 17.8569V11.4283H3.21387L10.2853 1.14258Z"
          stroke="#676C77"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1213_7267">
          <rect fill="white" height="18" transform="translate(0 0.5)" width="18" />
        </clipPath>
      </defs>
    </svg>
  );
};
