
import React from "react";

export const LoadingIndicator = ({ isLoading }) => {
    return (
      isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-10 z-50">
        <div className="w-10 h-10 border-4 border-solid border-t-4 border-t-white border-gray-300 rounded-full animate-spin"></div>
      </ div>
      
      )
    );
};