/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import img_gift from "../../assets/account-gift-1.gif"

export const GiftInvite = ({ className }) => {
  return (
    <div className={`relative w-[50px] h-[50px] bg-cover bg-[50%_50%] ${className}`} style={{backgroundImage: `url(${img_gift})`}}/>
  );
};
