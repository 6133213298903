import { useEffect, useState } from "react"
import Coin from "../../assets/guide-coin.png";
import Agent from "../../assets/guide-agent.png";
import Model from "../../assets/guide-model.png";
import EText from "../../assets/guide-text.png";
import GuideAlert from "./GuideAlert";

export const GuidePage = ({onFinish})=>{
  const [showAlert, setShowAlert] = useState(false)
    const [page, setPage] = useState(1)
    const [imageName, setImageName] = useState(Agent)
    useEffect(()=>{
      if (page == 2) {
        setTitle("Connect Data")
        setSubtitle("Connect your accounts to supply data to your Hi PIN and earn PIN points. The more accounts you connect, the more points you earn.")
        setImageName(Coin)
      } else if (page == 3) {
        setTitle("Train AI Model")
        setSubtitle("Train your own AI model - Hi PIN by providing it with data, making it increasingly powerful. You can continuously earn points throughout the process.")
        setImageName(Model)
      } else if (page == 4) {
        setTitle("Invite Friends")
        setSubtitle("Invite your friends to join us and unlock countless benefits together! Share the experience, earn rewards, and enjoy exclusive features.")
        setImageName(EText)
      } else if (page > 4) {
        onFinish()
      }
    }, [page])
    const [title, setTitle] = useState("Assign tasks to “Hi PIN”")
    const [subtitle, setSubtitle] = useState("Hi PIN is your personal AI assistant, dedicated to meeting your needs. Simply click to access Hi PIN and unlock its full potential.")
    return (
        <div className="w-screen h-screen bg-element-lightwhite flex flex-col items-center">
            <div className="relative h-full w-[300px]">
                <div className="mt-[130px] text-center text-[#1c1f25] text-2xl font-medium font-['SF Pro Display']">
                {title} </div>

                <div className="opacity-80 text-center text-[#313741] text-lg font-normal font-['SF Pro Display'] leading-snug">
                        {subtitle}
                        </div>
                {page==2 && <div className="opacity-80 text-center text-[#e15ce6] text-lg font-normal font-['SF Pro Display'] leading-snug"
                 onClick={()=>{
                  setShowAlert(true)
                 }}
                >Will my data be secure?</div>}

                <img  src={imageName} alt="Coin" className="mt-[60px] w-[300px] h-[300px] object-contain" />


                <div className="absolute w-[295px] h-[45px] px-6  bottom-[100px] bg-[#1b1c1e] rounded-xl shadow justify-center items-center gap-2.5 inline-flex"
                onClick={()=>{
                    setPage(prevPage => prevPage + 1)
                }}
                >
                <div className="text-white text-sm font-medium font-['SF Pro Display']">Continue</div>
                </div>
            </div>

            <GuideAlert isOpen={showAlert} onClose={()=>{
              setShowAlert(false)
            }} />
        </div>
    )

}

{/* <div className="w-screen h-screen bg-element-lightwhite">
<div className="relative  h-screen w-full flex flex-col items-center"> */}