
import React from "react";
import { Navbar } from "../Navbar";
import HomePower from "../../assets/home-power.png"

const HomeTipsAlert = ({ isOpen, onClose, item}) => {
    if (!isOpen) return null;
    const height = item.name=="PIN"? '405px' : '471px';

    return (
        <div className="flex flex-col min-h-screen justify-center">
            {/* Background overlay */}
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div
                     className="flex items-center justify-center w-screen h-screen inset-0 bg-black bg-opacity-50"
                    onClick={onClose}
                ></div>

                {/* Modal */}
                <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 bg-white rounded-tl-[40px] rounded-tr-[40px] shadow-lg" style={{ width: '100vw', height: height }}>

                <div className="flex flex-col items-center justify-center">
                        <div className="mt-[54px] h-[39px] text-center text-[#1c1f25] text-[24px] font-medium font-['SF Pro Display']">{item.name} Power</div>
                        <div className="flex gap-[10px] items-center">
                            <span className="text-[#e15ce6] text-[25px] font-bold font-['SF Pro Display']">{item.core}</span>
                            {/* <span>ssdfsd</span> */}
                            <img src={HomePower} alt="HomePower" className="w-[22px] h-[22px] relative" />
                        </div>
                       
                       <div className="mt-[25px] mx-[63px] space-y-[10px]">
                           {item.name!="PIN" && (
                            <div className="w-[250px] text-black text-[16px] font-medium font-['SF Pro Display'] leading-[21px]">
                                {item.name} Power is your energy power of Hi PIN Data connectors!
                            </div>
                           )}
                            <div className="text-black text-[16px] font-medium font-['SF Pro Display']">
                                {item.name=="PIN"? "PIN Power means you are able to collec" : "This number means you are able to collect"} 
                                <span className="text-[#e15ce6]"> {item.core} PIN Points per day.</span>
                                
                            </div>

                            {item.name=="PIN" && (<div>
                                It is comprised of your Data Power and Action Power.
                            </div>)}
                            
                            {item.name=="Data" && (<div>
                            To boost your Power:<br />
                            1. Connect more data accounts <br />
                            2. Upgrade a data connector
                            </div>)}

                            {item.name=="Action" && (<div>
                                To boost your Power, complete more Hi PIN actions and watch your Action Power rise!
                            </div>)}
                            
                       </div>

                        <div className="mt-[25px] w-[300px] h-[50px] px-6 py-3.5 bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex" onClick={onClose}>
                            <div className="text-white text-base font-medium font-['SF Pro Display']">Got it!</div>
                        </div>
                       
                 </div>
                </div>
            </div>


            <Navbar className="custom-navbar z-10" />
        </div>
    );
  };
  
  export default HomeTipsAlert;
  