

import React from "react";
import { Navbar } from "../Navbar";
import HeadIcon from "../../assets/task-piggy-bank.png"
import CoinIcon from "../../assets/task-coins.png"
import Cur from "../../assets/task-daily-cur.png"
import Check from "../../assets/task-daily-check.png"



// "checkin_points": {
//   "1": 500,
//   "2": 1000,
//   "3": 2500,
//   "5": 5000,
//   "7": 15000,
//   "10": 25000,
//   "14": 100000,
//   "20": 500000,
//   "30": 1000000
// },


const TaskDailyAlert = ({ isOpen, onClose, item}) => {
    if (!isOpen) return null;
    const height = '695px';
    const detail = item.checkin_detail
    const cardsData = Object.entries(detail.checkin_points)

    const canCollect = detail.is_today_checkin == 0
    const cardslength = Object.keys(cardsData).length;

    const tempList = Object.entries(detail.checkin_points)
    .map(([day, value]) => ({
      day: day.trim(), // Ensure no extra spaces in day key
      value: value.toString() // Convert value to string
    }))
    .sort((a, b) => parseInt(a.day) - parseInt(b.day)); // Sort by day numerically
  
    const rows = [];
    const itemsPerRow = 3;
    
    // Iterate through the sorted array in chunks of 'itemsPerRow'
    for (let i = 0; i < tempList.length; i += itemsPerRow) {
      const row = tempList.slice(i, i + itemsPerRow);
      // Reverse the order of every other row
      rows.push((Math.floor(i / itemsPerRow) % 2 === 1) ? row.reverse() : row);
    }
    
    // Flatten the rows into a single array
    const cardList = rows.flat();
  
    var consecutive_days = detail.consecutive_days

    // consecutive_days = 2


    return (
        <div className="flex flex-col min-h-screen justify-center">
            {/* Background overlay */}
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div
                    className="flex items-center justify-center w-[375px] h-screen inset-0 bg-black bg-opacity-50"
                ></div>

                {/* Modal */}
                <div className="flex flex-col justify-start items-center fixed bottom-[0px] left-1/2 transform -translate-x-1/2 bg-white rounded-tl-[40px] rounded-tr-[40px] shadow-lg" style={{ width: '375px', height: height }}>
                    <img className="mt-[31.5px] w-[92.80px] h-[94.40px]" src={item.logo} />
                    <div className="flex items-center justify-center mt-[0px] w-[334px] min-h-[37px] text-center text-black text-base font-medium font-['SF Pro Display'] leading-snug">
                        You've been on a {consecutive_days} day check-in streak!</div>

                    {canCollect?
                    <div class="justify-start items-center inline-flex h-[30px] mt-[8px]">
                      <div class="text-black  text-[25px] font-bold font-['SF Pro Display']">+{item.reward_points}</div>
                      <img src={CoinIcon} alt="CoinIcon" class="w-[18.57px] h-[18.57px] ml-[5px]" />
                    </div>:
                    <div >
                      <div className="mt-[8px] text-center text-[#e15ce6] text-[11px] font-medium font-['SF Pro Display']">Next reward:</div>
                      <div className="h-[30px] text-black text-[25px] font-bold font-['SF Pro Display']">4 day: 22 hr</div>
                    </div>
                    }
                    {/* gap-x-[40px] gap-y-[60px] */}

                    <div className="mt-[30px] grid grid-cols-3 w-[334px] h-[360px] py-[7px]">
  {cardList.map((item, index) => {
    const column1 = index % 3 === 0;
    const column2 = index % 3 === 1;
    const column3 = index % 3 === 2;
    const row_even = Math.floor(index / 3) % 2 === 0
    const showLine1 = index < cardslength -1 && (!row_even && column3 || (row_even && column1 && index>0)) 
    // const showLine2 =  false
    const showLine2 = index < cardslength-1 && ((row_even && column3) || (!row_even && column1))
    var line1Color = "#d9d9d9"
    var line2Color = "#d9d9d9"
    var rowline1Color = "#d9d9d9"
    var rowline2Color = "#d9d9d9"
    
    var cancheck = false

    // consecutive_days = 20
    const dayAsInt = parseInt(item.day, 10);
    var bgColor = '#EAE0FF'
    var textColor = "#e15ce6"
    //border
    var showborder = false
    //dot img 0: gray  1: await finish  2: finish
    var dotStyle = 0
    if (dayAsInt == consecutive_days || (consecutive_days==0 && index==0)) {
      bgColor = '#ffffff'
      textColor = "#e15ce6"
      cancheck = true
      showborder = true
      dotStyle = 1
      line1Color = "#e15ce6"
      rowline1Color = "#e15ce6"
    } else if (dayAsInt > consecutive_days) {
      bgColor = '#d9d9d9'
      textColor = "#676c77"
      dotStyle = 0
      if (showLine1) {
        line1Color = "#d9d9d9"
      }
    } else {
      dotStyle = 2
      if (showLine1) {
        line1Color = "#e15ce6"
      }
      if (showLine2) {
        line2Color = "#e15ce6"
      }
      rowline1Color = "#e15ce6"
      rowline2Color = "#e15ce6"
    }
    
    const card  =  { day: 'Day '+item.day, value: item.value, bgColor: bgColor, textColor: textColor, showBorder: showborder}
    return (
      <div key={index} className="relative flex flex-col justify-start items-center w-full h-full">
        <Card card={card} />
        
        {/* showLine1 */}
        {showLine1 ? <div className={`w-[3px] h-[9px] bg-[${line1Color}]`}></div>
        :  <div className="w-[3px] h-[9px]"/>
      }
        {dotStyle==0 && <div className="w-[20px] h-[20px] bg-[#d9d9d9] rounded-[10px] z-10" />
        }
         {dotStyle==1 && <img className="w-[20px] h-[20px] bg-[#e15ce6] rounded-[10px] z-10" src={Cur} alt="Cur" />
        }
        {dotStyle==2 && <img className="w-[20px] h-[20px] bg-[#e15ce6] rounded-[10px] z-10" src={Check} alt="Cur" />
        }
        
        {/* rowline1Color rowline2Color */}
        {column1 && (
          <div
            className={`mt-[-10px] h-[3px] bg-[${rowline2Color}] z-0 w-[50%] ml-auto`}
          ></div>
        )}
         {column2 && (
          <div className="flex flex  h-[3px] w-full z-0">
            <div
              className={`mt-[-10px] h-[3px] bg-[${rowline1Color}] z-0 w-[50%] ml-auto`}
             ></div>
            <div
              className={`mt-[-10px] h-[3px] bg-[${rowline2Color}] z-0 w-[50%] mr-auto`}
            ></div>
          </div>
        )}

        {column3 && (
          <div
            className={`mt-[-10px] h-[3px] bg-[${rowline2Color}] z-0 w-[50%] mr-auto`}
          ></div>
        )}

        {/* line2 */}
        {showLine2 && <div className={`absolute bottom-0  w-[3px] h-[calc(100%-69px-20px)]  bg-[${line2Color}]`}></div>}
        {/* {index < cardsData.length-1 && !row_even && column3 && <div className={`w-[3px] h-[19px)]  bg-[#e15ce6]`}></div>} */}
        
      </div>
    );
  })}
</div>

                     {/* button */}
                     {canCollect? 
                     <div className="mt-[34px] mx-[22px] self-stretch h-[50px] px-6 py-3.5 bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex" onClick={()=>{onClose(item)}}>
                      <div className="text-white text-base font-medium font-['SF Pro Display']" >Collect!</div>
                     </div> :
                     <div className="mt-[34px] mx-[22px] self-stretch h-[50px] py-3.5 bg-white border-[1px] border-solid border-[#e15ce6] rounded-xl shadow justify-center items-center inline-flex" onClick={()=>{onClose(item)}}>
                      <div className="text-[#e15ce6] text-base font-medium font-['SF Pro Display']" >Come back tommorrow</div>
                    </div>}

                   </div>
            </div>


            <Navbar className="custom-navbar z-10" />
        </div>
    );
  };

  // w-[85px] h-[60px]
  const Card = ({card})=>{
    return (
    <div className={`w-[85px] h-[60px] px-[5px] py-2.5 rounded-xl flex flex-col justify-start items-center gap-1.5 ${
      card.showBorder ? `border-[1px] border-solid border-[#e15ce6]` : ''
      }`}
      style={{ backgroundColor: card.bgColor }}
      >
      <div className="justify-center items-center gap-1 inline-flex">
        <div className="text-right text-base font-medium font-['SF Pro Display']"
          style={{ color: card.textColor }}
        >
          {card.value}
        </div>
        <img className="w-[18.57px] h-[18.57px]" src={CoinIcon} alt="CoinIcon" />

      </div>
      <div className="self-stretch text-center text-[#1c1f25] text-xs font-medium font-['SF Pro Display']">
      {card.day}
      </div>
    </div>
    )
  }
  
  export default TaskDailyAlert;