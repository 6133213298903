import React, { useEffect, useState } from "react";
import { Splash } from "../components/Splash/Splash";
import { useNavigate } from "react-router-dom";
import { Onboard } from "../components/Onboard/Onboard";
import { GuidePage } from "../components/Guide/GuidePage";

const SplashPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      const flag = localStorage.getItem("is_onboard_complete");
      if (flag === "1") {
        navigate("/main", { replace: true });
      }
    }, 2000);
  }, []);

  const handleOnboardFinish = () => {
    localStorage.setItem("is_onboard_complete", "1");
    navigate("/main", { replace: true });
  };

  return (
    <>{isLoading ? <Splash /> : <GuidePage onFinish={handleOnboardFinish} />}</>
  );
};

export default SplashPage;
