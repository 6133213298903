import React, { useEffect, useState } from 'react';
import { Navbar } from "../Navbar";
import PrivateNotice from "../../assets/data-private-notice.png";
import PrivateIcon from "../../assets/data-privacy-icon.png";

const GuideAlert = ({ isOpen, onClose}) => {

const height = "450px"
const cancel = ()=> {
  onClose()
}
if (!isOpen) return null;

  return (
    <div className="flex flex-col h-screen justify-center">
      <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 flex items-center justify-center">
        <div
          className="absolute inset-0 bg-black bg-opacity-50"
          onClick={()=>{
            cancel()
          }}
        ></div>
        
        {/* Modal */}
        <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-4 bg-white rounded-tl-[40px] rounded-tr-[40px] shadow-lg" style={{ width: '100vw', height: height }}>
         
          {/* Modal Body */}
          <div className="pt-[50px] flex flex-col items-center justify-center gap-[25px]">
             <PrivacyCard />
{/* button */}
            <div className="w-[300px] h-[50px]  py-3.5  bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex" onClick={()=>{
                cancel()
                }}>
                <div className="text-white text-base font-medium font-['SF Pro Display']">Nice!</div>
            </div>
          </div>
        </div>
      </div>
      </div>

      <Navbar className="custom-navbar z-10" />

    </div>
  );
};


const PrivacyCard = ()=>{
    const list =[
        "Your data is never stored",
        "Your data will be deleted after use",
        "Used only for your requests"
    ]
    return (
        <div className='flex flex-col items-center justify-center gap-[25px]'>
        <img className="w-[60px] h-[60px]  rounded-xl" src={PrivateNotice} />
          <div className="h-[31px] text-center text-black text-2xl font-medium font-['SF Pro Display']">Your privacy is protected!</div>
          <div className="flex flex-col justify-between items-center inline-flex gap-[19px]">
            {list.map((item, index)=>(
                <div key={index} className='w-[300px] flex items-center  gap-2.5 inline-flex'>
                    <img src={PrivateIcon} alt='PrivateIcon' className='w-[30px] h-[30px]'></img>
                    <div className="text-black text-base font-normal font-['SF Pro Display'] leading-[21px]">{item}</div>
                </div>
            ))}
          </div>
    </div>
    )
}

export default GuideAlert;