import PropTypes from "prop-types";
import React from "react";
import pinaiLogo from "../../assets/chat-logo.png"; 
import { postRequest } from "../NetWork/NetWork";

export const MessageBubble = ({sender, logos, title, message , actionId}) => {
    const isAI = sender === "AI";
    const openAppOrFallback = (appLink, agent_name, fallbackLink)=> {
        const params = {
            agent_name: agent_name,
            action_id: actionId
        }
        postRequest("/action/agent/select", params)
        // return
        // 创建一个隐形的 iframe 来尝试拉起 App
        var iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = appLink;  // 使用深度链接尝试拉起 App
        document.body.appendChild(iframe);
    
        // 设置一个定时器，如果 App 没有被拉起，则跳转到 Web 页
        var fallbackTimer = setTimeout(function() {
            if (fallbackLink) {
                window.location.href = fallbackLink;  // 跳转到 Web 页
            }
           
        }, 1500);  // 1.5 秒未响应则认为未安装 App
    
        // 在一定时间后移除 iframe
        setTimeout(function() {
            document.body.removeChild(iframe);
            clearTimeout(fallbackTimer);  // 清理定时器，避免内存泄漏
        }, 3000);  // 等待 3 秒后移除 iframe
    }

    return (
        <div className={`flex flex-col ${isAI ? "items-start" : "items-end"} mb-2`}>
            <div className="flex">
            {isAI && (
                <div className="mr-2 flex-shrink-0">
                    <img src={pinaiLogo} alt="PIN AI Logo" className="w-10 h-10" />
                </div>
            )}
            <div
                className={`p-3 rounded-xl max-w-[80%] ${isAI ? "bg-element-lightwhite" : "ml-auto bg-primarypin-ai-light-pink"}`}
            >
                {title && (
                    <p
                        className={`[font-family:'SF_Pro_Display-Medium',Helvetica] tracking-[0] text-sm text-primarydark-blue font-medium leading-[26px] whitespace-pre-wrap`}
                    >
                        {title}
                    </p>
                )}
            </div>

            </div>
            

            {isAI && logos.length > 0 && (
                    <div className="flex flex-wrap mt-2 gap-2 ml-[50px]">
                        {logos.map((logo, index) => (
                            <div key={index} className="inline-flex items-center gap-[5px] p-[5px] bg-white rounded-xl border border-solid border-element-lightform-line"
                                onClick={()=>{
                                    openAppOrFallback(logo.link,logo.name,logo.web_link)
                                }}
                            >

                                <img
                                    className="!h-[25.49px] !ml-[-0.42px] !mb-[-0.49px] !relative !left-[unset] !w-[25.83px] !top-[unset]"
                                    src={logo.icon}
                                    alt={logo.name}
                                />
                                <div className="inline-flex flex-col items-start relative flex-[0_0_auto]">
                                    <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-element-light2nd-text text-[11px] tracking-[0] leading-[15px] whitespace-nowrap">
                                        {logo.name}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {message && (
                    <p
                        className={`ml-[50px] rounded-xl bg-white p-2 [font-family:'SF_Pro_Display-Medium',Helvetica] tracking-[0] text-sm text-primarydark-blue font-medium leading-[26px] whitespace-pre-wrap mt-2`}
                    >
                        {message}
                    </p>
                )}



        </div>
    );
};



MessageBubble.propTypes = {
    sender: PropTypes.oneOf(["User", "AI"]).isRequired,
    logos: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string.isRequired,
            alt: PropTypes.string,
            name: PropTypes.string.isRequired
        })
    ),
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
};

MessageBubble.defaultProps = {
    logos: [],
    title: "",
};
