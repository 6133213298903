import React, { useEffect, useState } from "react";
import { Button } from "../Button";
import { LoginColorfulBubble } from "../ColorfulBubble";
import logo from "../../assets/logo.png"
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import logooff from "../../assets/login-off.png"
import logoon from "../../assets/login-on.png"
import { useTranslation } from 'react-i18next';
import { MiniKit } from "@worldcoin/minikit-js";

export const Login = () => {
  const {loginWithRedirect} = useAuth0();

  const handleLogin = () => {
      if(MiniKit.isInstalled()) {
        loginWithRedirect({authorizationParams: {connection: "worldcoin"}})
      } else {
        loginWithRedirect()
      }
  }
  const localcheck = localStorage.getItem("logincheck") === 'true'; // 将 'true' 字符串转换为布尔值
  const [checked, setChecked] = useState(localcheck);

  useEffect(() => {
      localStorage.setItem("logincheck", checked);
  }, [checked]);

  const handleChange = () => {
      setChecked(!checked);
      localStorage.setItem("logincheck", checked)
  };

  const { t } = useTranslation();

  return (
    <div className="relative w-[375px] h-[812px] bg-element-lightbackground overflow-hidden">
      <div className="absolute w-[506px] h-[812px] top-[111px] left-0">
       
      <label className="absolute top-[550px] left-[30px] flex items-center space-x-2 cursor-pointer">
            <input
                type="checkbox"
                checked={checked}
                onChange={handleChange}
                className="hidden"
            />
            <div
                className={`w-6 h-6 border border-gray-300 rounded-sm flex items-center justify-center bg-white`}
            >
                {checked?(
                    <img  src={logoon} alt="logoon"/>
                ):
                ( <img  src={logooff} alt="logooff"/>)}
            </div>

            <p className="text-gray-700 w-[300px] text-[13px]">
              By using this service, you agree to our
                <Link to="/protocol?type=terms" className="text-[#E15CE6] hover:underline">
                    {" Terms of Services"}
                </Link>
                  {" and "}
                <Link to="/protocol?type=privacy" className="text-[#E15CE6] hover:underline">
                    Privacy Policy
                </Link>.
            </p>
        </label>




        <LoginColorfulBubble className="!absolute !left-[131px] !top-0 pointer-events-none" />
        <div className="absolute w-[155px] h-[173px] top-[134px] left-[111px]">
        <div className="absolute w-[100px] h-[100px] top-0 left-[27px]" style={{ backgroundImage: `url(${logo})`, backgroundSize: '100% 100%' }} />
        <p className="absolute top-[115px] left-0 [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-transparent text-2xl text-center tracking-[0] leading-[normal]">
          <span className="text-[#1c1f25]">
            {t('Welcome to Hi PIN')}
          </span>
        </p>
        </div>
        {checked?<Button
          onClick={handleLogin}
          className="!absolute !left-10 ![background:linear-gradient(180deg,rgb(112,168,235)_0%,rgb(180,149,245)_33.33%,rgb(227,86,197)_66.67%,rgb(249,173,88)_100%)] !bg-[unset] !w-[295px] !top-[500px]"
          divClassName="!text-primarydark-blue !font-medium ![font-family:'SF_Pro_Display-Medium',Helvetica]"
          state="primary"
          text={t('Get started')}
          theme="light"
        />:
        <Button
          className="!absolute !left-10  bg-gray-300 !w-[295px] !top-[500px]"
          divClassName="!text-primarydark-blue !font-medium ![font-family:'SF_Pro_Display-Medium',Helvetica]"
         
          text={t('Get started')}
         
        />
        }
        
      </div>
    </div>
  );
};
