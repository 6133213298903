import React, { useCallback, useState } from "react";
import rectangle27 from "../../assets/rectangle-27.svg";
import icarrowback from "../../assets/ic-arrow-left-mini.svg";
import icarrownext3 from "../../assets/ic-arrow-right-mini.svg";
import googlesymbol from "../../assets/google-symbol.svg";
import fbsymol from "../../assets/fb.png";
import { Check } from "../../icons/Check";
import { useNavigate } from "react-router-dom";

export const Feed = () => {
  const navigate = useNavigate();
  const googleEarned = localStorage.getItem('googleEarned') || 0;
  const facebookEarned = 0

  const goBack = (e) => {
    e.preventDefault(); // 防止默认行为
    localStorage.setItem('googleEarned', 0)
    navigate('/main');
  }

  const gotoPage = (e, type, earned) => {
    if(earned > 0) {
      return;
    }
    e.preventDefault(); // 防止默认行为
    navigate(`/feed/detail?type=${type}`);
  };

  return (
    <div className="w-[375px] h-[812px] bg-element-lightwhite rounded-[10px] overflow-hidden">
      <div className="relative w-[760px] h-[897px] top-[-85px]">
        <div className="absolute w-[623px] h-[590px] top-0 left-[137px]">
          <div className="relative h-[590px]">
            <div className="absolute w-[168px] h-[378px] top-28 left-[101px] rounded-[83.82px/188.93px] rotate-[-39.74deg] blur-[90px] [background:linear-gradient(180deg,rgb(85,166.12,241)_0%,rgb(255,255,255)_100%)]" />
            <div className="absolute w-[319px] h-[528px] top-[31px] left-40 rounded-[159.47px/264.18px] rotate-[-48.15deg] [background:linear-gradient(180deg,rgba(255,255,255,0)_0%,rgb(85,166,241)_100%)]" />
          </div>
        </div>
        <img className="absolute w-[375px] h-[768px] top-[129px] left-0" alt="Rectangle" src={rectangle27} />
        <header className="absolute w-[38px] h-[38px] top-[145px] left-5 bg-transparent">
          <div className="relative w-[38px] h-[38px] bg-element-lightwhite rounded-[10px] border border-solid border-[#c9cfda] shadow-shadow">
            <img onClick={goBack} className="absolute w-6 h-6 top-1.5 left-1.5" alt="Ic arrow back" src={icarrowback} />
          </div>
        </header>
        <div className="flex flex-wrap w-[335px] items-start gap-[15px_183px] absolute top-[234px] left-5">
          <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-xl tracking-[0] leading-[normal] whitespace-nowrap">
            Choose&nbsp;&nbsp;account:
          </div>
          <div className="flex w-[335px] items-center gap-2.5 p-4 relative bg-element-lightform-line rounded-[18px] shadow-shadow cursor-pointer">
            <div className="relative w-[38px] h-[38px] bg-secondarylight-ink-blue rounded-[100px]">
              <img className="absolute w-[22px] h-[22px] top-2 left-2 object-cover" alt="Fb" src={fbsymol} />
            </div>
            <div className="flex flex-col items-start gap-3 relative flex-1 grow">
              <div className="flex flex-col items-start gap-1.5 relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative self-stretch mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-element-light2nd-text text-sm tracking-[0] leading-[normal]">
                  Facebook
                </div>
                {facebookEarned > 0 && (
                  <div className="relative self-stretch [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-secondarymint text-xs tracking-[0] leading-[normal]">
                    Earned {facebookEarned} pts
                  </div>
                )}
              </div>
            </div>
            {facebookEarned> 0 && (
                  <Check className="!relative !w-6 !h-6" />
            )}
            {facebookEarned <= 0 && (
              <>
                <div className="relative w-[62px] h-[18px]">
                  <div className="relative w-[60px] h-[18px] rounded-lg [background:linear-gradient(180deg,rgb(112,168,235)_0%,rgb(180,149,245)_33.33%,rgb(227,86,197)_66.67%,rgb(249,173,88)_100%)]">
                    <div className="absolute w-[50px] h-[18px] top-0 left-[5px] [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-primarydark-blue text-[11px] tracking-[0] leading-[15px] whitespace-nowrap">
                      +1250 pts
                    </div>
                  </div>
                </div>
                <img className="relative w-[18px] h-[18px]" alt="Ic arrow next" src={icarrownext3} />
              </>
            )}
          </div>
          <div onClick={(e)=>{gotoPage(e,'google',googleEarned)}} className="flex w-[335px] items-center gap-2.5 p-4 relative bg-element-lightwhite rounded-[18px] shadow-shadow cursor-pointer">
            <div className="relative w-[38px] h-[38px] bg-secondarylight-lime rounded-[100px]">
              <img className="absolute w-5 h-5 top-[9px] left-[9px]" alt="Google symbol" src={googlesymbol} />
            </div>
            <div className="flex flex-col items-start gap-3 relative flex-1 grow">
              <div className="flex flex-col items-start gap-1.5 relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative self-stretch mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-sm tracking-[0] leading-[normal]">
                  Google
                </div>
                {googleEarned > 0 && (
                  <div className="relative self-stretch [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-secondarymint text-xs tracking-[0] leading-[normal]">
                    Earned {googleEarned} pts
                  </div>
                )}
              </div>
            </div>
            {googleEarned > 0 && (
                  <Check className="!relative !w-6 !h-6" />
            )}
             {googleEarned <= 0 && (
              <>
                <div className="relative w-[62px] h-[18px]">
                  <div className="relative w-[60px] h-[18px] rounded-lg [background:linear-gradient(180deg,rgb(112,168,235)_0%,rgb(180,149,245)_33.33%,rgb(227,86,197)_66.67%,rgb(249,173,88)_100%)]">
                    <div className="absolute w-[50px] h-[18px] top-0 left-[5px] [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-primarydark-blue text-[11px] tracking-[0] leading-[15px] whitespace-nowrap">
                      +1250 pts
                    </div>
                  </div>
                </div>
                <img className="relative w-[18px] h-[18px]" alt="Ic arrow next" src={icarrownext3} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
