import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Button } from "../Button";
import { Unchecked4 } from "../../icons/Unchecked4";
import frame6 from "../../assets/frame.svg";
import icarrowback from "../../assets/ic-arrow-left-mini.svg";
import rectangle27 from "../../assets/rectangle-27.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Check } from "../../icons/Check";

export const FeedDetail = () => {
  const navigate = useNavigate();
  const [emails, setEmails] = useState([]);

  const goBack = () => {
    navigate('/feed');
  }

  const fetchGmailData = async (token) => {
    try {
      const response = await axios.get('https://www.googleapis.com/gmail/v1/users/me/messages', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          maxResults: 10,
        },
      });
      const messages = response.data.messages;

      const emailPromises = messages.map((message) =>
        axios.get(`https://www.googleapis.com/gmail/v1/users/me/messages/${message.id}?format=full`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );

      const emailResults = await Promise.all(emailPromises);
      const emailsWithContent = emailResults.map((res) => {
        const message = res.data;
        return {
          id: message.id,
          snippet: message.snippet,
          checked: false,
          from: message.payload.headers.find(header => header.name === 'From').value,
          internalDate: message.internalDate
        };
      });

      setEmails(emailsWithContent);
    } catch (error) {
      console.error('Error fetching Gmail data:', error);
    }
  };

  const onItemClick = useCallback((emailId) => {
    setEmails(prevEmails => prevEmails.map(email => {
      if (email.id === emailId) {
        return { ...email, checked: !email.checked };
      }
      return email;
    }));
  }, []);

  const emailList = useMemo(() => {
    return emails.map((email, index) => (
      <div key={email.id || index} onClick={() => onItemClick(email.id)} className="flex w-full items-center gap-2.5 p-4 bg-element-lightwhite rounded-[18px] shadow-shadow">
        <div className="w-[38px] h-[38px] bg-secondarylight-ink-blue rounded-full flex items-center justify-center">
          <img className="w-5 h-5" alt="Frame" src={frame6} />
        </div>
        <div className="flex flex-col flex-1 min-w-0">
          <div className="text-primarydark-blue text-sm font-medium truncate">{email.from}</div>
          <div className="text-element-light2nd-text text-xs font-medium line-clamp-3">{email.snippet}</div>
        </div>
        <div className="w-[46px] h-[18px] bg-gradient-to-b from-blue-400 via-purple-400 to-pink-400 rounded-lg flex items-center justify-center flex-shrink-0">
          <div className="text-primarydark-blue text-[11px] font-normal">+60 pts</div>
        </div>
        {email.checked ? <Check className="w-6 h-6" /> : <Unchecked4 className="w-6 h-6" color="#ABAFB7" />}
      </div>
    ));
  }, [emails, onItemClick]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    const dataType = urlParams.get('type');
    if(dataType == null || dataType === "google") {
      const accessToken = urlParams.get('access_token');
      if (accessToken) {
         fetchGmailData(accessToken);
      } else {
        const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
        const redirectUri = window.location.origin+'/feed/detail';
        const scope = encodeURIComponent('https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/calendar.readonly');
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=${scope}&include_granted_scopes=true&response_type=token&state=state_parameter_passthrough_value&redirect_uri=${redirectUri}&client_id=${clientId}`;
        window.location.href = authUrl;
      }
    }
  },[])

  const onSubmit = () => {
      let googleEarned = 0
      emails.forEach(email => {
        if (email.checked) {
          googleEarned += 60
        }
      });

      localStorage.setItem('googleEarned',googleEarned)
      navigate('/feed')
  }

  return (
    <div className="w-[375px] h-[812px] bg-element-lightwhite rounded-[10px] overflow-hidden">
      <div className="relative w-[760px] h-[897px] top-[-85px]">
        <div className="absolute w-[623px] h-[590px] top-0 left-[137px]">
          <div className="relative h-[590px]">
            <div className="absolute w-[168px] h-[378px] top-28 left-[101px] rounded-[83.82px/188.93px] rotate-[-39.74deg] blur-[90px] [background:linear-gradient(180deg,rgb(85,166.12,241)_0%,rgb(255,255,255)_100%)]" />
            <div className="absolute w-[319px] h-[528px] top-[31px] left-40 rounded-[159.47px/264.18px] rotate-[-48.15deg] [background:linear-gradient(180deg,rgba(255,255,255,0)_0%,rgb(85,166,241)_100%)]" />
          </div>
        </div>
        <img className="absolute w-[375px] h-[768px] top-[129px] left-0" alt="Rectangle" src={rectangle27} />
        <header className="absolute w-[38px] h-[38px] top-[145px] left-5 bg-transparent">
          <div onClick={goBack} className="relative w-[38px] h-[38px] bg-element-lightwhite rounded-[10px] border border-solid border-[#c9cfda] shadow-shadow">
            <img className="absolute w-6 h-6 top-1.5 left-1.5" alt="Ic arrow back" src={icarrowback} />
          </div>
        </header>

        <div className="flex flex-wrap w-[335px] h-[600px] items-start gap-[15px_183px] absolute top-[214px] left-5 overflow-y-auto">
          <p className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-xl tracking-[0] leading-[normal] whitespace-nowrap">
            Choose up to 10 items:
          </p>
          {emailList}
        </div>
        <Button
          onClick={onSubmit}
          className="!h-[35px] !shadow-[unset] !absolute !left-[21px] !bg-primarypin-ai-light-pink !w-[334px] !top-[865px]"
          divClassName="!mt-[-6.00px] !text-primarydark-blue !mb-[-4.00px] !font-medium ![font-family:'SF_Pro_Display-Medium',Helvetica]"
          state="primary"
          text="Feed to Hi-Pin"
          theme="light"
        />
       </div>
    </div>
  );
};
