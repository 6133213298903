import { useLocation, useNavigate } from "react-router-dom";
import ic_arrow_left_mini from "../../assets/ic-arrow-left-mini.svg";
import { useEffect, useState } from "react";
import img_logo from "../../assets/navbar_logo.png";
import { getRequest } from "../NetWork/NetWork";
import { LoadingIndicator } from "../Loading/Loading";
import Voice from "../../assets/agent-voice.png";

export const RiderAgentPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const actionid = queryParams.get('action_id');

    const [loading, setLoading] = useState(false)
    const [placeList , setPlaceList] = useState([])
     const getList = async ()=>{
        // setLoading(true)
        const list = await  getRequest("/action/nearby/places")
        if (list) {
            setPlaceList(list)
        }
        setLoading(false)
     }

     const gotoChat = async (item)=> {
        const params = {
            action_id: actionid,
            lat: item.latitude,
            lng: item.longitude,
            name: item.name,
            address: item.address
            // category: 
        }
        setLoading(true)
         const data = await getRequest("/action/steps", params)
         setLoading(false)
         if (data) {
            navigate('/riderAgentChat', {
                state: { data, actionid: actionid }
            }
            )
         }
     }
    
    useEffect(()=>{
        setLoading(true)
        navigator.geolocation.getCurrentPosition(
            (position) => {
              localStorage.setItem("lat", position.coords.latitude)
              localStorage.setItem("lng", position.coords.longitude)
              getList()
            },
            (error) =>  {
                setLoading(false)
            }
          );
    }, [])

  
    return (
        <div className="w-[375px] h-screen bottom-[30px] bg-[#F8FAFC] flex flex-col items-center rounded-[10px]">
          <nav className="relative w-full">
            <button className="absolute left-[20px] top-[20px] w-[20px] h-[20px]" onClick={() => navigate(-1)}>
            <img src={ic_arrow_left_mini} alt="ic_arrow_left_mini" className="w-full h-full" />
            </button>
          </nav>
     
     <div className="flex flex-col w-[375px] gap-5 absolute bottom-[30px] top-[98px] flex-col items-center overflow-y-auto">
        <div className="flex w-[335px] gap-5  flex-col">
            <div className="relative w-[335px] h-44">
                <div className="flex flex-col w-[335px] items-center gap-3 p-5 relative rounded-[18px] border border-solid border-[#cacfdb]">
                    <img className="relative w-[100px] h-[100px]" alt="Logo" src={img_logo} />
                    <div className="w-[295px] text-center text-[#1c1f25] text-xl font-medium font-['SF Pro Display']">
                        What is your destination?</div>
                </div>
            </div>
        </div>

        {/* List */}
        <div className="flex flex-col justify-center items-center p-4  gap-[16px]">
            {placeList.map((item, index) => (
                <div className="w-full  p-4 rounded-[18px] shadow flex flex-col justify-start items-center gap-2.5 inline-flex"
                onClick={()=>{
                  gotoChat(item)
                }}
                >
                    <div className="self-stretch text-[#1c1f25] text-[13px] font-medium font-['SF Pro Display'] leading-[17px] ">
                        {item.name}                   
                    </div>
                    <div className="self-stretch text-[#1c1f25] text-[15px] font-medium font-['SF Pro Display'] leading-[17px] ">
                        {item.address}                   
                    </div>
                </div>
            ))}

            <img src={Voice} alt="Voice" className="w-[45px] h-[45px] mt-[10px]" 
                onClick={()=>{
                    navigate(`/talk`);
                }}
            />
         </div>
       </div>
       <LoadingIndicator isLoading={loading} />
      </div>
      
    );
  };