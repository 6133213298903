import React, { useState, useEffect } from "react";
import { Card } from "../../components/Card";
import { Coins4 } from "../../icons/Coins4";
import { ColorfulBubble } from "../../icons/ColorfulBubble";
import { InfoOutline } from "../../icons/InfoOutline";
import { Power2 } from "../../icons/Power2";
import img_unlocked from "../../assets/data-unlocked.png";
import img_indicator from "../../assets/data-indicator.svg";
import { getRequest, postRequest } from "../NetWork/NetWork";
import DataActivityAlert from "./DataActivityAlert";
import DataConnectAlert from "./DataConnectAlert";
import { LoadingIndicator } from "../Loading/Loading";
import DataConnectSuccessAlert from "./DataConnectSuccessAlert";
import { AuthProvider } from "../PIAuthCheckContext";
import { MiniKit } from "@worldcoin/minikit-js";
import { jwtDecode } from "jwt-decode";

const handleAuthorization = (item, setAuthoParams) => {
  localStorage.setItem("globalAuthType", item.type);
  // return
  if (
    item.type === "GoogleCalendar" ||
    item.type === "Gmail" ||
    item.type === "Google"
  ) {
    googleOAuth(item);
  } else if (item.type === "Facebook") {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          const token = response.authResponse.accessToken;
          const params = {
            connector_type: "Facebook",
            is_connect: true,
            access_token: token,
          };
          setAuthoParams(params);
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope: "user_posts, user_friends",
      }
    );
  }
};

function googleOAuth(item) {
  const type = item.type;
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  var redirectUri = window.location.origin + "/main/data";
  if (MiniKit.isInstalled()) {
    redirectUri = window.location.origin + "/connect/callback";
  }

  var scopeStr =
    "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.birthday.read";

  if (type === "Google") {
  } else if (type === "Gmail") {
    scopeStr = "https://www.googleapis.com/auth/gmail.readonly";
  } else if (type === "GoogleCalendar") {
    scopeStr = "https://www.googleapis.com/auth/calendar.readonly";
  }
  const scope = encodeURIComponent(scopeStr);
  var authUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=${scope}&include_granted_scopes=true&response_type=code&access_type=offline&redirect_uri=${redirectUri}&client_id=${clientId}&prompt=consent`;
  if (MiniKit.isInstalled()) {
    const token = localStorage.getItem("authToken");
    const decodedToken = jwtDecode(token);

    const stateObj = JSON.stringify({
      ...item,
      redirect_uri: redirectUri,
      sub: decodedToken.sub,
    });

    const encodedState = encodeURIComponent(stateObj);
    authUrl = authUrl + `&state=${encodedState}`;
  }
  window.location.href = authUrl;
}

const switchConnector = async (params) => {
  try {
    const result = await postRequest("/connector/switch", params);
    if (result) {
      return result;
    }
  } catch (error) {
    console.error("connector/switch Request failed:", error);
  }
};

export const Data = () => {
  const [points, setPoints] = useState({
    pinPoints: 0,
    pinScore: 0,
    dataScore: 0,
    actionScore: 0,
  });
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [authoParams, setAuthoParams] = useState(null);
  useEffect(() => {
    if (authoParams == null) return;
    const type = authoParams.connector_type;
    switchConnector(authoParams).then((result) => {
      if (result) {
        showConnectSucc(result.reward_points);
        fetchData();
      }
    });
  }, [authoParams]);

  useEffect(() => {
    if (loading) return;

    const queryParams = new URLSearchParams(window.location.search);
    // 提取出 `code` 参数
    const code = queryParams.get("code");
    if (code) {
      //google auth成功
      const authType = localStorage.getItem("globalAuthType");
      const redirectUri = window.location.origin + "/main/data";
      const params = {
        connector_type: authType,
        is_connect: true,
        auth_code: code,
        redirect_uri: redirectUri,
      };
      setAuthoParams(params);

      // 移除 URL 中的 access_token
      const newUrl = window.location.origin + window.location.pathname;
      //newUrl : http://localhost:3000/main/data
      window.history.replaceState(null, "", newUrl);
    }
  }, [points, tabs, loading]);

  function createTabs(data) {
    const tabInfo = [
      { name: "Hot", categoryId: 0 },
      { name: "Utility", categoryId: 1 },
      { name: "Travel", categoryId: 2 },
      { name: "Social", categoryId: 3 },
      { name: "Shopping", categoryId: 4 },
      { name: "Delivery", categoryId: 5 },
      { name: "Entertainment", categoryId: 6 },
      { name: "Finance", categoryId: 7 },
      { name: "Lifestyle", categoryId: 8 },
    ];

    // 使用 map 函数遍历 tabInfo 数组并生成结果
    return tabInfo.map((tab) => ({
      name: tab.name,
      categoryId: tab.categoryId,
      items: data[tab.name], // 动态获取对应的数据
    }));
  }

  const fetchData = async () => {
    try {
      setShowLoad(true);
      const data = await getRequest("/data/connectors");
      setShowLoad(false);
      const tabs = createTabs(data.categories);
      const points = {
        pinPoints: data.pin_points,
        dataScore: data.data_power,
      };
      setPoints(points);
      setTabs(tabs);
      // setActiveTab(0);
      setLoading(false);
    } catch (err) {
      console.error("data/connectors reequst fail:", err);
    }
  };

  useEffect(() => {
    fetchData();
    // Load the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v10.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const handleTabChange = (index) => {
    setActiveTab(index);
    localStorage.setItem("activeTab", JSON.stringify(index));
  };

  const [showLoad, setShowLoad] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  //activity
  const [isACAlert, setIsACAlert] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  //connect
  const [isConnectAlert, setIsConnectAlert] = useState(false);
  const connectChannel = (item) => {
    // setIsLoading(true)
    handleAuthorization(item, setAuthoParams);
    localStorage.setItem("authoSuccessItem", JSON.stringify(item));
  };

  const [rewardPoint, setRewardPoint] = useState(0);
  const showConnectSucc = (rewardPoint) => {
    const item = JSON.parse(localStorage.getItem("authoSuccessItem"));
    if (item) {
      setSelectedItem(item);
      setIsConnectSuc(true);
      setRewardPoint(rewardPoint);
      localStorage.removeItem("authoSuccessItem");
    }
  };

  //connect auth success
  const [isConnectSuc, setIsConnectSuc] = useState(false);

  const openModal = (item) => {
    setSelectedItem(item);
    if (item.unlock == 1 || item.available == 0) {
      setIsACAlert(true);
    } else if (item.unlock == 0 && item.available == 1) {
      setIsConnectAlert(true);
    } else {
      setIsModalOpen(true);
    }
  };
  const closeModal = (item) => {
    if (item.unlock == 1 || item.available == 0) {
      setIsACAlert(false);
    } else if (item.unlock == 0 && item.available == 1) {
      setIsConnectAlert(false);
    } else {
      setIsModalOpen(false);
    }
  };
  return (
    <AuthProvider
      children={
        <div className="w-screen h-screen bg-element-lightwhite">
          <div className="relative h-screen w-full">
            <ColorfulBubble className="!absolute !w-screen !top-0 " />
            <div className="absolute  w-full top-[148px] bottom-[80px]  rounded-tl-[20px] rounded-tr-[20px] bg-[#F8FAFC]" />
            
            
            <div className="absolute flex w-screen top-[80px] items-center justify-center ">
              <div className="flex flex-col ml-[20px] w-[188px]  gap-1  top-[66px]">
                <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-element-light2nd-text text-sm tracking-[0] leading-[normal]">
                  PIN Points
                </div>
                <div className="inline-flex items-center gap-1 relative flex-[0_0_auto]">
                  <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-[28px] tracking-[0] leading-[normal] whitespace-nowrap">
                    {points.pinPoints}
                  </div>
                  <Coins4
                    className="!relative !w-[18px] !h-[18px]"
                    color="#676C77"
                  />
                </div>
              </div>
              <div className="flex flex-col w-[111px] items-start gap-1  top-[66px]">
                <div className="inline-flex items-center justify-center gap-1 relative flex-[0_0_auto]">
                  <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-element-light2nd-text text-sm tracking-[0] leading-[normal]">
                    Data Power
                  </div>
                  <InfoOutline
                    className="!relative !w-[15px] !h-[15px]"
                    color="#626262"
                  />
                </div>
                <div className="inline-flex items-center gap-1 relative flex-[0_0_auto]">
                  <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-[28px] tracking-[0] leading-[normal] whitespace-nowrap">
                    {points.dataScore}
                  </div>
                  <Power2 className="!relative !w-[18px] !h-[18px]" />
                </div>
              </div>
            </div>
            

            <div className="absolute flex  h-[38px] top-[167px] px-[16px] w-screen border-b [border-bottom-style:solid] border-[#cacfdb] overflow-x-auto whitespace-nowrap scrollbar-hide">
              <div className="inline-flex h-full items-start gap-[26px] relative ">
                {tabs.map((tab, index) => (
                  <div
                    key={index}
                    className={`relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-base tracking-[0] leading-[normal] whitespace-nowrap cursor-pointer ${
                      activeTab === index
                        ? "text-primarypin-ai-pink"
                        : "text-element-light2nd-text"
                    }`}
                    onClick={() => handleTabChange(index)}
                  >
                    {tab.name}
                    {activeTab == index && (
                      <img
                        className="absolute w-[51px] h-px top-6 left-0"
                        alt="Line"
                        src={img_indicator}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div
              className="flex flex-wrap justify-center items-center gap-[17px_14px]  top-[228px] overflow-y-auto"
              style={{ position: "absolute", bottom: "100px", top: "228px" }}
            >
              {tabs[activeTab] &&
                tabs[activeTab].items.map((item) => (
                  <div
                    key={item.id}
                    className="relative"
                    onClick={() => {
                      if (item.unlock === 1) {
                        openModal(item);
                      }
                    }}
                  >
                    <Card
                      earnedClassName={item.available === 0 ? "locked" : ""}
                      name={item.name}
                      power_growth={item.power_growth}
                      level={"Lvl " + item.level}
                      cost={item.cost}
                      override={
                        <img
                          className="!h-[42px] !w-[42px] rounded-md"
                          src={item.logo}
                          alt={item.text}
                        />
                      }
                      item={item}
                    />
                    {item.available == 0 ? (
                      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-end justify-center rounded-[18px] p-2">
                        <button
                          className="all-[unset] box-border h-[30px] flex items-center justify-center gap-2.5 px-6 py-3 bg-[#e5e7ec] rounded-xl overflow-hidden shadow-[0px_2px_3px_#cbcccf26] mb-1"
                          onClick={() => {
                            openModal(item);
                          }}
                        >
                          <div className="text-[#e15ce6] text-center text-sm font-normal font-['SF Pro Display'] whitespace-nowrap">
                            Coming soon
                          </div>
                        </button>
                      </div>
                    ) : item.unlock == 0 ? (
                      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-end justify-center rounded-[18px] p-2">
                        <button
                          className="all-[unset] box-border h-[30px] flex items-center justify-center gap-2.5 px-6 py-3 bg-primarypin-ai-pink rounded-xl overflow-hidden shadow-[0px_2px_3px_#cbcccf26] mb-1"
                          onClick={() => {
                            openModal(item);
                          }}
                        >
                          <div className="relative w-[19px] h-[19px]">
                            <img
                              className="absolute w-[17px] h-[17px] top-px left-px object-cover"
                              alt="Unlocked"
                              src={img_unlocked}
                            />
                          </div>
                          <span className="relative w-fit [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-element-lightwhite text-sm tracking-[0] leading-[normal]">
                            Connect
                          </span>
                        </button>
                      </div>
                    ) : null}
                  </div>
                ))}
            </div>
          </div>

          {/* <UpgradeAlert isOpen={isModalOpen} onClose={closeModal} item={selectedItem}>
      </UpgradeAlert> */}

          <DataActivityAlert
            isOpen={isACAlert}
            onClose={closeModal}
            item={selectedItem}
          />

          <DataConnectAlert
            isOpen={isConnectAlert}
            onClose={closeModal}
            item={selectedItem}
            connect={connectChannel}
          />

          <DataConnectSuccessAlert
            point={rewardPoint}
            isOpen={isConnectSuc}
            onClose={() => {
              setIsConnectSuc(false);
            }}
            item={selectedItem}
          />

          <LoadingIndicator isLoading={showLoad} />
        </div>
      }
    />
  );
};
