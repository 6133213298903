/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const DataConnector = ({ className, strokeColor = 'white' }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="25"
      viewBox="0 0 25 25"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1187_10783)">
        <path
          d="M12.8936 20.5591H24.0364"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M20.8525 17.3755L24.0363 20.5592L20.8525 23.7428"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M8.91534 6.23303C10.2342 6.23303 11.3033 5.16389 11.3033 3.84503C11.3033 2.52618 10.2342 1.45703 8.91534 1.45703C7.59649 1.45703 6.52734 2.52618 6.52734 3.84503C6.52734 5.16389 7.59649 6.23303 8.91534 6.23303Z"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M15.2816 14.1925C16.6004 14.1925 17.6696 13.1234 17.6696 11.8045C17.6696 10.4856 16.6004 9.4165 15.2816 9.4165C13.9627 9.4165 12.8936 10.4856 12.8936 11.8045C12.8936 13.1234 13.9627 14.1925 15.2816 14.1925Z"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M10.5062 22.9474C11.825 22.9474 12.8942 21.8782 12.8942 20.5594C12.8942 19.2405 11.825 18.1714 10.5062 18.1714C9.18731 18.1714 8.11816 19.2405 8.11816 20.5594C8.11816 21.8782 9.18731 22.9474 10.5062 22.9474Z"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M12.8939 11.8047H6.12854C3.71089 11.8047 1.75098 13.7646 1.75098 16.1823C1.75098 18.5999 3.71089 20.5598 6.12854 20.5598H8.11835"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M17.6691 11.8044H20.0569C22.2548 11.8044 24.0365 10.0227 24.0365 7.82481C24.0365 5.62694 22.2548 3.84521 20.0569 3.84521H11.3018"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M6.52651 3.84521H1.75098"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1187_10783">
          <rect fill="white" height="24" transform="translate(0.893555 0.600098)" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
