import React, { useEffect, useState } from 'react';
import { SpinLoading } from 'antd-mobile';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Route,Routes } from 'react-router-dom';
import "./OAuthContent.css"
import axios from 'axios';
import { Login } from './Login/Login';
import { Navbar} from '../components/Navbar';
import { Home } from './Home';
import { Data } from './Data';
import { Account } from './Account';
import { Talk } from './Talk/Talk';
import { Tasks } from './Tasks';
import { Action } from './Action/Action';
import { FriendSummary } from './Summary/FriendSummary';
import { Invite } from './Invite/Invite';
import { postRequest } from './NetWork/NetWork';
import { Protocol } from './Protocol/Protocol';
import { ModelPage } from './Home/ModelPage';
import { ActionFaceSelectPage } from './Action/ActionFaceSelectPage';
import { RiderAgentPage } from './Action/RiderAgentPage';
import { RiderAgentChat } from './Action/RiderAgentChat';
import { FoodAgentPage } from './Action/FoodAgentPage';
import { ShoppingAgentPage } from './Action/ShoppingAgentPage';
import { LoadingIndicator } from './Loading/Loading';

const MainLayout = () => {
    const { isLoading, isAuthenticated, error,getIdTokenClaims} = useAuth0();
    const login = localStorage.getItem('is_login_pinai')==='1'?true: false
    const [showLoad, setShowLoad] = useState(false)
    const [isLogin, setIsLogin] = useState(login)
    // localStorage.setItem('is_login_pinai','0')
    const loginToPinAI = async (callback) => {
      const logined = localStorage.getItem('is_login_pinai');
      if(logined !== '1') {
        const token = await getIdTokenClaims();
        localStorage.setItem("authToken", token.__raw);
        const params = {}
        const loginCode = localStorage.getItem("loginCode")
        if (loginCode) {
          params.invite_code = loginCode
        }
        setShowLoad(true)
        const response = await postRequest("/users/login", params)
        if(response) {
           localStorage.setItem('is_login_pinai','1')
           localStorage.setItem('invite_code', response.invite_code)
          setIsLogin(true)
          console.log("login success")
        }
        setShowLoad(false)

        if(callback)  {
          callback(response)
        }  
      }
    }

    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    const accessToken = urlParams.get('access_token');
    var activeKey = 'facebook';
    if (accessToken) {
       activeKey = 'google';
    }

    useEffect(()=>{
      if(!isLoading) {
        if(isAuthenticated) {
          loginToPinAI()
        } else {
          localStorage.setItem('is_login_pinai','0')
        }
      }
    }, [isAuthenticated, isLoading, isLogin]);

    const [selectedTab, setSelectedTab] = useState(activeKey);
  
    const handleTabChange = (key) => {
      setSelectedTab(key);
    };
  
    // 处理加载状态
    if (isLoading) {
      return (
        <SpinLoading style={{ '--size': '32px' }} />
      );
    }
  
    // 处理错误状态
    if (error) {
        return (
            <div>Oops... {error.message}</div>
        );
    }
    console.log("isAuthenticated , login", isAuthenticated, isLogin)
    // 处理认证状态
    if (!isLogin) {
      // 未认证状态下重定向到登录
      return (
        <div>
          <Login />
          <LoadingIndicator isLoading={showLoad} />  
      </ div>
      )

    }  
    else {
      return (
        <div className="flex flex-col min-h-screen justify-between">
          <div className="flex-grow">
            <Routes>
              <Route exact path="" element={<Navigate to="home" />}/>
              <Route path="home" element={<Home />} />
              <Route path="data" element={<Data />} />
              <Route path="task" element={<Tasks />} />
              <Route path="action" element={<Action />} />
              <Route path="profile" element={<Account />} />
              <Route path="friendSummary" element={<FriendSummary />} />
              <Route path='invite' element={<Invite />} />
              <Route path="protocol" element={<Protocol />} />
              <Route path="model" element={<ModelPage />} />
              <Route path="faceselect" element={<ActionFaceSelectPage />} />
              <Route path="riderAgentPage" element={<RiderAgentPage />} />
              <Route path="riderAgentChat" element={<RiderAgentChat />} />
              <Route path="foodAgentPage" element={<FoodAgentPage />} />
              <Route path="shoppingAgentPage" element={<ShoppingAgentPage />} />
            </Routes>
          </div>
          <Navbar className="custom-navbar" />
        </div>
      )
    }
  
    // return (
    //   <div className="App">
    //     <Tabs
    //     activeKey={selectedTab}
    //     onChange={handleTabChange}
    //     >
    //       <Tab destroyOnClose={false} key="facebook" title="Facebook">
    //           <FaceBookAnalysis />
    //       </Tab>
    //       <Tab destroyOnClose={false} key="google" title="Google">
    //           <GoogleAnalysis />
    //       </Tab>
    //     </Tabs>
    //   </div>
    // );
  };
  
  export default MainLayout;