import React, { useState } from 'react';
import twitter from "../../assets/data-twitter.png";
import { Navbar } from "../Navbar";

const ActionXlert = ({ isOpen, discover, onClose}) => {
  const defaultStr = "https://x.com/"
  const [inputValue, setInputValue] = useState('');
  const [isEditing, setIsEditing] = useState(false)
  const [height, setHeight] = useState("452px")


  if (!isOpen) return null;
  
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleBlur = () => {
    setTimeout(() => {
        setIsEditing(false);
        setHeight("452px");
      }, 200); 
  };

  const handleClick = () => {
    setIsEditing(true);
    setHeight("700px")
  };

  const finishAction = ()=> {
    const baseURL = "https://x.com/";
    const result =  inputValue.includes(baseURL) ? inputValue.replace(baseURL, '') : inputValue;
    discover(result)
  }



  return (
    <div className="flex flex-col min-h-screen justify-center">
      <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 flex items-center justify-center">
        <div
          className="absolute inset-0 bg-black bg-opacity-50"
          onClick={()=>{
            onClose()
          }}
        ></div>
        
        {/* Modal */}
        <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-4 bg-white rounded-tl-[40px] rounded-tr-[40px] shadow-lg" style={{ width: '375px', height: height }}>
         
          {/* Modal Body */}
          <div className="pt-[84px] flex items-center justify-center">
            <div className="w-[263.89px] h-[322px] flex-col justify-start items-center inline-flex">
              <div className="w-[60px] h-[60px] relative">
              <img className="w-[60px] h-[60px] left-0 top-0 absolute rounded-xl" src={twitter} />
              </div>
              <div className="absolute top-[169px] w-[306px] text-center text-black text-base font-normal font-['SF Pro Display'] leading-[21px]">
                Enter the profile link of the friend whose twitter stories you wish to view.</div>

            <div className="absolute  left-[20px] top-[236px] w-[330px] h-[22px] text-black text-base font-medium font-['SF Pro Display']">User Profile Link</div>
            
        <div className='absolute top-[268px] left-[20px]'>

        <div
            className="relative top-[0px] w-[330px] self-stretch h-[45px] px-[15px] py-3 bg-white rounded-xl border border-solid border-[#676c77] justify-start items-center gap-1.5 inline-flex"
            onClick={handleClick}
    >
            {isEditing ? (
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                autoFocus
                className="w-full border-none outline-none"
            />
            ) : (
                inputValue.length > 0?(
                    <div className="text-black text-base font-medium font-['SF Pro Display'] leading-[20px]">
                        {inputValue}
                    </div>
                ):(
                    <div className="text-[#abafb7] text-base font-medium font-['SF Pro Display'] leading-[20px]">
                        {defaultStr}
                    </div>
                )
            )}
        </div>

            {/* button */}
            <div className="relative mt-[25px] w-[330px] h-[50px]  py-3.5  bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex" onClick={()=>{
                    finishAction()
                }}>
                <div className="text-white text-base font-medium font-['SF Pro Display']">Discover!</div>
            </div>


        </div>
           

            </div>
          </div>

        </div>
      </div>
      </div>

      <Navbar className="custom-navbar z-10" />

    </div>
  );
};

export default ActionXlert;
