import { useLocation, useNavigate } from "react-router-dom";
import ic_arrow_left_mini from "../../assets/ic-arrow-left-mini.svg";
import { useEffect, useState } from "react";
import { getRequest } from "../NetWork/NetWork";
import { LoadingIndicator } from "../Loading/Loading";
import facebook from "../../assets/facebook.png";
import { List } from "antd-mobile";
import { toast } from "react-toastify";

export const ActionFaceSelectPage = () => {
    const navigate = useNavigate();

    const defaultStr = "DonaldTrump"

    const [inputValue, setInputValue] = useState('');
    const [isEditing, setIsEditing] = useState(false)
    const [resultList, setResultList] = useState([])
    const [item, setItem] = useState(null)
    const [facebooklist, setFacebooklist] = useState([])
    const[showLoad, setShowLoad] = useState(false)
    const [btnAble, setBtnAble] = useState(false)
//    const testlist = [
//     {"name": "Ligen Peng", "id": "7748249551935469"},
//     {"name": "Penguin Ligen", "id": "1122334455667788"},
//   ]

const finishAction = ()=> {
    navigate(`/friendSummary?type=2006&name=${item.id}`, { replace: true })
}


const featchFaceBook = async ()=> {
    setShowLoad(true)
    const data = await getRequest("/action/friends/list/facebook")
    if (data) {
      setFacebooklist(data)
    }
    setShowLoad(false)
  }

  useEffect(()=>{
    featchFaceBook()
  }, [])

  const handleInputChange = (e) => {
    const value = e.target.value
    setInputValue(value);
    getMatchingList(value)
     if (value.length> 0) {
            setBtnAble(true)
        } else {
            setBtnAble(false)
        }
  };

  const handleBlur = () => {
    setTimeout(() => {
        setIsEditing(false);
       
        // if (inputValue > 0) {
        //     setBtnAble(true)
        // } else {
        //     setBtnAble(false)
        // }
      }, 200); 
  };

  const handleClick = () => {
    setIsEditing(true);
  };


  function getMatchingList(input) {
    if (input.length > 0){
        // 转换输入为小写以进行不区分大小写的匹配
        const lowerCaseInput = input.toLowerCase();
        // 过滤列表，匹配 name 中包含输入的项
        const temp = facebooklist.filter(item => 
        item.name.toLowerCase().includes(lowerCaseInput)
        );
        setResultList(temp)
    } else {
        setResultList([])
    }

  }
   
    return (
        <div className="w-[375px] h-screen bg-[#F8FAFC] flex flex-col">
          <nav className="relative">
            <button className="w-[20px] h-[20px] m-4" onClick={() => navigate(-1)}>
            <img src={ic_arrow_left_mini} alt="ic_arrow_left_mini" className="w-full h-full" />
            </button>
          </nav>

          

          <div className="pt-[5px] flex items-center justify-center">
            <div className="w-[263.89px] h-[322px] flex-col justify-start items-center inline-flex">
              <div className="w-[60px] h-[60px] relative">
              <img className="w-[60px] h-[60px] left-0 top-0 absolute rounded-xl" src={facebook} />
              </div>
              <div className="ml-[10px] mt-[10px] w-[306px] text-center text-[13px] text-black text-base font-normal font-['SF Pro Display'] leading-[21px]">
              Enter and choose the user name of the friend</div>

            <div className="ml-[10px] mt-[10px] w-[330px] h-[22px] text-black text-[18px] font-medium font-['SF Pro Display']">User Name</div>
            

        <div
            className="mt-[10px] w-[330px] h-[45px] px-[15px] py-3 bg-white rounded-xl border border-solid border-[#676c77] justify-start items-center gap-1.5 inline-flex"

            onClick={handleClick}
    >
            {isEditing ? (
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                autoFocus
                className="w-full border-none outline-none"
                style={{ fontSize: '16px' }}
            />
            ) : (
                inputValue.length > 0?(
                    <div className="text-black text-[16px] font-medium font-['SF Pro Display'] leading-[20px]">
                        {inputValue}
                    </div>
                ):(
                    <div className="text-[#abafb7] text-[16px]  font-medium font-['SF Pro Display'] leading-[20px]">
                        {defaultStr}
                    </div>
                )
            )}
        </div>


        {/* List */}
        {resultList.length > 0 && (
        <div className="absolute top-[270px] w-[330px] h-[280px] bg-white-100 flex flex-col gap-[20px] overflow-y-auto rounded-xl py-[10px]">
        {resultList.map((item, index) => (
            <div className="h-[25px] mx-[10px]">
            <div key={index} className=" mt-[2px] text-[13px] h-[20px]"
            onClick={() => {
                setInputValue(item.name);
                setItem(item)
                setIsEditing(false);
            }}
            >
            {item.name}
            </div>
            <div className="mt-[5px] h-[1px] bg-gray-300" />
            </div>
        ))}
         </div>
        )}


         {/* button */}
         {btnAble?  <div className="absolute bottom-[45px] w-[330px] h-[50px]  py-3.5  bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex" onClick={()=>{
                    finishAction()
                }}>
                <div className="text-white text-base font-medium font-['SF Pro Display']">Discover!</div>
            </div>:
            
            <div className="absolute bottom-[45px] w-[330px] h-[50px]  py-3.5  bg-gray-300 rounded-xl shadow justify-center items-center gap-2.5 inline-flex">
                <div className="text-white text-base font-medium font-['SF Pro Display']">Discover!</div>
             </div>
            }
        
           
           
            </div>
          </div>


        <LoadingIndicator isLoading={showLoad} />
          
      </div>
      
    );
  };