import React, { useEffect, useState } from "react";
import { ArrowNext1 } from "../../icons/ArrowNext1";
import { Check } from "../../icons/Check";
import { ColorfulBubble } from "../../icons/ColorfulBubble";
import img_rectangle from "../../assets/action-rectangle-task.svg";
import img_planner from "../../assets/action-planner-1-1.png";
import img_profit from "../../assets/profit-1.png";
import img_twitter from "../../assets/action-twitter-2.png";
import img_youtube from "../../assets/action-youtube-2-2.png";
import img_telegram from "../../assets/action-telegram-2-1.png";
import img_instagram from "../../assets/action-instagram-2-1.png";
import img_arrow_right from "../../assets/ic-arrow-right-mini.svg";
import { getRequest, postRequest } from "../NetWork/NetWork";
import TaskFinishAlert from "./TaskFinishAlert";
import TaskDaliyAlert from "./TaskDailyAlert";
import { json } from "react-router-dom";
import { LoadingIndicator } from "../Loading/Loading";
import { useNavigate } from "react-router-dom";
import arrow_back from "../../assets/arrow-back.png";
import { AuthProvider } from "../PIAuthCheckContext";


export const Tasks = () => {

  const [points, setPoints] = useState({
    pinPoints: 0,
  });
  const [tasks, setTasks] = useState([]);

  const navigate = useNavigate()

  const featchData = async ()=>{
    const data =  await getRequest("/task/list");
      if (data) {
        setPoints((pre)=> ({
          ...pre,
          pinPoints: data.pin_points
      }));
      setTasks(data.tasks)
      const taskStr = localStorage.getItem('isTaskCompleted');
      if (taskStr) {
        const task = JSON.parse(taskStr)
        openModal(task)
        localStorage.removeItem('isTaskCompleted');
      }
    }
  }

  const featchFinish = async (task) => {
    const re =  await postRequest(`/task/${task.task_id}/complete`)
    featchData()
  }

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isDaliyOpenModal, setIsDaliyOpenModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const openModal = (item) => {
    setSelectedItem(item)
    if (item.task_id == 1001) {
      setIsDaliyOpenModal(true)
    } else {
      setIsOpenModal(true)
    }
  }
  const closeModal = (task) => {
    featchFinish(task)
    if (task.task_id == 1001) {
      setIsDaliyOpenModal(false)
    } else {
      setIsOpenModal(false)
    }
    
  }
  
  useEffect(()=>
  {
    featchData();
  },[]);

  const handleTaskClick = (task) => {
    // if (task.is_complete) return
    // Daily checkin
    if(task.task_id === 1001) {
      openModal(task)
      return
    } 
    // setIsLoading(true);
    if (task.is_complete) return
    localStorage.setItem('isTaskCompleted', JSON.stringify(task));
    window.location.href = `${task.redirect}`;
  };

  return (
   <AuthProvider children={
    <div className="relative w-[375px] h-[812px] bg-element-lightwhite overflow-hidden">
    <nav className="relative">
      <div className="w-[335px] h-[30px] flex-col mt-[50px] justify-start items-center inline-flex">
        <button className="w-[30px] h-[30px] absolute left-[11px]" onClick={() => navigate(-1)}>
                   <img src={arrow_back} alt="arrow_back"/>
            </button>
      </div>
    </nav>
    {/* bg-[#F8FAFC] */}
    <div className="relative">
      <ColorfulBubble className="!absolute !w-[375px] !h-[812px] !top-0 !left-0" />
      <div className="w-[188px] left-[21px] flex flex-col items-start gap-1 absolute top-[6px]">
        <div className="relative w-fit mt-[-1px] [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-element-light2nd-text text-sm tracking-[0] leading-[normal]">
          PIN Points
        </div>
        <div className="inline-flex items-center gap-1 relative flex-[0_0_auto]">
          <div className="w-fit mt-[-1.00px] text-primarydark-blue text-[28px] whitespace-nowrap relative [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium tracking-[0] leading-[normal]">
            {points.pinPoints}
          </div>
          <img className="relative w-5 h-5 object-cover" alt="Profit" src={img_profit} />
        </div>
      </div>
      <div className="flex flex-wrap w-[335px] items-start gap-[15px_183px] absolute top-[113px] left-5">
        <div className="flex w-[335px] items-start justify-between relative">
          <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-xl tracking-[0] leading-[normal] whitespace-nowrap">
            Tasks
          </div>
          <div className="inline-flex items-center gap-1.5 relative flex-[0_0_auto]">
            {/* <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-element-light2nd-text text-sm text-right tracking-[0] leading-[normal]">
              See All
            </div>
            <img className="relative w-3 h-3" alt="Ic arrow left mini" src={img_arrow_right} /> */}
          </div>
        </div>
        {tasks.map((task) => (
          <div
            key={task.task_id}
            className="flex w-[335px] items-center gap-2.5 p-4 relative bg-element-lightwhite rounded-[18px] shadow-shadow"
            onClick={() => handleTaskClick(task)}
          >
            <div
              className="relative w-[45px] h-[45px] bg-cover bg-[50%_50%]"
              style={{ backgroundImage: `url(${task.logo})` }}
            />
            <div className="flex flex-col items-start gap-3 relative flex-1 grow">
              <div className="flex flex-col items-start gap-1.5 relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative self-stretch mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-sm tracking-[0] leading-[normal]">
                  {task.task_name}
                </div>
                <div className="self-stretch text-primarypin-ai-pink text-xs relative [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium tracking-[0] leading-[normal]">
                  +{task.reward_points}
                </div>
              </div>
            </div>
            {task.is_complete ? (
              <Check className="!relative !w-[25px] !h-[25px]" color="#43C6A7" />
            ) : (
              <ArrowNext1 className="!relative !w-[25px] !h-[25px]" />
            )}
          </div>
        ))}
      </div>
    </div>
      
      <TaskFinishAlert isOpen={isOpenModal} onClose={closeModal} item={selectedItem}>
        {/* You can add more content here as needed */}
      </TaskFinishAlert>

      <TaskDaliyAlert isOpen={isDaliyOpenModal} onClose={closeModal} item={selectedItem}>
        {/* You can add more content here as needed */}
      </TaskDaliyAlert>
      <LoadingIndicator isLoading={isLoading} />

  </div>
   } />
  );
};
