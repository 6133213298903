/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Coins19 } from "../../icons/Coins19";
import { Power7 } from "../../icons/Power7";
import { Google } from "../Google";

// {
//   "type": "LinkedIn",
//   "name": "LinkedIn",
//   "tier": 3,
//   "logo": "https://pinai-public.s3.amazonaws.com/logo_linkedin.png",
//   "available": 0,
//   "unlock": 0,
//   "level": 0,
//   "cost": 30000,
//   "power_growth": 2700
// }

export const Card = ({
  name = "Gmail",
  earnedClassName,
  power_growth = "&nbsp;&nbsp;+0",
  earnedClassNameOverride,
  override = <Google className={`!h-[42px] !w-[42px]`} />,
  level = "Lvl 0",
  cost = "0",
  item
}) => {
  return (
    <div className="flex w-[161px] h-[149px] items-start gap-2.5 p-4 relative bg-element-lightwhite rounded-[18px] shadow-shadow">
      <div className="inline-flex flex-col items-center gap-2 relative flex-[0_0_auto] mr-[-1.00px]">
        <div className="flex w-[42px] h-[42px] items-center relative rounded-lg">{override}</div>
        <div className="relative w-fit [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-base tracking-[0] leading-[normal] whitespace-nowrap">
          {name}
        </div>
        <div className="flex w-[130px] items-center justify-between relative flex-[0_0_auto]">
          <div className="relative w-20 [font-family:'SF_Pro_Display-Regular',Helvetica] font-normal text-primarydark-blue text-xs tracking-[0] leading-[15px]">
          Data Power: 
          </div>
          <div
            className={`px-[5px] py-[3px] ml-[-9px] inline-flex h-[18px] items-center justify-end gap-[5px] relative flex-[0_0_auto] rounded-lg ${earnedClassName}`}
          >
            <div className="mt-[-2.50px] mb-[-0.50px] relative w-fit [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-xs text-right tracking-[0] leading-[15px] whitespace-nowrap">
              +{power_growth}
            </div>
            <Power7 className="!relative !w-3 !h-3" />
          </div>
        </div>
        {item.unlock==1 && 
          <div className="mb-[16px] text-[#e15ce6] text-xs font-medium font-['SF Pro Display']">
            Active</div>}
      </div>
    </div>
  );
};

Card.propTypes = {
  name: PropTypes.string,
  power_growth: PropTypes.number,
  level: PropTypes.string,
  cost: PropTypes.number,
};
