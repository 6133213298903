import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SplashPage from "./pages/SplashPage";
import MainPage from "./pages/MainPage";
import { Home } from "./components/Home";
import { Talk } from "./components/Talk/Talk";
import { Feed } from "./components/Feed";
import { FeedDetail } from "./components/FeedDetail/FeedDetail";
import { FriendSummary } from "./components/Summary";
import { Invite } from "./components/Invite/Invite";
import { Tasks } from "./components/Tasks";
import { Protocol } from "./components/Protocol/Protocol";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ModelPage } from "./components/Home/ModelPage";
import { ActionFaceSelectPage } from "./components/Action/ActionFaceSelectPage";
import { useTranslation } from "react-i18next";
import { RiderAgentPage } from "./components/Action/RiderAgentPage";
import { RiderAgentChat } from "./components/Action/RiderAgentChat";
import { FoodAgentPage } from "./components/Action/FoodAgentPage";
import { ShoppingAgentPage } from "./components/Action/ShoppingAgentPage";
import MiniKitProvider from "./MiniKitProvider.tsx";
import { ConnectCallback } from "./components/Data/ConnectCallback.jsx";
import { Auth0Provider } from "@auth0/auth0-react";

const App = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  localStorage.setItem("language", language);

  const queryParams = new URLSearchParams(window.location.search);
  // 提取出 `code` 参数
  const code = queryParams.get("invite_code");
  if (code) {
    localStorage.setItem("loginCode", code);
  }
  // http://localhost:3000?invite_code=bMZt3u

  return (
    <MiniKitProvider>
      <Router>
        <ToastCustom />
        <Routes>
          <Route path="/connect/callback" element={<ConnectCallback />} />

          <Route
            path="/*"
            element={
              <Auth0Provider
                domain="pinai.us.auth0.com"
                clientId="nfIPssiugHY7hnHsHF7EqWQlpMtpfxg8"
                authorizationParams={{
                  redirect_uri: window.location.origin + "/main",
                }}
              >
                <ProtectedRoutes />
              </Auth0Provider>
            }
          />
        </Routes>
      </Router>
    </MiniKitProvider>
  );
};

const ProtectedRoutes = () => (
  <Routes>
    <Route exact path="/" element={<SplashPage />} />
    <Route exact path="/main/*" element={<MainPage />} />
    <Route exact path="/home" element={<Home />} />
    <Route exact path="/talk" element={<Talk />} />
    <Route exact path="/feed" element={<Feed />} />
    <Route exact path="/feed/detail" element={<FeedDetail />} />
    <Route path="friendSummary" element={<FriendSummary />} />
    <Route path="invite" element={<Invite />} />
    <Route path="task" element={<Tasks />} />
    <Route path="protocol" element={<Protocol />} />
    <Route path="model" element={<ModelPage />} />
    <Route path="faceselect" element={<ActionFaceSelectPage />} />
    <Route path="riderAgentPage" element={<RiderAgentPage />} />
    <Route path="riderAgentChat" element={<RiderAgentChat />} />
    <Route path="foodAgentPage" element={<FoodAgentPage />} />
    <Route path="shoppingAgentPage" element={<ShoppingAgentPage />} />
  </Routes>
);

const ToastCustom = () => {
  return (
    <ToastContainer
      position="bottom-center"
      autoClose={2000}
      hideProgressBar
      closeOnClick
      pauseOnHover
      draggable
      toastStyle={{
        backgroundColor: "#333",
        color: "white",
        width: "300px",
        padding: "8px",
        borderRadius: "8px",
        textAlign: "center",
        fontSize: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      style={{
        position: "fixed",
        bottom: "100px",
        width: "300px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    />
  );
};

export default App;
