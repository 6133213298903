import { useEffect, useState } from "react";
import { postRequest } from "../NetWork/NetWork";
import DataConnectSuccessAlert from "./DataConnectSuccessAlert";

export const ConnectCallback = () => {
  const [isConnectSuc, setIsConnectSuc] = useState(false);
  const [rewardPoint, setRewardPoint] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);

  const switchConnector = async (params) => {
    try {
      const result = await postRequest("/connector/switch", params);
      if (result) {
        return result;
      }
    } catch (error) {
      console.error("connector/switch Request failed:", error);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const state = queryParams.get("state");

    if (state) {
      try {
        const decodedState = decodeURIComponent(state);
        const stateData = JSON.parse(decodedState);
        setSelectedItem(stateData);

        const params = {
          connector_type: stateData.type,
          is_connect: true,
          auth_code: code,
          third_party_id: stateData.sub,
          redirect_uri: stateData.redirect_uri,
        };
        switchConnector(params).then((result) => {
          if (result) {
            setIsConnectSuc(true);
            setRewardPoint(result.reward_points);
          }
        });
        const newUrl = window.location.origin + window.location.pathname;
        window.history.replaceState(null, "", newUrl);
      } catch (error) {
        alert(error);
      }
    }
  }, []);

  const handleClose = () => {
    setIsConnectSuc(false);
    window.location.href =
      "https://worldcoin.org/mini-app?app_id=app_ab6f7f70af23623bc6123c6d5f1d7eb1&path=https%3A%2F%2Fweb.pinai.tech%2Fmain%2Fdata";
  };

  return (
    <div>
      <DataConnectSuccessAlert
        point={rewardPoint}
        isOpen={isConnectSuc}
        onClose={() => {
          handleClose();
        }}
        item={selectedItem}
      />
    </div>
  );
};
