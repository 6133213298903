import { useLocation, useNavigate } from "react-router-dom";
import { ColorfulBubble } from "../../icons/ColorfulBubble";
import ic_arrow_left_mini from "../../assets/ic-arrow-left-mini.svg";
import actionLogo from "../../assets/action-logo.png";
import shareIcon from '../../assets/icon-share.png';
import downloadFileIcon from '../../assets/icon-download-file.png';
import { getRequest } from "../NetWork/NetWork";
import { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import { LoadingIndicator } from "../Loading/Loading";

export const FriendSummary = () => {
    const navigate = useNavigate();
    // Horoscope, email_summary
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    const user_id = queryParams.get('name');
    const [loading, setLoading] = useState(false);
   
    const [content, setContent] = useState("")

    async function FeatchData() {
        setLoading(true);
        var params = {}
        var url = ""
        if (type == 2007) {
            url = "/action/horoscope"
        } else if (type == 2005) {
            url = "/action/gmail/summary"
        } else if (type == 2006) {
            url = "/action/friends/summary/facebook"
            params.user_id = user_id
        } else if (type == 2004) {
            url = "/action/calendar/summary"
        } else if (type == 2008) {
            url = "/action/friends/summary/twitter"
            params.user_name = user_id
        }
        const data = await getRequest(url, params);
        if (data) {
            setContent(data)
        }
        setLoading(false);
    }
    useEffect(()=>{
        FeatchData()
    },[])

    const screenshotRef = useRef(null)
   


    const handleShareClick = async () => {
        if (navigator.share) {
            navigator.share({
                title: 'Friend Summary',
                text: 'Hi PIN',
                url: window.location.href,
            }).catch(error => console.error('fail', error));
        } else {
            alert('Not Support');
        }
    };

    const handleDwonfileClick = async () => {
        if (screenshotRef.current) {
            // 获取当前元素的样式
            const originalMaxHeight = screenshotRef.current.style.maxHeight;
            const originalOverflow = screenshotRef.current.style.overflow;
            
            // 移除max-height和overflow样式
            screenshotRef.current.style.maxHeight = 'none';
            screenshotRef.current.style.overflow = 'visible';
    
            // 强制重绘以确保内容渲染完成
            await new Promise(resolve => setTimeout(resolve, 300));
    
            try {
                // 使用 html2canvas 截图
                const canvas = await html2canvas(screenshotRef.current, {
                    useCORS: true,
                    scale: 2,  // 提高分辨率，获取更清晰的截图
                });
    
                // 获取当前时间戳作为文件名
                const timestamp = new Date().getTime();
                const fileName = `screenshot_${timestamp}.png`;
    
                // 将Canvas转换为Data URL
                const dataURL = canvas.toDataURL("image/png");
    
                // 创建一个临时的a标签，用于下载图片
                const link = document.createElement("a");
                link.href = dataURL;
                link.download = fileName;
                link.click();  // 触发点击事件，开始下载
            } catch (error) {
                console.error('Screenshot failed:', error);
            } finally {
                // 恢复原始样式
                screenshotRef.current.style.maxHeight = originalMaxHeight;
                screenshotRef.current.style.overflow = originalOverflow;
            }
        }
    };


    return (
        <div className="w-screen h-screen bg-[#F8FAFC] flex flex-col">
          <nav className="relative">
            <button className="w-[20px] h-[20px] m-4" onClick={() => navigate(-1)}>
            <img src={ic_arrow_left_mini} alt="ic_arrow_left_mini" className="w-full h-full" />
            </button>
          </nav>
          <div className="flex-grow mt-[10px] mx-[20px] bg-white rounded-lg shadow-lg flex flex-col items-center">
            

          <div ref={screenshotRef} className="h-[calc(100vh-230px)] overflow-y-auto">
            <img src={actionLogo} className="w-[80px] h-[80px]"></img>
            <pre className="px-[10px]  text-left whitespace-pre-line" style={{ fontSize: '14px' }}>
                    {content + '\n\n'}
            </pre>
           </div>
           
        </div>
{/* 
        <div className="mx-[20px] h-[50px] mt-[10px] bg-[#e15ce6] rounded-xl shadow flex justify-center items-center"
            onClick={handleShareClick}    
        >
             <img src={shareIcon} alt="icon_share" className="mr-[10px] w-[24px] h-[24px]"></img>
             <div className="text-white text-base font-medium font-['SF Pro Display']">Share</div>
        </div> */}


        <div className="mx-[20px] h-[50px] mt-[10px] mb-[30px] bg-[#313741] rounded-xl shadow flex justify-center items-center"
            onClick={handleDwonfileClick}
        >
        <img src={downloadFileIcon} alt="icon_share" className="mr-[10px] w-[24px] h-[24px]"></img>
            <div className="text-white text-base font-medium font-['SF Pro Display']">Save to photos</div>
        </div>

        <LoadingIndicator isLoading={loading} />  
          
      </div>
      
    );
  };