import React from 'react';
import { Coins19 } from '../../icons/Coins19';
import { Power7 } from '../../icons/Power7';
import { postRequest } from '../NetWork/NetWork';
import { Navbar } from "../Navbar";

const DataActivityAlert = ({ isOpen, onClose, item}) => {
  if (!isOpen) return null;
  const height = "380px"
  const unavailable = item.available === 0
  var tiitle = "Connect"
  var subtitle = "You already connected your account, and unlocked this Data Connector. Unlock more and earn more!"
  if (unavailable) {
    tiitle = "Coming Soon"
    subtitle = "This Data Connector is under development, check back later."
  }

  return (
    <div className="flex flex-col min-h-screen justify-center">
      <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 flex items-center justify-center">
        <div
          className="absolute inset-0 bg-black bg-opacity-50"
          onClick={()=>{
            onClose(item)
          }}
        ></div>
        
        {/* Modal */}
        <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-4 bg-white rounded-tl-[40px] rounded-tr-[40px] shadow-lg" style={{ width: '375px', height: height }}>
         
          {/* Modal Body */}
          <div className="pt-[50px] flex items-center justify-center">
            <div className="w-[263.89px] h-[322px] flex-col justify-start items-center gap-[25px] inline-flex">
              <div className="w-[60px] h-[60px] relative">
              <img className="w-[60px] h-[60px] left-0 top-0 absolute rounded-xl" src={item.logo} />
              </div>
              <div className="self-stretch h-[31px] text-center text-black text-2xl font-medium font-['SF Pro Display']">{tiitle}</div>
           
             
              <div className="text-center text-black text-base font-normal font-['SF Pro Display'] leading-[21px]">
              {subtitle}  
               
              </div>
         

{/* button */}
            <div className="w-[300px] h-[50px]  py-3.5  bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex" onClick={()=>{
                    onClose(item)
                }}>
                <div className="text-white text-base font-medium font-['SF Pro Display']">Got it!</div>
            </div>

           
            </div>
          </div>

          
        </div>
      </div>
      </div>

      <Navbar className="custom-navbar z-10" />

    </div>
  );
};

export default DataActivityAlert;
