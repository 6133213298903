
import React from "react";
import { Navbar } from "../Navbar";
import HomeGuide from "../../assets/home-pig.png"
import taskcoins from "../../assets/task-coins.png"
import HomePower from "../../assets/home-power.png"

const DataConnectSuccessAlert = ({ isOpen, onClose, item , point}) => {
    if (!isOpen) return null;
    const height = '471px';
    const close = ()=> {
        onClose(item)
    }
  
    return (
        <div className="flex flex-col min-h-screen justify-center">
            {/* Background overlay */}
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div
                     className="flex items-center justify-center w-[375px] h-screen inset-0 bg-black bg-opacity-50"
                    onClick={close}
                ></div>

                {/* Modal */}
                <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 bg-white rounded-tl-[40px] rounded-tr-[40px] shadow-lg" style={{ width: '375px', height: height }}>
                <div className="mt-[69px] w-full h-[259px] flex flex-col justify-start items-center inline-flex">
                    <img className="w-[120px] h-[120px]" src={HomeGuide} alt="HomeGuide"/>
                    <div className="mt-[25px] flex items-center gap-[3px]">
                        <div className="text-black text-[25px] font-bold font-['SF Pro Display']">+{point}</div>
                        <img src={taskcoins} alt="taskcoins" className="w-[22px] h-[22px]" />
                    </div>

                    <div className="flex items-center gap-[3px]">
                        <div className="text-black text-[25px] font-bold font-['SF Pro Display']">+{item.power_growth}</div>
                        <img src={HomePower} alt="taskcoins" className="w-[22px] h-[22px]" />
                    </div>

                    <div className="mt-[25px] w-[259px] text-center text-black text-base font-normal font-['SF Pro Display'] leading-[21px]">
                        Congratulations!<br/>Unlocked a new Data Connector.</div>
                    <div className="mt-[25px] mx-[36px] self-stretch h-[50px] px-6 py-3.5 bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex" 
                    onClick={close}>
                        <div className="text-white text-base font-medium font-['SF Pro Display']">Collect!</div>
                    </div>
                </div>

                    </div>
                </div>

            <Navbar className="custom-navbar z-10" />
        </div>
    );
  };
  
  export default DataConnectSuccessAlert;
  