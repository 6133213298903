import React from "react";
import { Navbar } from "../Navbar";
import { Coins19 } from '../../icons/Coins19';
import { Power7 } from '../../icons/Power7';
import { data } from "autoprefixer";


const ModelUpAlert = ({ confirmUpgrade, isOpen, onClose,costCofing, item}) => {
    if (!isOpen) return null;
    const height = '700px';
    const close = ()=> {
        onClose()
    }
    const upgradeLevel = item.current_level+1
    const pinPoint = costCofing[item.current_level+1].cost
    const dataPower = costCofing[item.current_level+1].add_power

    console.log("itemss", item)
    return (
        <div className="flex flex-col min-h-screen justify-center">
            {/* Background overlay */}
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div
                     className="flex items-center justify-center w-[375px] h-screen inset-0 bg-black bg-opacity-50"
                    onClick={close}
                ></div>

                {/* Modal */}
                <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 bg-white rounded-tl-[40px] rounded-tr-[40px] shadow-lg" style={{ width: '375px', height: height }}>
                <div className="mt-[69px] w-full h-[259px] flex flex-col justify-start items-center gap-[25px] inline-flex">

                
                <img src={item.urls[upgradeLevel].url} className="mt-[16px] w-[180px] h-[238px] rounded-xl" />

                <div style={{width: 301, height: 31, textAlign: 'center', color: '#1C1F25', fontSize: 16, fontFamily: 'SF Pro Display', fontWeight: '500', wordWrap: 'break-word'}}>
                    Your {item.name} is ready to upgrade </div>

                <div className="h-[31px] text-center text-black text-2xl font-bold font-['SF Pro Display']">
                    Upgrade to Level {upgradeLevel}</div>


                <div className="flex flex-col w-[130px] justify-between items-center inline-flex">
                {/* power */}
                <div className='flex justify-between items-center inline-flex'>
                    <div className="w-20 text-[#676c77] text-sm font-normal font-['SF Pro Display'] leading-[15px]">
                        Data Power: 
                    </div>
                    <div className="px-[5px] py-[3px] rounded-lg justify-end items-center gap-[5px] flex">
                        <div className="text-right text-[#1c1f25] text-sm font-medium font-['SF Pro Display'] leading-[15px]">
                            {'+'+dataPower}
                        </div>
                        <Power7 className="!relative !w-3 !h-3" />
                    </div>
                </div>

                {/* PIN Points:  */}
                <div className='flex mt-[13px] justify-between items-center inline-flex'>
                    <div className="w-20 text-[#676c77] text-sm font-normal font-['SF Pro Display'] leading-[15px]">
                        PIN Points: 
                    </div>
                    <div className="px-[5px] py-[3px] rounded-lg justify-end items-center gap-[5px] flex">
                        <div className="text-right text-[#1c1f25] text-sm font-medium font-['SF Pro Display'] leading-[15px]">
                            {'-'+pinPoint}
                        </div>
                        <Coins19 className="!relative !w-3 !h-3" />
                    </div>
                </div>
                
              </div>


            <div className="flex flex-col items-center">
                <div className="mx-[36px] w-[300px] h-[50px] px-6 py-3.5 bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex" 
                onClick={confirmUpgrade}>
                    <div className="text-white text-base font-medium font-['SF Pro Display']">Upgrade</div>
                </div>

        
                <div className="mt-[10px] w-[300px] h-[50px]  py-3.5  bg-white border border-solid border-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex" 
                onClick={close}>
                    <div className="text-[#e15ce6] text-base font-medium font-['SF Pro Display']">Cancel</div>
                </div>
            </div>
               


                </div>

                    </div>
                </div>

            <Navbar className="custom-navbar z-10" />
        </div>
    );
  };
  
  export default ModelUpAlert;
  