
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import arrow_back from "../../assets/arrow-back.png";
import { getRequest  } from "../NetWork/NetWork";
import shareIcon from '../../assets/icon-share.png';
import downloadFileIcon from '../../assets/icon-download-file.png';
import { toast } from "react-toastify";
import { AuthProvider } from "../PIAuthCheckContext";

export  const Invite = ()=>{
   const [list, setList] = useState([])
   const [inviteList, setInviteList] = useState([])
  //  const testlist = [{
  //   name: 'pjm', avatar:'https://pinai-public.s3.amazonaws.com/invite_five_friends.png'
  //  }
  // ]


    const featchData = async () =>  {
        const data = await getRequest("/invitation/tasks")
        if (data) {
            setList(data.invitation_tasks)
            const dataList = await getRequest("/users/invite/list")
            if (dataList) {
              setInviteList(dataList.user_list)
              // setInviteList(testlist)
            }
        }
    }
    useEffect(()=>{
        featchData()
    }, [])

    const navigate = useNavigate()

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const showNavi = queryParams.get('type') === '1';

    return (
      <AuthProvider children={
        <div className="w-screen h-screen bg-[#F8FAFC] flex flex-col items-center ">
        {showNavi ?
           <nav className="relative">
            <div className=" w-screen h-20 flex-col mt-[16px] justify-start items-center inline-flex">
                <div className="flex justify-start items-center">
                    <button className="w-[30px] h-[30px] absolute left-[11px]" onClick={() => navigate(-1)}>
                        <img src={arrow_back} alt="arrow_back" className="w-full h-full" />
                    </button>
                    <div className="text-[#1c1f25] text-xl font-medium font-['SF Pro Display']">Invite Friends</div>
            </div>
            <div className="flex items-center w-[261px] h-[51px] text-center text-[#676c77] text-sm font-normal font-['SF Pro Display'] leading-tight">You will receive PIN points by completing each milestone!</div>
            </div>
         </nav> :
              <div className="w-[335px] h-20 flex-col mt-[16px] justify-start items-center inline-flex">
              <div className="flex justify-start items-center">
                  <div className="text-[#1c1f25] text-xl font-medium font-['SF Pro Display']">Invite Friends</div>
          </div>
          <div className="flex items-center w-[261px] h-[51px] text-center text-[#676c77] text-sm font-normal font-['SF Pro Display'] leading-tight">You will receive PIN points by completing each milestone!</div>
          </div>
        }
         
         <div className="relative gap-[15px] top-[15px]   flex flex-col items-center overflow-y-auto">
                  {list.map((item, index)=>(
                    <div>
                       <Card  key={index} item={item}/>
                    </div>
                  ))}


          {/* invite list */}
          <div className="mt-[25px] flex flex-col items-center w-full">
            <div className="text-[#1c1f25] text-xl font-medium font-['SF Pro Display']">Friends Invited</div>
          {inviteList.length == 0 &&
              <div className=" mt-[5px] w-[327px] h-8 text-center text-[#676c77] text-sm font-normal font-['SF Pro Display'] leading-tight">
              You haven’t invited any friend yet</div>}

          <div className="grid grid-cols-2 gap-x-[13px] gap-y-[13px] p-[15px]">
              {inviteList.map((item, index)=>(
                <div key={index}>
                  <FriendCard model={item} />
                </div>
              ))}
            </div>
            <div className="h-[250px] w-full" />
          </div>

      </div>


        {showNavi?
        <div  className="absolute bottom-[0px] w-screen">
        <ButtonSection/>
      </div>:
      <div  className="absolute bottom-[60px] w-screen">
      <ButtonSection/>
    </div>
      }
       </div>
      } />
    )
}

const FriendCard = ({model})=>{
  return (
    <div className="w-[161px] h-[60px] p-4 bg-white rounded-[18px] shadow justify-start items-center  gap-2.5 inline-flex">
      <img className="w-[45px] h-[45px]" src = {model.avatar} />
      <div className="text-[#1c1f25] w-[74px] text-sm font-medium font-['SF Pro Display']">
            {model.name}</div>
    </div>
  )
}

const ButtonSection = ()=>{
    const handleShareClick = async () => {
    const invite_code = localStorage.getItem('invite_code')
    const linkurl = window.location.origin + `?invite_code=${invite_code}`;
    console.log("linkurl", linkurl)
        if (navigator.share) {
            navigator.share({
                title: 'Invite Friend Link',
                text: 'Hi PIN',
                url: linkurl,
            }).catch(error => console.error('fail', error));
        } else {
            alert('Not Support');
        }
    };
    
    const handleCopy = () => {
      const invite_code = localStorage.getItem('invite_code')
      const linkurl = window.location.origin + `?invite_code=${invite_code}`;
        navigator.clipboard.writeText(linkurl).then(() => {

        }).catch((err) => {
        console.error('Failed to copy text: ', err);
        });
    };

   return  (
    <div>
        <div className="mx-[20px] h-[50px] mt-[10px] bg-[#e15ce6] rounded-xl shadow flex justify-center items-center"
            onClick={handleShareClick}    
        >
            <img src={shareIcon} alt="icon_share" className="mr-[10px] w-[24px] h-[24px]"></img>
            <div className="text-white text-base font-medium font-['SF Pro Display']">Invite Friends</div>
        </div>


        <div className="mx-[20px] h-[50px] mt-[10px] mb-[20px] bg-[#313741] rounded-xl shadow flex justify-center items-center"
            onClick={handleCopy}
        >
        <img src={downloadFileIcon} alt="icon_share" className="mr-[10px] w-[24px] h-[24px]"></img>
            <div className="text-white text-base font-medium font-['SF Pro Display']">Copy My Link</div>
        </div>
    </div>
   )
}


const Card = ({item})=>{
    const renderText = (text) => {
        const parts = text.split(/(\{.*?\})/).filter(Boolean);
        return parts.map((part, index) =>
          part.startsWith('{') && part.endsWith('}') ? (
            <span key={index} className="text-[#e15ce6] text-xs font-medium font-['SF Pro Display']">{part.slice(1, -1)}</span>
          ) : (
            <span key={index}>{part}</span>
          )
        );
      };

    return (
        <div className="flex  w-[335px] min-h-[77px] p-4 bg-white rounded-[18px] shadow  items-center gap-2.5 ">
        <div className="w-[45px] h-[45px] relative">
          <img className="w-[45px] h-[45px] left-0 top-0 absolute" src={item.logo} />
        </div>
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-3 inline-flex">
          <div className="self-stretch min-h-[37px] flex-col justify-start items-start gap-1.5 flex">
            <div className="self-stretch text-[#1c1f25] text-sm font-medium font-['SF Pro Display']">
              {item.task_name}
            </div>
            <div className="text-[#676c77] text-xs font-medium font-['SF Pro Display']">
              {/* {renderText(text)} */}
              {renderText(item.description)}
            </div>
          </div>
        </div>
      </div>
    )
}