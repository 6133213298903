/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Power3 = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1214_10716)">
        <path
          d="M6.85686 0.428711V4.71442H9.85686L5.14258 11.5716V7.28585H2.14258L6.85686 0.428711Z"
          stroke="#1C1F25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1214_10716">
          <rect fill="white" height="12" width="12" />
        </clipPath>
      </defs>
    </svg>
  );
};
