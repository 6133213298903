
import React from "react";
import { Navbar } from "../Navbar";
import HeadIcon from "../../assets/task-piggy-bank.png"
import CoinIcon from "../../assets/task-coins.png"


const TaskFinishAlert = ({ isOpen, onClose, item}) => {
    if (!isOpen) return null;
    const height = '446px';
    return (
        <div className="flex flex-col min-h-screen justify-center">
            {/* Background overlay */}
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div
                    className="flex items-center justify-center w-[375px] h-screen inset-0 bg-black bg-opacity-50"
                ></div>

                {/* Modal */}
                <div className="flex flex-col justify-start items-center fixed bottom-[0px] left-1/2 transform -translate-x-1/2 bg-white rounded-tl-[40px] rounded-tr-[40px] shadow-lg" style={{ width: '375px', height: height }}>
                
                <div class="w-[300px] h-[306px] flex flex-col justify-start items-center gap-[25px]">
                    <img class="mt-[75px] w-[120px] h-[120px]" src={HeadIcon} alt="HeadIcon" />
                    <div class="justify-start items-center inline-flex">
                        <div class="text-black text-[25px] font-bold font-['SF Pro Display']">+{item.reward_points}</div>
                            <img src={CoinIcon} alt="CoinIcon" class="w-[18.57px] h-[18.57px] ml-[5px]" />
                    </div>
                    <div class="w-[266px] text-center text-black text-base font-medium font-['SF Pro Display']">
                        {item.task_name}<br/>Task compeleted!</div>
                    <div class="self-stretch h-[50px] px-6 py-3.5 bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex">
                        <div class="text-white text-base font-medium font-['SF Pro Display']" onClick={()=>{onClose(item)}}>Collect!</div>
                    </div>
                    </div>
                
                </div>
            </div>


            <Navbar className="custom-navbar z-10" />
        </div>
    );
  };
  
  export default TaskFinishAlert;
  