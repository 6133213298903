
import { useLocation, useNavigate } from "react-router-dom";
import ic_arrow_left_mini from "../../assets/ic-arrow-left-mini.svg";
// import ic_arrow_left_mini from "../../assets/img_arrow_back.png";

import { useEffect, useRef, useState } from "react";

import img_rectangle from "../../assets/talk-rectangle.svg";
// import img_arrow_back from "../../assets/ic-arrow-left-mini.svg";  // 返回按钮图片
import icon_more from "../../assets/icon_more.svg";  // 更多按钮图片


// import img_riding_access_calendar from "../../assets/rides_access_calendar.png";
// import img_riding_access_gmail from "../../assets/home-mask-gmail.png";


import { MessageBubble } from "./MessageBubble";


export const RiderAgentChat = () => {
    const type = "ask_for_rides"
    const [messages, setMessages] = useState([]);
    const chatContainerRef = useRef(null);
    const navigate = useNavigate();
    const intervalRef = useRef(null); // 使用 useRef 来存储 interval

    const location = useLocation()
    const {data, actionid} = location.state || {}
 
    useEffect(() => {
        let messageIndex = 0;
        let selectedMessages = data.steps

        // switch (type) {
        //     case 'shopping':
        //         // selectedMessages = shoppingMessages;
        //         break;
        //     case 'order_food':
        //         // selectedMessages = askForFoodMessages;
        //         break;
        //     case 'ask_for_rides':
        //         selectedMessages = data.steps
        //         break;
        //     default:
        //         // selectedMessages = shoppingMessages;
        //         break;
        // }

        // 立即显示第一条消息
        let selectitem = null
        if (selectedMessages.length > 0 && !intervalRef.current) {
            intervalRef.current = setInterval(() => {
                if (messageIndex < selectedMessages.length) {
                    //直接从数组中取messageIndex+1条消息写到messages.
                    const step = selectedMessages[messageIndex]
                    if (step.agents) {
                        const selectAgent = step.agents.find(agent => agent.select === 1);
                         if (selectAgent) {
                            selectitem = selectAgent
                        }
                    }
                    setMessages(selectedMessages.slice(0, messageIndex+1));
                    messageIndex++;
                } else {
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;  // 清除 interval 引用
                    if (selectitem) {
                        handlePostMessageAction(selectitem.link, selectitem.webblink);
                    }
                    
                }
            }, 2000);
        }
        // setMessages(selectedMessages);

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);  // 清除 interval
                intervalRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const goBack = () => {
        navigate(-1)
    };

    const handlePostMessageAction = (appLink, fallbackLink)=> {
        setTimeout(() => {
            // 创建一个隐形的 iframe 来尝试拉起 App
        var iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = appLink;  // 使用深度链接尝试拉起 App
        document.body.appendChild(iframe);
    
        // 设置一个定时器，如果 App 没有被拉起，则跳转到 Web 页
        var fallbackTimer = setTimeout(function() {
            if (fallbackLink) {
                window.location.href = fallbackLink;  // 跳转到 Web 页
            }
        }, 1500);  // 1.5 秒未响应则认为未安装 App
        // 在一定时间后移除 iframe
        setTimeout(function() {
            document.body.removeChild(iframe);
            clearTimeout(fallbackTimer);  // 清理定时器，避免内存泄漏
        }, 3000);  // 等待 3 秒后移除 iframe
        }, 1000);
    }

    return (
        <div className="relative w-[375px] h-screen bg-variable-collection-light-bg rounded-[10px] overflow-hidden">
            <div className="absolute w-[375px] h-full top-0 left-0">
                <img className="absolute w-[375px] h-[768px] top-0 left-0" alt="Rectangle" src={img_rectangle} />
                <header className="absolute w-[375px] h-[65px] top-0 left-0 bg-transparent">
                    <div className="absolute w-[25px] h-[25px] top-[20px] left-[21px] cursor-pointer" onClick={goBack} style={{ backgroundImage: `url(${ic_arrow_left_mini})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} />
                    {/* <div className="absolute w-6 h-6 top-[20px] left-[330px] cursor-pointer" style={{ backgroundImage: `url(${icon_more})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} /> */}
                </header>
                <div className="absolute top-[65px] left-[20px] right-[20px] bottom-[20px] overflow-y-auto" ref={chatContainerRef} style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                    <div className="relative w-full h-full flex flex-col gap-3">
                        {messages.map((msg, index) => (
                            msg && (
                                <MessageBubble
                                    key={index}
                                    title={msg.title}
                                    message={msg.description}
                                    sender={msg.agents?"AI": "User"}
                                    logos={msg.agents}
                                    actionId={actionid}
                                />
                            )
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};