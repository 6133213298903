/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ColorfulBubble = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="812"
      viewBox="0 0 375 812"
      width="375"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1188_13973)">
        <g filter="url(#filter0_f_1188_13973)">
          <ellipse
            cx="83.3263"
            cy="212.744"
            fill="url(#paint0_linear_1188_13973)"
            rx="83.3263"
            ry="212.744"
            transform="matrix(0.465858 -0.88486 0.34188 0.939744 153 191.184)"
          />
        </g>
        <ellipse
          cx="175.569"
          cy="269.945"
          fill="url(#paint1_linear_1188_13973)"
          rx="175.569"
          ry="269.945"
          transform="matrix(0.364956 -0.931025 0.439009 0.898483 162.833 229.918)"
        />
        <path
          d="M153 226C153 214.954 161.954 206 173 206H508C519.046 206 528 214.954 528 226V715H153V226Z"
          fill="url(#paint2_linear_1188_13973)"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="606.291"
          id="filter0_f_1188_13973"
          width="344.919"
          x="92.0918"
          y="14.2305"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_1188_13973" stdDeviation="45" />
        </filter>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_1188_13973"
          x1="83.8795"
          x2="94.5677"
          y1="-37.6703"
          y2="382.72"
        >
          <stop offset="0.0190513" stopColor="#FF39EB" />
          <stop offset="0.27" stopColor="#B694FF" />
          <stop offset="0.529772" stopColor="#62E3FF" />
          <stop offset="0.825046" stopColor="#8EF585" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_1188_13973"
          x1="235.49"
          x2="187.359"
          y1="-30.2417"
          y2="548.965"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#6792FF" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_1188_13973"
          x1="340"
          x2="340.5"
          y1="223.5"
          y2="715"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.632459" stopColor="white" stopOpacity="0.98" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_1188_13973">
          <rect fill="white" height="812" width="375" />
        </clipPath>
      </defs>
    </svg>
  );
};
