import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import arrow_back from "../../assets/arrow-back.png";
import { Check } from "../../icons/Check";
import btnLeft from "../../assets/arrow-left-black.png"
import btnRight from "../../assets/arrow-right-black.png"

import ModelLock from "../../assets/model-lock.png"


import TestImg  from "../../assets/home-mask-gmail.png";
import { getRequest, postRequest } from "../NetWork/NetWork";
import ModelUpAlert from "./ModelUpAlert";
import { LoadingIndicator } from "../Loading/Loading";


import img_unavailable from "../../assets/data-unavailable.png";
import { AuthProvider } from "../PIAuthCheckContext";
import { preloadImage } from "../CommonTool";


export const ModelPage = ({onConfirm}) => {

  const navigate = useNavigate()

//   {
//     "id": 6001,
//     "logo": "https://pinai-public.s3.amazonaws.com/model_pinai.png",
//     "url": "https://pinai-public.s3.amazonaws.com/model_character_pinai.png",
//     "name": "PIN AI-1.5b",
//     "current_level": 0,
//     "cost": 5000,
//     "select": true
// }

  const featchData = async ()=>{
    const data = await getRequest("/model/list")
    setShowLoading(false)
    if (data) {
      const models = data.models
      setModelList(models)
      setPinPoints(data.pin_points)
      setCostList(data.cost_config)
      models.map((model)=>{
        if (model.select) {
          setSelectedModel(model)
          setTempSelectModel(model)
        }
        model.urls.map((url)=>{
          preloadImage(url)
        })
      })
    }
  }

  const [modelList, setModelList] = useState([])
  const [selectedModel, setSelectedModel] = useState(null);
  const [pinPoints, setPinPoints] = useState(0)
  const [costList, setCostList] = useState([])

  const [showUpdateAlert, setShowUpdateAlert] = useState(false)

  const [showLoading, setShowLoading] = useState(true)
  const [needRefresh, setNeedRefresh] = useState(false)

  const [tempSelectModel, setTempSelectModel] = useState(null)

 
  useEffect(() => {
    // const savedModel = localStorage.getItem("selectedModel");
    featchData()
  }, [needRefresh]);

  const confirmUpgrade = async ()=>{
    setShowUpdateAlert(false)
    setShowLoading(true)
    const data = await postRequest("/model/upgrade")
    setNeedRefresh(true)
  }

  const handleModelSelect = (model) => {
    setTempSelectModel(model);
    // setSelectedModel(model);
  };

  const handleConfirm =  async () => {
    setShowLoading(true)
    const data = await postRequest(`model/select/${tempSelectModel.id}`)
    if (data) {
      setSelectedModel(tempSelectModel)
      localStorage.setItem("current_model", JSON.stringify({
        "id": tempSelectModel.id,
        "name": tempSelectModel.name,
        "logo": tempSelectModel.logo,
        "current_level": tempSelectModel.current_level,
        "total_level": 10
      }))
    }
    setShowLoading(false)
    featchData()
  };

 

  return (
    <AuthProvider children={
          <div className="relative w-[375px] h-screen bg-[#F8FAFC] flex flex-col overflow-y-auto overscroll-none">
      <nav className="relative">
        <div className="w-full h-[30px] flex mt-[50px] justify-center items-center inline-flex">
          <button className="w-[30px] h-[30px] absolute left-[11px]" onClick={() => navigate(-1)}>
                     <img src={arrow_back} alt="arrow_back"/>
              </button>
            <div className="text-[#1c1f25] text-xl font-medium font-['SF Pro Display']">{ selectedModel?selectedModel.name:""}</div>
        </div>
      </nav>

      {/* {selectedModel?<div /> : <div />} */}
     {selectedModel && 
     <ModelPerson upgrade={
      ()=>{
        setShowUpdateAlert(true)
      }
     } model={selectedModel} pin_points={pinPoints} costList={costList}/>} 

      {selectedModel&& <div className="w-[375px] mt-[20px] bg-white rounded-t-3xl flex flex-col justify-between">
        <div className="w-[376px] flex flex-col items-center pt-6">
      <div className="w-[223px] h-[25px] text-center text-[#1c1f25] text-2xl font-medium font-['SF Pro Display']">Other Models</div>
        <div className="mt-[15px] w-[272px] h-[21px] text-center text-[#676c77] text-base font-medium font-['SF Pro Display'] leading-[21px]">
          You can change it at any time.</div>
        <div className="flex flex-col items-center gap-2 mt-4">
          {modelList.map((model) => (
            <div
              key={model.name}
              className={`flex w-[335px] items-center gap-2.5 p-4 bg-element-lightwhite rounded-[18px] border border-solid border-element-lightform-line shadow-shadow cursor-pointer ${tempSelectModel.id === model.id ? "border-primarypin-ai-pink" : ""}`}
              onClick={() => handleModelSelect(model)}
            >
              <div className="relative w-[45px] h-[45px]">
                <div className="relative h-[45px]">
                  <img
                    className="absolute w-[47px] h-[47px] -top-px -left-px"
                    alt={model.name}
                    src={model.logo}
                  />
                </div>
              </div>
              <div className="items-start gap-3 relative flex-1 grow flex flex-col">
                <div className="items-start gap-1.5 relative self-stretch w-full flex-[0_0_auto] flex flex-col">
                  <div className="relative self-stretch mt-[-1.00px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-primarydark-blue text-sm tracking-[0] leading-[normal]">
                    {model.name}
                  </div>
                  <p className={`relative self-stretch [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-xs tracking-[0] leading-[normal] ${tempSelectModel === model.name ? "text-primarypin-ai-pink" : "text-element-light2nd-text"}`}>
                  {renderText(`{Level ${model.current_level}}/10`)}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-center w-[25px] h-[25px]">
                {tempSelectModel.id === model.id && <Check className="!relative !w-[25px] !h-[25px]" />}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-[15px] mb-[50px] w-[336px] h-[50px] px-6 py-3.5 bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex"
          onClick={handleConfirm}
        >
          <div className="text-white text-base font-medium font-['SF Pro Display']">Confirm</div>
        </div>
        </div>
      </div>}

      <ModelUpAlert  confirmUpgrade={confirmUpgrade} costCofing={costList} isOpen={showUpdateAlert} onClose={()=>{
        setShowUpdateAlert(false)
      }} item={selectedModel} />
      
      <LoadingIndicator isLoading={showLoading} />
      </div> 
    } />
    )
}

const ModelPerson = ({model, pin_points, costList, upgrade})=>{
  const [cost, setCost] = useState({})
  const [curUrl, setCurUrl] = useState('')
  const [height, setHeight] = useState("375px") 
  const [progress, setProgress] = useState(0)
  const total = 10
  const orignalLevel = model.current_level

  const  [curLevel, setCurLevel] = useState(model.current_level)

  useEffect(()=>{
    const newLevel = model.current_level
    setCurLevel(newLevel)
    
    console.log("curLevel", curLevel, costList[newLevel])
    setCurUrl(model.urls[newLevel].url)
    setProgress(curLevel/10);
    if (curLevel < 10) {
      const tempcost =  costList[newLevel+1]
      setCost(tempcost)
      const temp = pin_points/tempcost.cost
      if (temp > 1) {
        setProgress(1)
      } else {
        setProgress(temp)
      }
    }
    if (progress>=1 || newLevel < orignalLevel) {
      setHeight("423px")
    }

  }, [model.current_level])


 const handleClickLeft = ()=>{
  const curIndex = curLevel
  if (curIndex > 0) {
    const preLevenl = curIndex-1
    setCurLevel(preLevenl)
    setCurUrl(model.urls[preLevenl].url)
    const tempcost =  costList[preLevenl]
    setCost(tempcost)
  }
 }

 const handleClickRight = ()=>{
  const curIndex = curLevel
  if (curIndex < total) {
    const nextLevel = curIndex+1
    setCurLevel(nextLevel)
    setCurUrl(model.urls[nextLevel].url)
    const tempcost =  costList[nextLevel]
    setCost(tempcost)
  }
 }

  return (
   <div className="flex items-center justify-between w-full">

  <div className="h-[50px] flex items-center" onClick={()=>{
        handleClickLeft()
      }}>
      <img src={btnLeft} alt="btnLeft" className="ml-[5px] w-[25px] h-[25px]" />

    </div>

    <div className={`w-[300px] h-[${height}] px-[5px] py-4 flex flex-col rounded-[18px] justify-center items-center inline-flex`}>
      <div className="relative">

      <img src={curUrl} className="mt-[16px] w-[210px] h-[278px] rounded-xl" />
       {orignalLevel<curLevel && 
       <div className="absolute top-[16px] left-0 w-[210px] h-[278px] bg-gray-300 bg-opacity-70 flex items-center justify-center rounded-xl p-2"> 
        <img src={ModelLock} alt="ModelLock" className="absolute top-[10px] right-[14px] w-[25px] h-[25px]"></img>
      </div>}
      </div>
      
 
      
       {(orignalLevel<curLevel || orignalLevel>curLevel)&&
       <div className="flex flex-col justify-center items-center inline-flex gap-[10px]">
          <div className="text-center text-[#1c1f25] text-sm font-medium font-['SF Pro Display']">{model.title}</div>
          <div className="text-center text-[#e15ce6] text-xs font-medium font-['SF Pro Display']">Level {curLevel}</div>
          <div className="text-center text-[#676c77] text-xs font-medium font-['SF Pro Display']">{cost.cost}</div>
          <div className="text-center text-[#676c77] text-xs font-medium font-['SF Pro Display']">{curLevel<orignalLevel?"You completed this level" : "Complete the previous levels to unlock"}</div>
        </div>}

      {(orignalLevel == curLevel)&&
        <div className="flex flex-col justify-center items-center inline-flex">
            <div className="w-[150px]text-center mt-[10px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-xs">
              {renderText(`{Level ${curLevel}}/${total}`)}
            </div>

            {curLevel < 10 &&  
            <div className="flex flex-col justify-center items-center">
              <div className=" mt-[10px] w-[150px]text-center mt-[10px] [font-family:'SF_Pro_Display-Medium',Helvetica] font-medium text-xs">
              {renderText(`{${pin_points}}/${cost.cost}`)}
            </div>

            <div className="mt-[10px] w-[180px] h-[7px] bg-[#676c77] rounded-[100px] border border-[#676c77] flex-col justify-start items-start inline-flex">
              <div
                className="h-[7px] bg-gradient-to-br from-[#70a8eb] via-[#b28eff] to-[#f9ad58] rounded-[100px]"
                style={{ width: `${progress * 100}%` }}
              />
            </div>

            {progress >=1 && 
              <div className="mt-[10px] w-[180px] h-[38px] px-6 py-3.5 bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex"
              onClick={()=>{
                upgrade()
              }}          
              >
                <div className="text-white text-base font-medium font-['SF Pro Display']">Upgrade</div>
            </div>}

            </div>
            }
            

        </div>}

      </div>

      <div className="h-[50px] flex items-center" onClick={()=>{
        handleClickRight()
      }
      }>
      <img src={btnRight} alt="btnLeft" className="mr-[5px] w-[25px] h-[25px]" />
    </div>

    </div>
  )
}

const renderText = (text) => {
  const parts = text.split(/(\{.*?\})/).filter(Boolean);
  return parts.map((part, index) =>
    part.startsWith('{') && part.endsWith('}') ? (
      <span key={index} className="text-[#e15ce6] text-xs font-medium font-['SF Pro Display']">{part.slice(1, -1)}</span>
    ) : (
      <span key={index}>{part}</span>
    )
  );
};