/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const InfoOutline = ({ color = "#626262", className }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="15"
      viewBox="0 0 15 15"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1187_10232)">
        <path
          d="M6.875 4.375H8.125V5.625H6.875V4.375ZM6.875 6.875H8.125V10.625H6.875V6.875ZM7.5 1.25C4.05 1.25 1.25 4.05 1.25 7.5C1.25 10.95 4.05 13.75 7.5 13.75C10.95 13.75 13.75 10.95 13.75 7.5C13.75 4.05 10.95 1.25 7.5 1.25ZM7.5 12.5C4.74375 12.5 2.5 10.2563 2.5 7.5C2.5 4.74375 4.74375 2.5 7.5 2.5C10.2563 2.5 12.5 4.74375 12.5 7.5C12.5 10.2563 10.2563 12.5 7.5 12.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1187_10232">
          <rect fill="white" height="15" width="15" />
        </clipPath>
      </defs>
    </svg>
  );
};

InfoOutline.propTypes = {
  color: PropTypes.string,
};
