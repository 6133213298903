/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const InfoOutline1 = ({ color = "#626262", className }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="25"
      viewBox="0 0 25 25"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1187_10228)">
        <path
          d="M11.458 7.29183H13.5413V9.37516H11.458V7.29183ZM11.458 11.4585H13.5413V17.7085H11.458V11.4585ZM12.4997 2.0835C6.74967 2.0835 2.08301 6.75016 2.08301 12.5002C2.08301 18.2502 6.74967 22.9168 12.4997 22.9168C18.2497 22.9168 22.9163 18.2502 22.9163 12.5002C22.9163 6.75016 18.2497 2.0835 12.4997 2.0835ZM12.4997 20.8335C7.90593 20.8335 4.16634 17.0939 4.16634 12.5002C4.16634 7.90641 7.90593 4.16683 12.4997 4.16683C17.0934 4.16683 20.833 7.90641 20.833 12.5002C20.833 17.0939 17.0934 20.8335 12.4997 20.8335Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1187_10228">
          <rect fill="white" height="25" width="25" />
        </clipPath>
      </defs>
    </svg>
  );
};

InfoOutline1.propTypes = {
  color: PropTypes.string,
};
