/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Button = ({ theme, state, className, divClassName, text = "Button", onClick }) => {
  return (
    <button
      className={`all-[unset] box-border w-[140px] flex items-center gap-2.5 shadow-[0px_2px_3px_#cbcccf26] px-6 py-3.5 overflow-hidden rounded-xl justify-center relative ${
        state === "secondary" ? "border border-solid" : ""
      } ${
        theme === "dark" && state === "secondary"
          ? "border-primarypin-ai-light-pink"
          : theme === "light" && state === "secondary"
          ? "border-primarypin-ai-pink"
          : ""
      } ${
        theme === "dark" && state === "primary"
          ? "bg-primarypin-ai-light-pink"
          : theme === "dark" && state === "secondary"
          ? "bg-primarydark-blue"
          : state === "disable" && theme === "dark"
          ? "bg-[#2a3d81]"
          : theme === "light" && state === "primary"
          ? "bg-primarypin-ai-pink"
          : theme === "light" && state === "disable"
          ? "bg-[#c1c8ff]"
          : "bg-element-lightwhite"
      } ${className}`}
    >
      <div
        onClick={onClick}
        className={`[font-family:'SF_Pro_Display-Regular',Helvetica] w-fit mt-[-1.00px] tracking-[0] text-sm relative font-normal leading-[normal] ${
          ["disable", "primary"].includes(state)
            ? "text-element-lightwhite"
            : theme === "dark" && state === "secondary"
            ? "text-primarypin-ai-light-pink"
            : "text-primarypin-ai-pink"
        } ${divClassName}`}
      >
        {text}
      </div>
    </button>
  );
};
