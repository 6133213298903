import React from 'react';
import MainLayout from '../components/MainLayout';

const MainPage = () => {
  return (
    <MainLayout />
  );
};

export default MainPage;
