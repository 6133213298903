import React, { useEffect, useState } from 'react';
import { Coins19 } from '../../icons/Coins19';
import { Power7 } from '../../icons/Power7';
import { postRequest } from '../NetWork/NetWork';
import { Navbar } from "../Navbar";
import PrivateNotice from "../../assets/data-private-notice.png";
import PrivateIcon from "../../assets/data-privacy-icon.png";

const DataConnectAlert = ({ isOpen, onClose, item, connect}) => {
  
//   {
//     "type": "Pinterest",
//     "name": "Pinterest",
//     "tier": 4,
//     "logo": "https://pinai-public.s3.amazonaws.com/logo_pinterest.png",
//     "available": 0,
//     "unlock": 0,
//     "level": 0,
//     "cost": 50000,
//     "power_growth": 5000
// }
const [height, setHeight] = useState("420px");
const [showConnect, setShowConnect] = useState(false);

const showNext = () => {
    setHeight("450px")
    setShowConnect(true)
}

const connetect = (item)=>{
    connect(item)
    onClose(item)
    setShowConnect(false)
}

const cancel = ()=> {
  onClose(item)
  localStorage.removeItem("authoSuccessItem")
  setShowConnect(false)
}


if (!isOpen) return null;

  return (
    <div className="flex flex-col min-h-screen justify-center">
      <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 flex items-center justify-center">
        <div
          className="absolute inset-0 bg-black bg-opacity-50"
          onClick={()=>{
            cancel()
          }}
        ></div>
        
        {/* Modal */}
        <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-4 bg-white rounded-tl-[40px] rounded-tr-[40px] shadow-lg" style={{ width: '375px', height: height }}>
         
          {/* Modal Body */}
          <div className="pt-[50px] flex flex-col items-center justify-center gap-[25px]">
            {
                showConnect?
                <PrivacyCard />:
                <GOConnectCard  item={item}/>
            }

{/* button */}
            {showConnect?
            <div className="w-[300px] h-[50px]  py-3.5  bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex" onClick={()=>{
                    connetect(item)
                    }}>
                    <div className="text-white text-base font-medium font-['SF Pro Display']">Nice!</div>
                </div>:
            
            <div className="w-[300px] h-[50px]  py-3.5  bg-[#e15ce6] rounded-xl shadow justify-center items-center gap-2.5 inline-flex" onClick={()=>{
                showNext()
            }}>
            <div className="text-white text-base font-medium font-['SF Pro Display']">Go!</div>
            </div>
            }

          </div>
        </div>
      </div>
      </div>

      <Navbar className="custom-navbar z-10" />

    </div>
  );
};


const GOConnectCard = ({item}) => {
    return (

        <div className='flex flex-col items-center justify-center gap-[25px]'>
            <img className="w-[60px] h-[60px]  rounded-xl" src={item.logo} />
              <div className="h-[31px] text-center text-black text-2xl font-medium font-['SF Pro Display']">Connect {item.name}</div>
              <div className="flex flex-col w-[130px] justify-between items-center inline-flex">
                {/* power */}
                <div className='flex justify-between items-center inline-flex'>
                    <div className="w-20 text-[#676c77] text-sm font-normal font-['SF Pro Display'] leading-[15px]">
                        Data Power: 
                    </div>
                    <div className="px-[5px] py-[3px] rounded-lg justify-end items-center gap-[5px] flex">
                        <div className="text-right text-[#1c1f25] text-sm font-medium font-['SF Pro Display'] leading-[15px]">
                            {'+'+item.power_growth}
                        </div>
                        <Power7 className="!relative !w-3 !h-3" />
                    </div>
                </div>

                {/* PIN Points:  */}
                <div className='flex mt-[13px] justify-between items-center inline-flex'>
                    <div className="w-20 text-[#676c77] text-sm font-normal font-['SF Pro Display'] leading-[15px]">
                        PIN Points: 
                    </div>
                    <div className="px-[5px] py-[3px] rounded-lg justify-end items-center gap-[5px] flex">
                        <div className="text-right text-[#1c1f25] text-sm font-medium font-['SF Pro Display'] leading-[15px]">
                            {'+'+item.power_growth}
                        </div>
                        <Coins19 className="!relative !w-3 !h-3" />
                    </div>
                </div>
                
              </div>

              <div className="w-[259px] text-center text-black text-base font-normal font-['SF Pro Display'] leading-[21px]">
                    Connect your {item.name} account.
              </div>

        </div>
    )
}

const PrivacyCard = ()=>{
    const list =[
        "Your data is never stored",
        "Your data will be deleted after use",
        "Used only for your requests"
    ]
    return (
        <div className='flex flex-col items-center justify-center gap-[25px]'>
        <img className="w-[60px] h-[60px]  rounded-xl" src={PrivateNotice} />
          <div className="h-[31px] text-center text-black text-2xl font-medium font-['SF Pro Display']">Your privacy is protected!</div>
          <div className="flex flex-col justify-between items-center inline-flex gap-[19px]">
            {list.map((item, index)=>(
                <div key={index} className='w-[300px] flex items-center  gap-2.5 inline-flex'>
                    <img src={PrivateIcon} alt='PrivateIcon' className='w-[30px] h-[30px]'></img>
                    <div className="text-black text-base font-normal font-['SF Pro Display'] leading-[21px]">{item}</div>
                </div>
            ))}
          </div>
    </div>
    )
}

export default DataConnectAlert;
